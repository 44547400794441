import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import type { RootState } from '../../app/redux/reducer';
import { traceSpan } from '../../app/tracing';
import logger from '../../common/logger/AppLogger';
import { login, logout, selectAccessToken } from '../auth';
import { AuthError } from '../auth/AuthError';
import { refreshToken } from '../auth/oauth';
import { selectApiConfig } from '../config';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';

const tracedFetchFn: typeof fetch = (...args) => traceSpan('RTK Query Request', () => fetch(...args));

const rawBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = selectAccessToken(getState() as RootState);
      if (token !== undefined) headers.set('Authorization', `Bearer ${token}`);
    },
    fetchFn: tracedFetchFn,
  });

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  const apiConfig = selectApiConfig(state);

  if (apiConfig === undefined) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No API Host found',
      },
    };
  }

  let result = await rawBaseQuery(apiConfig.baseUrl)(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // try to get a new token
    if (state.config.configuration && state.auth.token) {
      try {
        const refreshResult = await refreshToken(state.config.configuration.oAuth, state.auth.token.refresh_token);
        if (refreshResult !== undefined) {
          api.dispatch(login(refreshResult));
          result = await rawBaseQuery(apiConfig.baseUrl)(args, api, extraOptions);
        } else api.dispatch(logout());
      } catch (err) {
        logger.error(err);
        if (err instanceof AuthError)
          api.dispatch(showErrorDialog({ message: 'Authentication Token Refresh Failed. Try Action again', dismissible: true }));
      }
    } else api.dispatch(logout());
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 5,
});
