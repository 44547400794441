import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import { useOperatorSession, useWorkstation } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';

import { setSelectedTable } from './tableSlice';

export function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workstation = useWorkstation();
  const operatorSession = useOperatorSession();
  return (
    <>
      <div className="pos-header pos-header_role_tables">
        <div className="pos-header__area pos-header__area_role_info">
          <div className="pos-header__info">
            <div className="pos-header__labels">
              <div className="pos-header__label">Till No. {workstation?.terminalNumber}</div>
              <div className="pos-header__label">
                {operatorSession?.operator.firstName.substring(0, 1)} {operatorSession?.operator.lastName}
              </div>
              <div className="pos-header__label">
                <Moment interval={1000} format="ddd DD-MM-yyyy" />
              </div>
              <div className="pos-header__label">
                <Moment interval={1000} format="HH:mm:ss" />
              </div>
            </div>
          </div>
        </div>
        <div className="pos-header__area pos-header__area_role_actions">
          <div className="pos-header__actions">
            <button
              className="pos-header__action"
              type="button"
              onClick={() => {
                dispatch(setSelectedTable(undefined));
                navigate('/order');
              }}
            >
              Order
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
