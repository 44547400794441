import { useState } from 'react';
import Moment from 'react-moment';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Operator, OperatorSession, useGetActiveOperatorsQuery } from '../../api';

import './servers-overview.sass';

export function ServerOverviewDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  const manager = location.state as Operator;
  const { data } = useGetActiveOperatorsQuery();
  const [activeOperatorSession, setActiveOperatorSession] = useState<OperatorSession | undefined>();
  const [resultOffset, setResultOffset] = useState(0);
  const resultNextPage = () => {
    if (data && resultOffset < data.length - 15) setResultOffset(resultOffset + 15);
  };

  const resultPreviousPage = () => {
    if (data && resultOffset > 3 && data.length > 15) setResultOffset(resultOffset - 15);
    else setResultOffset(0);
  };

  const OperatorButton = ({ position }: { position: number }) => {
    const index = position - 1;
    if (data) {
      const currentData = data.slice(resultOffset, resultOffset + 15);
      if (currentData.length >= position) {
        const operator = currentData[index];

        if (operator) {
          return (
            <div
              className={`pos-servers-overview__server ${
                activeOperatorSession && activeOperatorSession.sessionId === operator.sessionId ? 'is-active' : ''
              }`}
              onClick={() => setActiveOperatorSession(operator)}
            >
              <h3 className="pos-servers-overview__server-text">
                {operator.operator.firstName} {operator.operator.lastName}
              </h3>
              <p className="pos-servers-overview__server-meta">
                <span className="pos-servers-overview__server-meta-line">
                  {operator.workstationId ? `Terminal ${operator.workstationNumber}` : 'Not Signed On'}
                </span>
                <span className="pos-servers-overview__server-meta-line">
                  Started At: <Moment format="HH:mm:ss" date={operator.signOnDateTime} />
                </span>
              </p>
            </div>
          );
        }
        return <></>;
      }
    }
    return <></>;
  };

  return (
    <>
      <div className="pos-modal__content">
        <div className="pos-servers-overview js-servers-overview">
          <div className="pos-servers-overview__header">
            <h2 className="pos-servers-overview__title">Signed In List</h2>
            <p className="pos-servers-overview__subtitle">Please select a server from the list below:</p>
          </div>
          <div className="pos-servers-overview__info">
            <div className="pos-servers-overview__servers">
              <button
                className="pos-servers-overview__servers-control pos-servers-overview__servers-control_role_previous js-servers-overview-servers-previous"
                type="button"
                onClick={resultPreviousPage}
                disabled={resultOffset === 0}
              >
                Previous
              </button>
              <div className="pos-servers-overview__servers-list">
                <div className="pos-servers-overview__servers-scroll js-servers-overview-servers-scroll">
                  <div className="pos-servers-overview__servers-row">
                    <div className="pos-servers-overview__servers-group">
                      <div className="pos-servers-overview__servers-row">
                        <OperatorButton position={1} />
                        <OperatorButton position={2} />
                        <OperatorButton position={3} />
                      </div>
                      <div className="pos-servers-overview__servers-row">
                        <OperatorButton position={4} />
                        <OperatorButton position={5} />
                        <OperatorButton position={6} />
                      </div>
                      <div className="pos-servers-overview__servers-row">
                        <OperatorButton position={7} />
                        <OperatorButton position={8} />
                        <OperatorButton position={9} />
                      </div>
                      <div className="pos-servers-overview__servers-row">
                        <OperatorButton position={10} />
                        <OperatorButton position={11} />
                        <OperatorButton position={12} />
                      </div>
                      <div className="pos-servers-overview__servers-row">
                        <OperatorButton position={13} />
                        <OperatorButton position={14} />
                        <OperatorButton position={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="pos-servers-overview__servers-control pos-servers-overview__servers-control_role_next js-servers-overview-servers-next"
                type="button"
                disabled={data && resultOffset < data.length - 15 === false}
                onClick={resultNextPage}
              >
                Next
              </button>
            </div>
          </div>
          <div className="pos-servers-overview__actions">
            {activeOperatorSession && (
              <button
                className="pos-servers-overview__action server-overview-action"
                type="button"
                onClick={() =>
                  navigate(`sign-out/${activeOperatorSession?.sessionId}`, {
                    state: { operator: activeOperatorSession, manager },
                  })
                }
              >
                Sign Out
              </button>
            )}
            {activeOperatorSession && activeOperatorSession.hasToken && (
              <button
                className="pos-servers-overview__action server-overview-action"
                type="button"
                onClick={() =>
                  navigate(`../change-fob/${activeOperatorSession?.sessionId}`, {
                    state: { operator: activeOperatorSession, manager },
                  })
                }
              >
                Change Fob
              </button>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
