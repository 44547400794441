import Moment from 'react-moment';

import { useAppSelector } from '../../../app/redux/hooks';
import { LayoutLinkType } from '../../api';
import { useEngineInfo, useServerLastPolled } from '../../appState';
import { selectVersion } from '../../config';
import { useActiveLayoutDefinition, useIsMenuVersionWorkstationSpecific, useMenuVersionNumber } from '../orderSlice';

import CategoryPanel from './main/CategoryPanel';
import FastBarPanel from './main/FastBarPanel';
import MenuButtonListPanel from './main/MenuButtonListPanel';
import PackageListPanel from './main/PackageListPanel';
import SubCategoryPanel from './main/SubCategoryPanel';

function MenuSection() {
  const engineInfo = useEngineInfo();
  const version = useAppSelector(selectVersion);
  const activeLayoutDefinition = useActiveLayoutDefinition();
  const serverLastPolled = useServerLastPolled();
  const menuVersion = useMenuVersionNumber();
  const isMenuWsSpecific = useIsMenuVersionWorkstationSpecific();

  return (
    <div className="pos-main__index">
      <div className="pos-main__menu">
        <div className="pos-menu pos-menu_role_main js-menu">
          <CategoryPanel />
          <SubCategoryPanel />
          {activeLayoutDefinition && activeLayoutDefinition.layoutLinkType === LayoutLinkType.FastBar && <FastBarPanel />}
          {activeLayoutDefinition && activeLayoutDefinition.layoutLinkType === LayoutLinkType.Category && <MenuButtonListPanel />}
          {activeLayoutDefinition && activeLayoutDefinition.layoutLinkType === LayoutLinkType.Packages && <PackageListPanel />}
        </div>
      </div>
      <div className="pos-main__version">
        PUB INVEST GROUP LTD | API {engineInfo?.versionNumber} | UI {version?.version} | Last Pinged&nbsp;
        {serverLastPolled != 'Never' ? <Moment interval={1000} date={serverLastPolled} format="DD/MM HH:mm:ss" /> : <>Never</>} | MENU{' '}
        <Moment format="DD/MM HH:mm" date={new Date(menuVersion * 1000)} />|{' '}
        <span style={{ color: isMenuWsSpecific ? 'red' : undefined }}>PRICE TIERS {isMenuWsSpecific ? 'RESTRICTED' : 'STANDARD'}</span>
      </div>
    </div>
  );
}

export default MenuSection;
