import { useAppDispatch } from '../../app/redux/hooks';

import { clearModalDialog, useModalDialogMessage } from './modalDialogSlice';

function ModalDialog() {
  const dispatch = useAppDispatch();
  const modalDialog = useModalDialogMessage();

  const dismiss = () => {
    dispatch(clearModalDialog());
  };

  return (
    <>
      {modalDialog && (
        <div className="pos-modal-portal">
          <div className="pos-modal-overlay">
            <div className="pos-popup pos-popup_role_info" style={{ width: modalDialog.width, height: modalDialog.height }}>
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">{modalDialog.title}</h3>
                  <p className="pos-popup__text" dangerouslySetInnerHTML={{ __html: modalDialog.message }}></p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={dismiss}>
                    {modalDialog.buttonText ? modalDialog.buttonText : 'Dismiss'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ModalDialog;
