import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../../../app/redux/hooks';
import Price from '../../../../common/components/price/Price';
import appLogger from '../../../../common/logger/AppLogger';
import {
  TransactionLineItem,
  useOperatorSignOffMutation,
  useVoidTransactionLineItemMutation,
  useVoidTransactionMutation,
  VoidReason,
} from '../../../api';
import { clearOperatorSession, useOperatorSession, useWorkstation } from '../../../appState';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { clearOrderState, useTransaction, useVoidReasons } from '../../orderSlice';

import 'swiper/css/bundle';
import './void.sass';

function Void() {
  const { state } = useLocation();
  const voidOperatorId = (state?.voidOperatorId ?? 0) as number;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workstation = useWorkstation();
  const transaction = useTransaction();
  const voidReasons = useVoidReasons();
  const operatorSession = useOperatorSession();

  const [triggerVoidItem] = useVoidTransactionLineItemMutation();
  const [triggerVoidTransaction] = useVoidTransactionMutation();
  const [triggerSignOff] = useOperatorSignOffMutation();

  const [activeMenuItem, setActiveMenuItem] = useState<TransactionLineItem | undefined>();
  const primaryTransaction = transaction.length > 0 ? transaction[0] : undefined;

  const [voidAll, setVoidAll] = useState(false);

  const [processing, setProcessing] = useState(false);

  let isVoidAllPermitted = primaryTransaction !== undefined ? primaryTransaction.totalDue == primaryTransaction.totalValue : true;
  if (primaryTransaction && primaryTransaction.bookingId) isVoidAllPermitted = false;

  if (primaryTransaction === undefined) return <></>;

  const close = () => {
    if (operatorSession) {
      if (operatorSession.operator.id === voidOperatorId || workstation?.hasFobReader === false) {
        navigate('/order');
        return;
      }

      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          dispatch(
            showErrorDialog({
              message: 'Failed to sign off operator',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
          navigate('/');
        });
    }
  };

  const onVoidReasonClicked = (voidReason: VoidReason) => {
    // Is the void value more than total due
    if (voidAll === false) {
      // VOID ITEM
      if (activeMenuItem && primaryTransaction) {
        const menuItemValue = activeMenuItem.totalPrice;
        const totalDue = primaryTransaction.totalDue;
        const totalDueWithoutDeals = totalDue + primaryTransaction.transactionAppliedDeals.map((p) => p.amount).reduce((a, b) => a + b, 0);
        if (menuItemValue > totalDueWithoutDeals) {
          dispatch(
            showErrorDialog({
              message: 'Cannot void more than is due to pay',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
          return;
        }
      } else return;
    } else {
      // VOID ALL
      if (primaryTransaction) {
        if (primaryTransaction.totalDue < primaryTransaction.totalValue) {
          dispatch(
            showErrorDialog({
              message: 'Cannot void all when there are payments',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
          return;
        }
      }
    }

    setProcessing(true);
    if (activeMenuItem && voidAll === false) {
      triggerVoidItem({
        transactionLineItemId: activeMenuItem.id,
        voidReasonId: voidReason.id,
        voidOperatorId: voidOperatorId,
      })
        .unwrap()
        .then(() => {
          setProcessing(false);
        })
        .catch((err) => {
          appLogger.error('Error voiding item', JSON.stringify(err));
          dispatch(
            showErrorDialog({
              message: 'Failed to void item',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
        });
    } else if (operatorSession && voidAll === true) {
      triggerVoidTransaction({
        transactionId: primaryTransaction.id,
        voidReasonId: voidReason.id,
        operatorId: voidOperatorId,
      })
        .unwrap()
        .then(() => {
          navigate('../');
        })
        .catch((err) => {
          appLogger.error('Error voiding transaction', JSON.stringify(err));
          dispatch(
            showErrorDialog({
              message: 'Failed to void all, possible the transaction is not in the right state',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
        });
    }
  };
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') close();
        }}
      >
        <div className="pos-modal pos-modal_role_void">
          <div className="pos-modal__content">
            <div className="pos-void">
              <div className="pos-void__header">
                <h2 className="pos-void__title">Void</h2>
                <h2 className="void-subtitle">You are in {voidAll ? 'Transaction' : 'Item'} Void Mode</h2>
              </div>
              <div className="pos-void__group">
                <div className="pos-void__item-select">
                  <div className="pos-invoice pos-invoice_role_void-item-select js-invoice">
                    <div className="pos-invoice__panel">
                      <div className="pos-invoice__panel-content">
                        <div className="pos-invoice__list">
                          <div className="pos-invoice__list-labels">
                            <p className="pos-invoice__list-label">Product</p>
                            <p className="pos-invoice__list-label">Qty</p>
                            <p className="pos-invoice__list-label">Each</p>
                            <p className="pos-invoice__list-label">Total</p>
                          </div>
                          <div className="pos-invoice__list-scroll js-invoice-scroll">
                            <Swiper
                              freeMode={true}
                              height={300}
                              slidesPerView="auto"
                              spaceBetween={30}
                              mousewheel={true}
                              scrollbar={true}
                              direction="vertical"
                              pagination={{ clickable: true }}
                              modules={[FreeMode, Pagination]}
                            >
                              <SwiperSlide>
                                <div className="pos-invoice__items">
                                  {primaryTransaction.transactionLineItems.map((lineItem) => (
                                    <div
                                      key={lineItem.id}
                                      className={`pos-invoice__item ${activeMenuItem?.id == lineItem.id || voidAll ? 'is-active' : ''}`}
                                      onClick={() => {
                                        if (activeMenuItem && activeMenuItem.id === lineItem.id) setActiveMenuItem(undefined);
                                        else setActiveMenuItem(lineItem);
                                      }}
                                    >
                                      <div className="pos-invoice__item-info">
                                        <p className="pos-invoice__item-text">{lineItem.description}</p>
                                        <div className="pos-invoice__item-meta">
                                          {lineItem.childTransactionLineItems.map((childLineItem) => (
                                            <div key={childLineItem.id} className="pos-invoice__item-meta-group">
                                              <p className="pos-invoice__item-meta-text">
                                                {childLineItem.menuItemId > 0 && (
                                                  <>
                                                    {childLineItem.description} <Price price={childLineItem.itemPrice} />
                                                  </>
                                                )}
                                                {childLineItem.menuItemId === 0 && <i>{childLineItem.description}</i>}
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <div className="pos-invoice__item-qty">
                                        <p className="pos-invoice__item-text">{lineItem.quantity}</p>
                                      </div>
                                      <div className="pos-invoice__item-price">
                                        <p className="pos-invoice__item-text">
                                          <Price price={lineItem.itemPrice} />
                                        </p>
                                      </div>
                                      <div className="pos-invoice__item-total">
                                        <p className="pos-invoice__item-text">
                                          <Price price={lineItem.totalPrice} />
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </SwiperSlide>
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="pos-invoice__panel-footer"></div>
                    </div>
                    <div className="pos-invoice__info">
                      <p className="pos-invoice__total">
                        <span className="pos-invoice__total-label">Total</span>
                        <span className="pos-invoice__total-value">
                          <Price price={primaryTransaction.totalValue} />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pos-void__actions">
                  {voidReasons.map((v) => (
                    <button
                      className="pos-void__action"
                      type="button"
                      disabled={processing == true || (voidAll === false && activeMenuItem === undefined)}
                      onClick={() => onVoidReasonClicked(v)}
                      key={v.id}
                    >
                      {v.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="pos-void__footer">
                {processing === false && isVoidAllPermitted && (
                  <button
                    className="pos-item__action void-all-button pos-sign-out__action_role_change-fob"
                    type="button"
                    onClick={() => {
                      if (isVoidAllPermitted) {
                        if (voidAll) setActiveMenuItem(undefined);
                        setVoidAll(!voidAll);
                      }
                    }}
                  >
                    Void {voidAll ? 'Item Only' : 'All'}
                  </button>
                )}
                {processing && <h2 className="pos-void__message">Processing Void, Please Wait</h2>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Void;
