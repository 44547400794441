import { useFeatureFlags } from '../../config';

import { OauthLogin } from './OauthLogin';
import { RegistrationLogin } from './RegistrationLogin';

export default function LoginSelect() {
  const flags = useFeatureFlags();

  if (flags && flags.useNewRegistrationFlow) return <RegistrationLogin />;

  return <OauthLogin />;
}
