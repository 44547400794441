import { baseApi } from '../baseApi';

import { WorkstationSession } from './WorkstationSession';

const workstationSessionsSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['WorkstationSessions'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWorkstationSession: builder.query<WorkstationSession, void>({
        query: () => ({
          url: `/WorkstationSessions`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'WorkstationSessions', id: result.id }] : []),
      }),
      getWorkstationSessions: builder.query<WorkstationSession[], void>({
        query: () => ({
          url: `/WorkstationSessions/All`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      closeWorkstationSession: builder.mutation<WorkstationSession, number>({
        query: (payload: number) => ({
          url: `/WorkstationSessions/close?operatorId=${payload}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['WorkstationSessions'],
      }),
      openWorkstationSession: builder.mutation<WorkstationSession, number>({
        query: (payload: number) => ({
          url: `/WorkstationSessions/open?operatorId=${payload}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['WorkstationSessions'],
      }),
      printWorkstationSession: builder.mutation<void, number>({
        query: (payload: number) => ({
          url: `/WorkstationSessions/${payload}/print`,
          method: 'POST',
          body: payload,
        }),
      }),
    }),
  });

export const {
  useGetWorkstationSessionQuery,
  useLazyGetWorkstationSessionQuery,
  useCloseWorkstationSessionMutation,
  useOpenWorkstationSessionMutation,
  useGetWorkstationSessionsQuery,
  useLazyGetWorkstationSessionsQuery,
  usePrintWorkstationSessionMutation,
} = workstationSessionsSlice;
