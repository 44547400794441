import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import {
  useCloseWorkstationSessionMutation,
  useEnableWasteModelMutation,
  useGetWorkstationSessionQuery,
  useLazyGetWorkstationsQuery,
  useOperatorSignOffMutation,
} from '../../../api';
import { setWorkstation, useOperatorSession } from '../../../appState';
import { useFeatureFlags } from '../../../config';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { setQuantity, setQuantityShow, useTransaction } from '../../orderSlice';

function CloseSession() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const transaction = useTransaction();

  const flags = useFeatureFlags();
  const useWasteMode = flags && flags.useWasteMode === true;

  const [triggerClose] = useCloseWorkstationSessionMutation();
  const [triggerSignOff] = useOperatorSignOffMutation();
  const [triggerWorkstation] = useLazyGetWorkstationsQuery();
  const [triggerWaste] = useEnableWasteModelMutation();

  const operatorSession = useOperatorSession();
  const { data: workstationSession } = useGetWorkstationSessionQuery();

  const notAllowed = transaction.length > 1 || transaction[0].totalValue > 0;

  const waste = () => {
    if (operatorSession != null) {
      triggerWaste(operatorSession.operator.id)
        .unwrap()
        .then((r) => {
          if (r.wastePermitted === true) navigate('../');
          else {
            dispatch(
              showErrorDialog({
                message: 'Cannot activate waste due to transaction in progress',
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          }
        })
        .catch((err) => {
          if (err && err.status && (err.status === 406 || err.status === 409)) {
            dispatch(
              showErrorDialog({
                message: err.data.detail,
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'An error occurred',
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          }
        });
    }
  };

  const close = () => {
    if (operatorSession != null) {
      triggerClose(operatorSession.operator.id)
        .unwrap()
        .then(() => {
          triggerWorkstation()
            .unwrap()
            .then((ws) => {
              dispatch(setWorkstation(ws));
              triggerSignOff({
                operatorId: operatorSession.operator.id,
              })
                .unwrap()
                .then(() => {
                  navigate('/');
                })
                .catch(() => {
                  dispatch(
                    showErrorDialog({
                      message: 'An error occurred signing out',
                      buttonText: 'OK',
                      dismissible: true,
                    }),
                  );
                });
            });
        })
        .catch((err) => {
          if (err && err.status && (err.status === 406 || err.status === 409)) {
            dispatch(
              showErrorDialog({
                message: err.data.detail,
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'An error occurred',
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          }
        });
    }
  };
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') {
            dispatch(setQuantityShow(false));
            dispatch(setQuantity(1));
            navigate('/order');
          }
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Confirm Close Session</h2>
              </div>
              {notAllowed == true && (
                <>
                  <p className="pos-servers-overview__error">TRANSACTION IN PROGRESS</p>
                  <div className="pos-servers-overview__actions">
                    <button className="pos-servers-overview__action" type="button" onClick={() => navigate('../')}>
                      Exit
                    </button>
                  </div>
                </>
              )}
              {notAllowed == false && (
                <>
                  <div className="close-message">
                    <p>
                      Please Confirm you want to close the current session. <br />
                      <br />
                      {useWasteMode && workstationSession?.hasWasteTransaction === false && (
                        <span>If you have waste to log please click on Waste first.</span>
                      )}
                      {useWasteMode && workstationSession?.hasWasteTransaction === true && <span>Waste has already been completed</span>}
                    </p>
                  </div>
                  <div className="pos-servers-overview__actions">
                    {useWasteMode && workstationSession?.hasWasteTransaction === false && (
                      <button className="pos-servers-overview__action server-overview-action-waste" type="button" onClick={waste}>
                        Waste
                      </button>
                    )}
                    <button className="pos-servers-overview__action" type="button" onClick={close}>
                      Close
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CloseSession;
