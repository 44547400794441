import { ReactNode, useRef } from 'react';

import { context, trace, Span, SpanStatusCode, Tracer } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { FetchError } from '@opentelemetry/instrumentation-fetch/build/src/types';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import AppLogger from '../../common/logger/AppLogger';
import { selectUser } from '../../features/auth';
import { selectConfig } from '../../features/config';
import { useAppSelector } from '../redux/hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
let webTracerWithZone: Tracer | undefined;
let bindingSpan: Span | undefined;

export default function TraceProvider({ children }: { children: ReactNode }) {
  const user = useAppSelector(selectUser);
  const config = useAppSelector(selectConfig);

  const hasRegistered = useRef<boolean>(false);

  if (config == undefined) return <></>;

  const serviceName = config.telemetry.serviceName;
  const environmentName = config.app.environmentName;
  const collectorUrl = config.telemetry.collectorUrl;

  const getAttributes = () => {
    if (user !== undefined) {
      return {
        'service.name': serviceName,
        'deployment.environment': environmentName.toLowerCase(),
        'user.id': user.id,
        'user.name': user.userName,
        'user.domain': user.firstName,
      };
    }

    return {
      'service.name': serviceName,
      'deployment.environment': environmentName.toLowerCase(),
    };
  };

  AppLogger.initialise(config, user);

  if (hasRegistered.current === false) {
    const resource = new Resource(getAttributes());
    const provider = new WebTracerProvider({ resource });
    if (collectorUrl != '') {
      const collector = new OTLPTraceExporter({
        url: `${collectorUrl}/v1/traces`,
      });
      provider.addSpanProcessor(new BatchSpanProcessor(collector));
    }
    //provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

    provider.register({ contextManager: new ZoneContextManager() });

    webTracerWithZone = provider.getTracer(serviceName);

    window.startBindingSpan = (traceId: string, spanId: string, traceFlags: number) => {
      if (webTracerWithZone) {
        bindingSpan = webTracerWithZone.startSpan('');
        bindingSpan.spanContext().traceId = traceId;
        bindingSpan.spanContext().spanId = spanId;
        bindingSpan.spanContext().traceFlags = traceFlags;
      }
    };

    registerInstrumentations({
      instrumentations: [
        new FetchInstrumentation({
          propagateTraceHeaderCorsUrls: [/.*/g],
          clearTimingResources: true,
          applyCustomAttributesOnSpan: (span: Span, request: Request | RequestInit, result: Response | FetchError) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const attributes = (span as any).attributes;
            if (attributes.component === 'fetch') span.updateName(`${attributes['http.method']} ${attributes['http.url']}`);

            if (result instanceof Error) {
              span.setStatus({
                code: SpanStatusCode.ERROR,
                message: result.message,
              });
              span.recordException(result.stack || result.name);
            }
          },
        }),
      ],
    });
    hasRegistered.current = true;
  }

  return <>{children}</>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function traceSpan<F extends (...args: any) => ReturnType<F>>(name: string, func: F): ReturnType<F> {
  let singleSpan: Span;
  if (webTracerWithZone) {
    if (bindingSpan) {
      const ctx = trace.setSpan(context.active(), bindingSpan);
      singleSpan = webTracerWithZone.startSpan(name, undefined, ctx);
      bindingSpan = undefined;
    } else singleSpan = webTracerWithZone.startSpan(name);

    return context.with(trace.setSpan(context.active(), singleSpan), () => {
      try {
        const result = func();
        singleSpan.end();
        return result;
      } catch (error) {
        singleSpan.setStatus({ code: SpanStatusCode.ERROR });
        singleSpan.end();
        throw error;
      }
    });
  }
  console.warn('No tracing available');
  return func();
}
