import Moment from 'react-moment';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import { LoyaltyType, Transaction, TransactionStatus } from '../api';

import { setSelectedTransaction, useSelectedTransaction } from './salesReviewSlice';

function SalesReviewLineItem({ transaction }: { transaction: Transaction }) {
  const dispatch = useAppDispatch();
  const selectedTransaction = useSelectedTransaction();

  let rowColour = '';
  if (transaction.transactionLineItems.filter((x) => x.voidReasonId != null).length > 0) rowColour = 'is-void';
  else if (transaction.voided === true) rowColour = 'is-void-all';
  else if (transaction.bookingId != null) rowColour = 'is-deposit';
  else if (transaction.status === TransactionStatus.Stored || transaction.status === TransactionStatus.Open) rowColour = 'is-wtl';
  else if (transaction.transactionAppliedDeals.length > 0) rowColour = 'is-discount';
  else if (transaction.isNoSale) rowColour = 'is-no-sale';
  if (selectedTransaction !== undefined && transaction.id == selectedTransaction.id) rowColour = 'is-active';

  return (
    <div className={`pos-table__row ${rowColour}`} onClick={() => dispatch(setSelectedTransaction(transaction))}>
      <div className="pos-table__cell">
        <p className="pos-table__text">
          <span className="pos-table__text-meta">
            <Moment format="DD/MM/yy" date={transaction.transactionStartDateTime} />
          </span>{' '}
          <Moment format="HH:mm" date={transaction.transactionStartDateTime} />
        </p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">
          {transaction.transactionNumber}/{transaction.sequenceNumber}
        </p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">{transaction.terminalNumber}</p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">{transaction.operatorName}</p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">
          <Price price={transaction.totalValue} />
        </p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">
          {transaction.transactionAppliedDeals.length > 0 ? (
            <Price price={transaction.transactionAppliedDeals.reduce((a, b) => a + b.amount, 0)} />
          ) : (
            'No'
          )}
        </p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">{transaction.loyaltyType != LoyaltyType.None ? 'YES' : 'NO'}</p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">{transaction.bookingId != null ? transaction.bookingId : 'N/A '}</p>
      </div>
      <div className="pos-table__cell">
        <p className="pos-table__text">
          {transaction.transactionLineItems.filter((x) => x.voidReasonId != null).length > 0 ? 'V' : ''}
          {transaction.voided === true ? 'VA' : ''}
          {transaction.isNoSale ? 'NS' : ''}
        </p>
      </div>
    </div>
  );
}
export default SalesReviewLineItem;
