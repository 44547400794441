import Moment from 'react-moment';
import { useRouteError } from 'react-router-dom';

import logger from '../../common/logger/AppLogger';
import { useWorkstation } from '../appState';

import './styles.sass';

function Error() {
  const error = useRouteError() as Error;
  const workstation = useWorkstation();

  logger.error({
    type: error.name,
    message: error.message,
    stack: error.stack,
  });
  return (
    <div className="x-pos-error-body">
      <div className="x-pos-error-container">
        <div className="x-pos-error-box">
          <h2>Something has gone wrong</h2>
          <p>{error.name}</p>
          <p>{error.message}</p>
          <p>{error.stack}</p>
          <p>Till {workstation ? workstation.terminalNumber : 'Not available'}</p>
          <p>
            <Moment format="DD/MM/yyyy HH:mm:ss" />
          </p>
          <button type="button" className="reload-button" onClick={() => window.location.reload()}>
            Restart Till
          </button>
        </div>
      </div>
    </div>
  );
}

export default Error;
