import { useNavigate, useParams } from 'react-router-dom';

import { useLoyaltyDetailsQuery } from '../../../api';

import cross from './assets/cross.svg';
import tick from './assets/tick.svg';
import './loyalty-details.sass';

export default function LoyaltyDetails() {
  const navigate = useNavigate();
  const { transactionId } = useParams<{ transactionId: string }>();
  const { data, error, isLoading } = useLoyaltyDetailsQuery(Number(transactionId || '0'));
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Customer Details</h2>
              </div>
              {data && (
                <div className="loyalty-details">
                  <h3 className="loyalty-name">
                    {data.contact.firstName} {data.contact.lastName}
                  </h3>
                  {data.contact.isActive === false ||
                    (data.contact.isVerified === false && (
                      <h4 className="loyalty-invalid-profile">The customer is not currently verified or active in Loyalty</h4>
                    ))}
                  <h3 className="loyalty-subtitle">
                    These deals are what are available to the customer based on their loyalty status <br />
                    Some of these may not be available right now.
                  </h3>
                  <div className="loyalty-deals">
                    <table>
                      <thead>
                        <tr>
                          <td></td>
                          <th>Deal</th>
                          <th>Limited Use?</th>
                          <th>Allowed Uses</th>
                          <th>Customer Used</th>
                        </tr>
                      </thead>
                      {data.deals.map((deal) => (
                        <tr key={deal.dealId}>
                          <td style={{ width: '30px' }}>
                            {deal.isDealAvailableNow && deal.hasUsageLimit === false && (
                              <svg
                                style={{ height: '24px', width: '24px' }}
                                width="800px"
                                height="800px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 12.3333L10.4615 15L16 9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                  stroke="#0d9541"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            {deal.isDealAvailableNow && deal.hasUsageLimit === true && deal.usageLimit > deal.consumedUsage && (
                              <svg
                                style={{ height: '24px', width: '24px' }}
                                width="800px"
                                height="800px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 12.3333L10.4615 15L16 9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                  stroke="#0d9541"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                            {(deal.isDealAvailableNow === false ||
                              (deal.hasUsageLimit === true && deal.usageLimit <= deal.consumedUsage)) && (
                              <svg
                                style={{ height: '24px', width: '24px' }}
                                width="800px"
                                height="800px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.3689 5.64103L5.63548 18.3634M5.63106 5.64103L18.3645 18.3634M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                  stroke="#ff0000"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </td>
                          <td>{deal.dealName}</td>
                          <td className="col-100">{deal.hasUsageLimit ? 'Is Limited' : 'Unlimited'}</td>
                          <td className="col-100">{deal.hasUsageLimit ? deal.usageLimit : '*'}</td>
                          <td className="col-100">{deal.consumedUsage}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              )}
              {isLoading && <div>Checking Loyalty and processing, please wait.</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
