import { baseApi } from '../baseApi';

import { TenderMedia } from './TenderMedia';

const tenderMediaSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['TenderMedias'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTenderMedias: builder.query<TenderMedia[], void>({
        keepUnusedDataFor: 60,
        query: () => ({
          url: `/TenderMedias`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'TenderMedias' as const,
                  id,
                })),
                { type: 'TenderMedias', id: 'LIST' },
              ]
            : [{ type: 'TenderMedias', id: 'LIST' }],
      }),
    }),
  });

export const { useGetTenderMediasQuery } = tenderMediaSlice;
