import { Subject } from 'rxjs';

import { TransactionLineItemAddRequest } from '../../features/api';
import { PacSignatureVerifyMessage } from '../../features/pac-signature-verify/pacSignatureVerifyMessage';

const testSubject = new Subject<number>();
const killSubject = new Subject<boolean>();
const cardPaymentSuccessSubject = new Subject<{ transactionId: number; result: string }>();
const cardPaymentFailureSubject = new Subject<number>();
const cardSignatureSubject = new Subject<PacSignatureVerifyMessage>();
const cardNotificationSubject = new Subject<{ transactionId: number; notification: string; terminalId: string }>();
const errorButtonClickedSubject = new Subject<any>();
const errorButtonAlternateClickedSubject = new Subject<any>();
const cardResultStatusSubject = new Subject<CardPaymentStatusItem>();
const addToBasketSubject = new Subject<TransactionLineItemAddRequest>();
const layoutVersionSubject = new Subject<number>();

export const appSubscriptionService = {
  testNext: (val: number) => testSubject.next(val),
  testObservable: () => testSubject.asObservable(),
  killNext: (val: boolean) => killSubject.next(val),
  killObservable: () => killSubject.asObservable(),
  cardPaymentSuccessNext: (val: { transactionId: number; result: string }) => cardPaymentSuccessSubject.next(val),
  cardPaymentSuccessObservable: () => cardPaymentSuccessSubject.asObservable(),
  cardSignatureNext: (val: PacSignatureVerifyMessage) => cardSignatureSubject.next(val),
  cardSignatureObservable: () => cardSignatureSubject.asObservable(),
  cardNotificationNext: (val: { transactionId: number; notification: string; terminalId: string }) => cardNotificationSubject.next(val),
  cardNotificationObservable: () => cardNotificationSubject.asObservable(),
  cardPaymentFailureNext: (val: number) => cardPaymentFailureSubject.next(val),
  cardPaymentFailureObservable: () => cardPaymentFailureSubject.asObservable(),
  errorButtonClickedNext: (val: any) => errorButtonClickedSubject.next(val),
  errorButtonClickedObservable: () => errorButtonClickedSubject.asObservable(),
  errorButtonAlternateClickedNext: (val: any) => errorButtonAlternateClickedSubject.next(val),
  errorButtonAlternateClickedObservable: () => errorButtonAlternateClickedSubject.asObservable(),
  cardResultStatusNext: (val: CardPaymentStatusItem) => cardResultStatusSubject.next(val),
  cardResultStatusObservable: () => cardResultStatusSubject.asObservable(),
  addToBasketNext: (val: TransactionLineItemAddRequest) => addToBasketSubject.next(val),
  addToBasketObservable: () => addToBasketSubject.asObservable(),
  layoutVersionNext: (val: number) => layoutVersionSubject.next(val),
  layoutVersionObservable: () => layoutVersionSubject.asObservable(),
};

export interface CardPaymentStatusItem {
  terminalId: string;
  transactionNumber: number;
  success: boolean;
  lastAmount: number;
  dateTimeAdded: number;
  operatorName: string;
  terminalName: string;
}
