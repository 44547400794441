import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import logger from '../../common/logger/AppLogger';
import {
  OperatorLevel,
  useGetWorkstationSessionsQuery,
  useLazyGetWorkstationsQuery,
  useOpenWorkstationSessionMutation,
  useOperatorSignOffMutation,
  usePrintWorkstationSessionMutation,
  WorkstationSession,
} from '../api';
import { clearOperatorSession, setWorkstation, useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState } from '../order/orderSlice';

function StartSession() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const [triggerOpenSession, { isLoading }] = useOpenWorkstationSessionMutation();
  const [triggerWorkstation] = useLazyGetWorkstationsQuery();
  const [triggerSignOff] = useOperatorSignOffMutation();
  const keyboardState = useRef<string>('');

  const [triggerPrintSession] = usePrintWorkstationSessionMutation();
  const { data } = useGetWorkstationSessionsQuery();

  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          clearOperatorSession();
          navigate('/');
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  const start = () => {
    if (operatorSession != null) {
      triggerOpenSession(operatorSession.operator.id)
        .unwrap()
        .then(() => {
          triggerWorkstation()
            .unwrap()
            .then((data) => {
              dispatch(setWorkstation(data));
              navigate('/order');
            });
        })
        .catch((err) => {
          dispatch(
            showErrorDialog({
              message: JSON.stringify(err),
              dismissible: true,
              buttonText: 'OK',
            }),
          );
        });
    }
  };

  const close = () => {
    if (operatorSession != null) {
      triggerSignOff({ operatorId: operatorSession.operator.id })
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch(() => {
          dispatch(
            showErrorDialog({
              message: JSON.stringify('Failed to sign out'),
              dismissible: true,
              buttonText: 'OK',
            }),
          );
        });
    }
  };
  const print = (ws: WorkstationSession) => {
    triggerPrintSession(ws.id)
      .unwrap()
      .then(() => {
        logger.info('Successfully requested print');
      })
      .catch((err) => {
        logger.error(err);
        dispatch(
          showErrorDialog({
            message: 'Failed to request print',
            dismissible: true,
          }),
        );
      });
  };

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOff = /^0{3,4}(00)$/gm;
        if (operatorSession === undefined) navigate('/');
        else {
          const regexSignOffMatch = keyboardState.current.match(regexSignOff);
          if (regexSignOffMatch !== null) {
            logger.info('Sign Off needed');
            signOff();
          }
        }

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [operatorSession, dispatch, navigate, signOff]);

  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay">
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              {operatorSession === undefined && (
                <>
                  <div className="pos-servers-overview__header">
                    <h2 className="pos-servers-overview__title">Scan Fob</h2>
                    <p className="pos-servers-overview__subtitle">Scan your Fob</p>
                  </div>
                  <div className="pos-servers-overview__icon pos-servers-overview__icon_role_scan"></div>
                </>
              )}
              {operatorSession !== undefined &&
                (operatorSession.operator.operatorLevel === OperatorLevel.Manager ||
                  operatorSession.operator.operatorLevel === OperatorLevel.Supervisor ||
                  operatorSession.operator.operatorLevel === OperatorLevel.Admin) && (
                  <>
                    <div className="pos-servers-overview__header">
                      <h2 className="pos-servers-overview__title">Confirm Start</h2>
                      <p className="pos-servers-overview__subtitle">Confirm Start New Shift</p>
                      {isLoading && <p className="pos-servers-overview__subtitle">PLEASE WAIT</p>}
                    </div>
                    <div className="pos-servers-overview__actions">
                      <button className="pos-servers-overview__action" type="button" onClick={() => start()} disabled={isLoading}>
                        Start
                      </button>
                    </div>
                    {data && data.length > 0 && (
                      <div className="pos-servers-overview__actions" style={{ position: 'absolute', bottom: '10px' }}>
                        <button
                          className="pos-servers-overview__action server-overview-action-print"
                          type="button"
                          style={{ width: 'auto' }}
                          onClick={() => print(data[0])}
                        >
                          Re-Print Last EOD
                        </button>
                      </div>
                    )}
                  </>
                )}
              {operatorSession !== undefined && operatorSession.operator.operatorLevel === OperatorLevel.Operator && (
                <>
                  <div className="pos-servers-overview__header">
                    <h2 className="pos-servers-overview__title">Cannot Start Session</h2>
                    <p className="pos-servers-overview__subtitle">You do not have the permissions to start a session</p>
                  </div>
                  <div className="pos-servers-overview__actions">
                    <button className="pos-servers-overview__action" type="button" onClick={close}>
                      Close
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartSession;
