import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { useOperatorSignOffMutation } from '../../../api';
import { clearOperatorSession, useOperatorSession } from '../../../appState';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { clearOrderState, setSidebarState } from '../../orderSlice';

function SignOutConfirm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();

  const [triggerSignOff] = useOperatorSignOffMutation();
  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(setSidebarState(false));
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch((err) => {
          if (err.status === 412) {
            dispatch(
              showErrorDialog({
                message: err.data.title,
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Something went wrong',
                dismissible: true,
              }),
            );
          }
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  const closeFob = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
        stop: true,
      })
        .unwrap()
        .then(() => {
          dispatch(setSidebarState(false));
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch((err) => {
          if (err.status === 412) {
            dispatch(
              showErrorDialog({
                message: err.data.title,
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Something went wrong',
                dismissible: true,
              }),
            );
          }
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') {
            dispatch(setSidebarState(false));
            navigate('../');
          }
        }}
      >
        <div className="pos-modal pos-modal_role_sign-out">
          <div className="pos-modal__content">
            <div className="pos-sign-out">
              <div className="pos-sign-out__header">
                <h2 className="pos-sign-out__title">Signing Out</h2>
              </div>
              <div className="pos-sign-out__message">
                <p className="pos-sign-out__message-text">How do you want to sign out?</p>
              </div>
              <div className="pos-sign-out__actions">
                {operatorSession?.hasToken && (
                  <button className="pos-sign-out__action pos-sign-out__action_role_change-fob" type="button" onClick={closeFob}>
                    CLOSE FOB
                  </button>
                )}
                <button className="pos-sign-out__action pos-sign-out__action_role_confirm" type="button" onClick={signOff}>
                  SIGN OUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignOutConfirm;
