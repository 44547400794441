import { LayoutDefinition } from '../../../api';
import { useOperatorSession } from '../../../appState';
import { useMenuItemCategories } from '../../orderSlice';

export function MenuCategoryButton({
  layoutDefinition,
  isActive,
  onClick,
}: {
  layoutDefinition: LayoutDefinition;
  isActive: string;
  onClick: (layoutDefinition: LayoutDefinition) => void;
}) {
  const operator = useOperatorSession();
  const categories = useMenuItemCategories();
  const category = categories.find((x) => x.id === layoutDefinition.menuItemCategoryId);

  if (category) {
    let visibleCategory = true;

    if (operator && operator.operator) visibleCategory = category.visibleToOperatorLevel >= operator.operator.operatorLevel;

    if (visibleCategory) {
      return (
        <div className={`pos-menu__category${isActive}`} onClick={() => onClick(layoutDefinition)}>
          <p className="pos-menu__category-text">{category ? category.name : ''}</p>
        </div>
      );
    }
  }
  return <div className="pos-menu__category hidden-button">Hidden</div>;
}
