import { useNavigate } from 'react-router-dom';

import Price from '../../../../common/components/price/Price';
import { MenuItem } from '../../../api/menu';
import { useIsAppTill, useIsSessionBlockNewTransactions } from '../../../appState';
import { useActiveCategoryIds, useMenuItemCategories, useSelectHasLineItems } from '../../orderSlice';

function MenuButton({ menuItem, color }: { menuItem?: MenuItem; color?: string }) {
  const categories = useMenuItemCategories();
  const activeCategoryIds = useActiveCategoryIds();
  const navigate = useNavigate();
  const isNewItemBlocked = useIsSessionBlockNewTransactions();
  const isAppTill = useIsAppTill();
  const hasLineItems = useSelectHasLineItems();

  const itemClick = (menuItem: MenuItem) => {
    if (isAppTill || (isNewItemBlocked && hasLineItems === false)) return;
    navigate(`./add/${menuItem.id}`);
  };

  const getColour = (categoryIds: number[]) => {
    const childCategories = categories.filter((x) => categoryIds.includes(x.id) && x.parentCategoryId != undefined);
    if (childCategories.length > 0) return color ? color : `category-color-${activeCategoryIds.indexOf(childCategories[0].id) + 1}`;
    if (categoryIds.length > 0) return color ? color : `category-color-${activeCategoryIds.indexOf(categoryIds[0]) + 1}`;
  };

  const getCategoryName = (categoryIds: number[]) => {
    const childCategories = categories.filter((x) => categoryIds.includes(x.id) && x.parentCategoryId != undefined);
    if (childCategories.length > 0) return childCategories[0].name;
    if (categoryIds.length > 0) return categories.find((x) => x.id == categoryIds[0])?.name;
  };

  if (menuItem !== undefined) {
    if (menuItem && menuItem.prices.length > 0) {
      return (
        <div
          className={`pos-menu__item ${getColour(menuItem.menuItemCategoryIds)} ${
            isAppTill || (isNewItemBlocked && hasLineItems === false) ? 'menu-item-disabled' : ''
          }`}
          onClick={() => itemClick(menuItem)}
        >
          <p className="pos-menu__item-meta">{getCategoryName(menuItem.menuItemCategoryIds)}</p>
          <p className="pos-menu__category-text">{menuItem.name}</p>
          <p className="pos-menu__item-meta">
            <Price price={menuItem.currentPrice ? menuItem.currentPrice : 0} />
          </p>
        </div>
      );
    }
  }
  return <div className="pos-menu__item hidden-button"></div>;
}
export default MenuButton;
