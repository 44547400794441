import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/redux/hooks';
import logger from '../../../common/logger/AppLogger';
import { selectOauthConfig, selectVersion } from '../../config/configSelectors';
import './styles/styles.sass';
import { AuthenticationToken, DeviceAuthState } from '../auth.interfaces';
import { selectIsAuthenticated } from '../authSelectors';
import { login } from '../authSlice';
import { checkTokenAvailable, deviceCode } from '../oauth';

function OauthLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const oAuthConfig = useAppSelector(selectOauthConfig);
  const version = useAppSelector(selectVersion);

  const [deviceToken, setDeviceToken] = useState<DeviceAuthState | undefined>();
  const [authToken, setAuthToken] = useState<AuthenticationToken | undefined>();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    if (oAuthConfig) {
      if (deviceToken === undefined) {
        deviceCode(oAuthConfig).then((data) => {
          setDeviceToken(data);
        });
      }
    }
  }, [oAuthConfig, deviceToken]);
  useEffect(() => {
    if (deviceToken !== undefined && authToken === undefined) {
      const timer = setTimeout(() => {
        setDeviceToken(undefined);
      }, (deviceToken.expires_in - 5) * 1000);

      return () => clearTimeout(timer);
    }
  }, [deviceToken, authToken]);

  useEffect(() => {
    if (deviceToken !== undefined && oAuthConfig !== undefined && authToken === undefined) {
      const timer = setInterval(() => {
        checkTokenAvailable(oAuthConfig, deviceToken.device_code).then((data) => {
          if (data.error) logger.warn('Token not yet validated by user');
          else if (data.access_token) setAuthToken(data);
        });
      }, deviceToken.interval * 1000);

      return () => clearInterval(timer);
    }
  }, [deviceToken, authToken, oAuthConfig]);

  useEffect(() => {
    if (authToken !== undefined) dispatch(login(authToken));
  }, [authToken, dispatch]);

  useEffect(() => {
    if (authToken !== undefined && isAuthenticated === true) navigate('/');
  }, [authToken, isAuthenticated, navigate]);

  if (oAuthConfig === undefined) return <div>No OpenID Configuration Found</div>;
  return (
    <div className="x-pos-auth-body">
      <div className="x-pos-auth-container">
        <div className="x-pos-auth-box">
          <h1>Enroll Workstation</h1>
          <p>Please login with the Workstation ID to enroll the device</p>
          {deviceToken && authToken === undefined && <QRCode value={deviceToken.verification_uri_complete} />}
          {authToken && <div>Authenticated, loading info</div>}
          <p>{deviceToken?.user_code}</p>
          <p>Version {version?.version}</p>
        </div>
      </div>
    </div>
  );
}
export { OauthLogin };
