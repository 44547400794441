import { TransactionLineItem } from '../transactionLineItem/TransactionLineItem';

import { TransactionAppliedDeal } from './TransactionAppliedDeal';
import { TransactionPayment } from './TransactionPayment';

export enum TransactionStatus {
  Open = 1,
  Closed = 2,
  Stored = 3,
}

export enum LoyaltyType {
  None = 0,
  Customer = 1,
  PromoCode = 2,
}

export interface Transaction {
  id: number;
  operatorId: number;
  operatorName: string;
  tableId?: number;
  tableNumber?: number;
  status: TransactionStatus;
  sequenceNumber: number;
  transactionNumber: number;
  transactionStartDateTime: string;
  transactionName: string;
  covers: number;
  bookingId?: number;
  bookingName?: string;
  bookingAmount?: number;
  bookingPaid?: number;
  bookingAmountRemaining?: number;
  voided: boolean;
  totalValue: number;
  totalDue: number;
  transactionPayments: TransactionPayment[];
  transactionLineItems: TransactionLineItem[];
  transactionAppliedDeals: TransactionAppliedDeal[];
  cardPaymentInProgress: boolean;
  loyaltyId: string;
  loyaltyType: LoyaltyType;
  pinEntryDeviceTerminalId?: string;
  isNoSale: boolean;
  isRefund: boolean;
  terminalNumber: number;
  hasVoidedItems: boolean;
  forbidNewLineItems: boolean;
  payAtCounterStartTime?: string;
  isWaste: boolean;
}
