import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '../../../api/menu';
import { useIsSessionBlockNewTransactions, useOperatorSession } from '../../../appState';
import { useMenuItemCategories, useMenuItems, useSelectHasLineItems } from '../../orderSlice';

function MenuSearch() {
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const isNewItemBlocked = useIsSessionBlockNewTransactions();
  const hasLineItems = useSelectHasLineItems();

  const isForbidToSearch = isNewItemBlocked && hasLineItems === false;

  const menuItems = useMenuItems();
  const categories = useMenuItemCategories();
  const [visibleMenuItems, setVisibleMenuItems] = useState<MenuItem[]>([]);
  const [filterText, setFilterText] = useState('');
  const [index, setIndex] = useState(0);

  const addFilterText = (letter: string) => setFilterText(filterText + letter);
  const backSpace = () => setFilterText(filterText.length > 1 ? filterText.substring(0, filterText.length - 1) : '');
  const onSearchClick = () => {
    if (filterText.length > 0) {
      if (operatorSession) {
        const categoryIds = categories.filter((x) => x.visibleToOperatorLevel >= operatorSession.operator.operatorLevel).map((x) => x.id);
        const filteredMenu = menuItems.filter((x) => x.menuItemCategoryIds.filter((y) => categoryIds.includes(y)).length > 0);
        const filtered = filteredMenu.filter((x) => x.prices.length > 0 && x.name.toLowerCase().indexOf(filterText) > -1);
        setVisibleMenuItems(filtered);
      }
    }
    setIndex(0);
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        {isForbidToSearch === false && (
          <div className="pos-modal pos-modal_role_search">
            <div className="pos-modal__content">
              <div className="pos-search js-search">
                <div className="pos-search__header">
                  <h2 className="pos-search__title">Product Search</h2>
                </div>
                <div className="pos-search__products">
                  <button
                    className="pos-search__products-control pos-search__products-control_role_previous js-search-products-previous"
                    type="button"
                    onClick={() => setIndex(index - 3)}
                    disabled={index === 0}
                  >
                    Previous
                  </button>
                  <div className="pos-search__products-list">
                    <div className="pos-search__products-scroll js-search-products-scroll">
                      <div className="pos-search__products-row">
                        {visibleMenuItems.slice(index, index + 3).map((x) => (
                          <div key={x.id} className="pos-search__product" onClick={() => navigate(`../add/${x.id}`)}>
                            <p className="pos-search__product-meta">{categories.find((y) => y.id === x.menuItemCategoryIds[0])?.name}</p>
                            <p className="pos-search__product-text">{x.name}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <button
                    className="pos-search__products-control pos-search__products-control_role_next js-search-products-next"
                    type="button"
                    onClick={() => setIndex(index + 3)}
                    disabled={visibleMenuItems.length < 3 || index >= visibleMenuItems.length - 3}
                  >
                    Next
                  </button>
                </div>
                <div className="pos-search__controls">
                  <div className="pos-search__field">
                    <div className="pos-field pos-field_role_search">
                      <div className="pos-field__group">
                        <div className="pos-field__box">{filterText.toUpperCase()}</div>
                        <button className="pos-field__action" onClick={onSearchClick}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="pos-search__keyboard">
                    <div className="pos-keyboard pos-keyboard_role_search">
                      <div className="pos-keyboard__controls">
                        <div className="pos-keyboard__controls-row">
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('1')}>
                            1
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('2')}>
                            2
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('3')}>
                            3
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('4')}>
                            4
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('5')}>
                            5
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('6')}>
                            6
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('7')}>
                            7
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('8')}>
                            8
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('9')}>
                            9
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('0')}>
                            0
                          </button>
                        </div>
                        <div className="pos-keyboard__controls-row">
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('q')}>
                            q
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('w')}>
                            w
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('e')}>
                            e
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('r')}>
                            r
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('t')}>
                            t
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('y')}>
                            y
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('u')}>
                            u
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('i')}>
                            i
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('o')}>
                            o
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('p')}>
                            p
                          </button>
                        </div>
                        <div className="pos-keyboard__controls-row">
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('a')}>
                            a
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('s')}>
                            s
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('d')}>
                            d
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('f')}>
                            f
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('g')}>
                            g
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('h')}>
                            h
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('j')}>
                            j
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('k')}>
                            k
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('l')}>
                            l
                          </button>
                        </div>
                        <div className="pos-keyboard__controls-row">
                          <button className="pos-keyboard__control pos-keyboard__control_role_shift" type="button">
                            Shift
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('z')}>
                            z
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('x')}>
                            x
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('c')}>
                            c
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('v')}>
                            v
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('b')}>
                            b
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('n')}>
                            n
                          </button>
                          <button className="pos-keyboard__control" type="button" onClick={() => addFilterText('m')}>
                            m
                          </button>
                          <button className="pos-keyboard__control pos-keyboard__control_role_backspace" type="button" onClick={backSpace}>
                            Backspace
                          </button>
                        </div>
                        <div className="pos-keyboard__controls-row">
                          <button
                            className="pos-keyboard__control pos-keyboard__control_role_space"
                            type="button"
                            onClick={() => addFilterText(' ')}
                          >
                            Space
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isForbidToSearch === true && (
          <div className="pos-popup pos-popup_role_error">
            <div className="pos-popup__content">
              <div className="pos-popup__icon"></div>
              <div className="pos-popup__info">
                <h3 className="pos-popup__title">Warning!</h3>
                <p className="pos-popup__text">Search is disabled due to till running from previous business day.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuSearch;
