const priceFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

function Price({ price }: { price: number }) {
  return <>{priceFormatter.format(price)}</>;
}

export default Price;
