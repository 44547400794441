import { useCallback, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import logger from '../../common/logger/AppLogger';
import { useOperatorSignOffMutation } from '../api';
import { clearOperatorSession, useOperatorSession } from '../appState';
import { clearOrderState } from '../order/orderSlice';

import { Header } from './Header';
import { setSelectedTable } from './tableSlice';

function Tables() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const keyboardState = useRef('');
  const [triggerSignOff] = useOperatorSignOffMutation();

  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      dispatch(setSelectedTable(undefined));
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          clearOperatorSession();
          navigate('/');
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOff = /^0{3,4}(00)$/gm;
        if (operatorSession === undefined) navigate('/');
        else {
          const regexSignOffMatch = keyboardState.current.match(regexSignOff);
          if (regexSignOffMatch !== null) {
            logger.info('Sign Off needed');
            signOff();
          }
        }

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [operatorSession, signOff, dispatch, navigate]);

  return (
    <>
      <div className="pos-container pos-container_role_tables">
        <Header />
        <Outlet />
      </div>
    </>
  );
}

export default Tables;
