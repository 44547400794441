import { useNavigate } from 'react-router-dom';

import Price from '../../../../common/components/price/Price';
import { Package } from '../../../api';

function PackageButton({ packageItem }: { packageItem?: Package }) {
  const navigate = useNavigate();
  const itemClick = (packageItem: Package) => {
    navigate('/order/package/' + packageItem.packageId);
  };

  if (packageItem !== undefined) {
    return (
      <div className={`pos-menu__item category-color-1`} onClick={() => itemClick(packageItem)}>
        <p className="pos-menu__item-meta">Package</p>
        <p className="pos-menu__category-text">{packageItem.name}</p>
        <p className="pos-menu__item-meta">
          <Price price={packageItem.price} />
        </p>
      </div>
    );
  }
  return <div className="pos-menu__item hidden-button"></div>;
}
export default PackageButton;
