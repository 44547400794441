import { baseApi } from '../baseApi';

const exclusionPeriodSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    dealExclusion: builder.mutation<void, void>({
      query: () => ({
        url: '/ExclusionPeriods/DealExclusion',
        method: 'POST',
        headers: {
          'x-api-version': '1.0',
        },
      }),
    }),
    priceTier: builder.mutation<void, void>({
      query: () => ({
        url: '/ExclusionPeriods/PriceTier',
        method: 'POST',
        headers: {
          'x-api-version': '1.0',
        },
      }),
    }),
  }),
});

export const { useDealExclusionMutation, usePriceTierMutation } = exclusionPeriodSlice;
