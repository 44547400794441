import { useState } from 'react';
import Moment from 'react-moment';
import { Outlet, useNavigate } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch, useAppSelector } from '../../app/redux/hooks';
import { useSearchTransactionsQuery } from '../api';
import { useEngineInfo, useOperatorSession, useServerLastPolled } from '../appState';
import { selectVersion } from '../config';

import SalesReviewLineItem from './SalesReviewLineItem';
import { setSelectedTransaction, useSelectedTransaction } from './salesReviewSlice';

import 'swiper/css/bundle';

function SalesReview() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedTransaction = useSelectedTransaction();
  const operatorSession = useOperatorSession();
  const operatorId = operatorSession ? operatorSession.operator.id : 0;

  const engineInfo = useEngineInfo();
  const version = useAppSelector(selectVersion);
  const serverLastPolled = useServerLastPolled();
  const [filterText, setFilterText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [showSearch, setShowSearch] = useState(false);
  const keyPress = (key: string) => setSearchText(searchText + key);
  const backSpace = () => setSearchText(searchText.length > 1 ? searchText.substring(0, searchText.length - 1) : '');

  const mainMenuClick = () => {
    dispatch(setSelectedTransaction(undefined));
    navigate('/order');
  };

  const displayClick = () => {
    navigate(`./${selectedTransaction?.id}`);
  };

  const searchClick = () => {
    setFilterText(searchText);
    setSearchText('');
    setShowSearch(false);
  };

  const { data } = useSearchTransactionsQuery({
    operatorId,
    searchText: filterText,
  });

  return (
    <>
      {' '}
      <div className="pos-container pos-container_role_sales-review">
        <div className="pos-header pos-header_role_sales-review">
          <h2 className="pos-header__title">Sales Review</h2>
        </div>
        <div className="pos-sales-review">
          <div className="pos-sales-review__table">
            <div className="pos-sales-review__table-content">
              <div className="pos-table pos-table_role_sales-review js-table">
                <div className="pos-table__group pos-table__group_role_header">
                  <div className="pos-table__row pos-table__row_role_header">
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting pos-table__sorting_type_desc"></div>
                      Date / Time
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>Tx ID
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>Term No
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      Operator
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      Amount
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      Discount
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      Loyalty
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      Booking ID
                    </div>
                    <div className="pos-table__cell pos-table__cell_role_header">
                      <div className="pos-table__sorting"></div>
                      V/NS
                    </div>
                  </div>
                </div>
                <div className="pos-table__group pos-table__group_role_main">
                  <div className="pos-table__scroll js-table-scroll">
                    <Swiper
                      freeMode={true}
                      height={300}
                      slidesPerView="auto"
                      spaceBetween={30}
                      mousewheel={true}
                      scrollbar={true}
                      direction="vertical"
                      pagination={{ clickable: true }}
                      modules={[FreeMode, Pagination]}
                    >
                      <SwiperSlide>
                        <div className="pos-table__scroll-content">
                          {data && data.map((t) => <SalesReviewLineItem key={t.id} transaction={t} />)}
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showSearch === false && (
            <div className="pos-sales-review__actions">
              <button
                className="pos-sales-review__action pos-sales-review__action_role_display-sale"
                type="button"
                disabled={selectedTransaction === undefined}
                onClick={displayClick}
              >
                Display Sale
              </button>
              <button className="pos-sales-review__action pos-sales-review__action_role_main-menu" type="button" onClick={mainMenuClick}>
                Main Menu
              </button>
              <button
                className="pos-sales-review__action pos-sales-review__action_role_search"
                type="button"
                onClick={() => setShowSearch(true)}
              >
                Search
              </button>
            </div>
          )}
          {showSearch === true && (
            <div className="pos-sales-review__controls">
              <div className="pos-sales-review__field">
                <div className="pos-field pos-field_role_search">
                  <div className="pos-field__group">
                    <div className="pos-field__box">{searchText}</div>
                  </div>
                </div>
              </div>
              <div className="pos-sales-review__keyboard">
                <div className="pos-keyboard pos-keyboard_role_search">
                  <div className="pos-keyboard__controls">
                    <div className="pos-keyboard__controls-row">
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('1')}>
                        1
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('2')}>
                        2
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('3')}>
                        3
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('4')}>
                        4
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('5')}>
                        5
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('6')}>
                        6
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('7')}>
                        7
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('8')}>
                        8
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('9')}>
                        9
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('10')}>
                        10
                      </button>
                    </div>
                    <div className="pos-keyboard__controls-row">
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Q')}>
                        q
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('W')}>
                        w
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('E')}>
                        e
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('R')}>
                        r
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('T')}>
                        t
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Y')}>
                        y
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('U')}>
                        u
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('I')}>
                        i
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('O')}>
                        o
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('P')}>
                        p
                      </button>
                    </div>
                    <div className="pos-keyboard__controls-row">
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('A')}>
                        a
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('S')}>
                        s
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('D')}>
                        d
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('F')}>
                        f
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('G')}>
                        g
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('H')}>
                        h
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('J')}>
                        j
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('K')}>
                        k
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('L')}>
                        l
                      </button>
                    </div>
                    <div className="pos-keyboard__controls-row">
                      <button className="pos-keyboard__control pos-keyboard__control_role_shift" type="button">
                        Shift
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Z')}>
                        z
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('X')}>
                        x
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('C')}>
                        c
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('V')}>
                        v
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('B')}>
                        b
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('N')}>
                        n
                      </button>
                      <button className="pos-keyboard__control" type="button" onClick={() => keyPress('M')}>
                        m
                      </button>
                      <button className="pos-keyboard__control pos-keyboard__control_role_backspace" type="button" onClick={backSpace}>
                        Backspace
                      </button>
                    </div>
                    <div className="pos-keyboard__controls-row">
                      <button
                        className="pos-keyboard__control pos-keyboard__control_role_space"
                        type="button"
                        onClick={() => keyPress(' ')}
                      >
                        Space
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-sales-review__control">
                <button className="pos-sales-review__action pos-sales-review__action_role_search" type="button" onClick={searchClick}>
                  Search
                </button>
              </div>
            </div>
          )}
          <div className="pos-sales-review__version">
            PUB INVEST GROUP LTD | API VERSION {engineInfo?.versionNumber} | UI VERSION {version?.version} | Last Pinged&nbsp;
            {serverLastPolled != 'Never' ? <Moment interval={1000} date={serverLastPolled} format="DD-MM-yyyy HH:mm:ss" /> : <>Never</>}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default SalesReview;
