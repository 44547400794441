import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import {
  LayoutDefinition,
  LayoutDefinitionVersion,
  LayoutLinkType,
  LayoutType,
  MenuActivePriceTier,
  MenuItem,
  MenuItemCategory,
  MenuItemCondiment,
  MessagePreset,
  Package,
  PackageVersion,
  TransactionLineItem,
  UnitOfSale,
  VoidReason,
} from '../api';
import { MenuVersion } from '../api/menu/MenuVersion';
import { Transaction } from '../api/transaction';

export interface OrderState {
  transaction: Transaction[];
  showSidebar: boolean;
  layoutDefinitions: LayoutDefinition[];
  menuItems: MenuItem[];
  menuItemCategories: MenuItemCategory[];
  menuItemCondiments: MenuItemCondiment[];
  unitOfSales: UnitOfSale[];
  voidReasons: VoidReason[];
  messagePresets: MessagePreset[];
  activeLayoutDefinition?: LayoutDefinition;
  activeCategory?: MenuItemCategory;
  menuPage: number;
  activeSubCategories: MenuItemCategory[];
  activeMenuItems: MenuItem[];
  activeCategoryIds: number[];
  quantity: number;
  quantityShown: boolean;
  activeTransactionLineItem?: TransactionLineItem;
  message: string;
  menuVersion: number;
  addToTransactionInProgress: boolean;
  packages: Package[];
  activePriceTiers: MenuActivePriceTier[];
  isMenuVersionWorkstationSpecific: boolean;
  layoutVersion: number;
  packageVersion: number;
  disableSignOff: boolean;
}

const initialState: OrderState = {
  showSidebar: false,
  menuPage: 1,
  layoutDefinitions: [],
  menuItemCategories: [],
  menuItems: [],
  unitOfSales: [],
  activeSubCategories: [],
  activeCategoryIds: [],
  activeMenuItems: [],
  menuItemCondiments: [],
  quantity: 1,
  quantityShown: false,
  transaction: [],
  message: '',
  messagePresets: [],
  voidReasons: [],
  menuVersion: -1,
  addToTransactionInProgress: false,
  packages: [],
  activePriceTiers: [],
  isMenuVersionWorkstationSpecific: false,
  layoutVersion: -1,
  packageVersion: -1,
  disableSignOff: false,
};

const getCategoryIds = (currentCategory: MenuItemCategory, allCategories: MenuItemCategory[]) => {
  const ids = [currentCategory.id];

  const childCategories = allCategories.filter((x) => x.parentCategoryId === currentCategory.id);
  const childIds = childCategories.map((x) => x.id);

  for (const child of childCategories) ids.push(...getCategoryIds(child, allCategories));

  ids.push(...childIds);
  return ids;
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setTransaction: (state: Draft<OrderState>, { payload }: PayloadAction<Transaction[]>) => {
      state.transaction = payload;
      state.activeTransactionLineItem = undefined;
      state.message = '';
    },
    setSidebarState: (state: Draft<OrderState>, { payload }: PayloadAction<boolean>) => {
      state.showSidebar = payload;
    },
    setLayoutDefinitions: (state: Draft<OrderState>, { payload }: PayloadAction<LayoutDefinitionVersion>) => {
      state.layoutDefinitions = payload.layoutDefinitions;
      state.layoutVersion = payload.version;
    },
    setMessagePresets: (state: Draft<OrderState>, { payload }: PayloadAction<MessagePreset[]>) => {
      state.messagePresets = payload;
    },
    setMenuItemCategories: (state: Draft<OrderState>, { payload }: PayloadAction<MenuItemCategory[]>) => {
      state.menuItemCategories = payload;
    },
    setMenuItems: (state: Draft<OrderState>, { payload }: PayloadAction<MenuItem[]>) => {
      state.menuItems = payload;
    },
    setPackages: (state: Draft<OrderState>, { payload }: PayloadAction<PackageVersion>) => {
      state.packages = payload.packages;
      state.packageVersion = payload.version;
    },
    setMenuItemCondiments: (state: Draft<OrderState>, { payload }: PayloadAction<MenuItemCondiment[]>) => {
      state.menuItemCondiments = payload;
    },
    setUnitOfSales: (state: Draft<OrderState>, { payload }: PayloadAction<UnitOfSale[]>) => {
      state.unitOfSales = payload;
    },
    setMenuVersion: (state: Draft<OrderState>, { payload }: PayloadAction<MenuVersion>) => {
      state.menuVersion = payload.versionNumber;
      state.menuItems = payload.menu;
      state.menuItemCondiments = payload.condiments;
      state.activePriceTiers = payload.activePriceTiers;
      state.isMenuVersionWorkstationSpecific = payload.workstationId !== null;
    },
    setVoidReasons: (state: Draft<OrderState>, { payload }: PayloadAction<VoidReason[]>) => {
      state.voidReasons = payload;
    },
    setActiveLayoutDefinition: (state: Draft<OrderState>, { payload }: PayloadAction<LayoutDefinition | undefined>) => {
      state.activeLayoutDefinition = payload;

      if (payload !== undefined) {
        if (payload.layoutLinkType === LayoutLinkType.Category) {
          const category = state.menuItemCategories.find((x) => x.id === payload.menuItemCategoryId);
          state.activeCategory = category;
          if (category !== undefined) {
            const childCategories = state.menuItemCategories.filter((x) => x.parentCategoryId === category.id);
            state.activeSubCategories = childCategories;
            state.activeCategoryIds = getCategoryIds(category, state.menuItemCategories);
          }
        } else {
          state.activeCategory = undefined;
          state.activeSubCategories = [];
        }
      }
    },
    setActiveCategory: (state: Draft<OrderState>, { payload }: PayloadAction<MenuItemCategory | undefined>) => {
      state.activeCategory = payload;
      if (payload !== undefined) {
        // Check if the category has children
        const childCategories = state.menuItemCategories.filter((x) => x.parentCategoryId === payload.id).length > 0;
        if (payload.parentCategoryId == undefined || childCategories) {
          const childCategories = state.menuItemCategories.filter((x) => x.parentCategoryId === payload.id);
          state.activeSubCategories = childCategories;
          state.activeCategoryIds = getCategoryIds(payload, state.menuItemCategories);
        } else state.activeCategoryIds = [payload.id];
      }
      if (payload === undefined) state.activeSubCategories = [];
    },
    clearOrderState: (state: Draft<OrderState>) => {
      state.showSidebar = false;
      state.activeLayoutDefinition = undefined;
      state.transaction = [];
      state.activeCategoryIds = [];
      state.menuPage = 1;
      state.activeTransactionLineItem = undefined;
      state.message = '';
      state.disableSignOff = false;
      state.quantity = 1;
    },
    setMenuPage: (state: Draft<OrderState>, { payload }: PayloadAction<number>) => {
      state.menuPage = payload;
    },
    setActiveMenuItems: (state: Draft<OrderState>, { payload }: PayloadAction<MenuItem[]>) => {
      state.activeMenuItems = payload;
    },
    setQuantity: (state: Draft<OrderState>, { payload }: PayloadAction<number>) => {
      state.quantity = payload;
      if (payload === 1) state.quantityShown = false;
    },
    setQuantityShow: (state: Draft<OrderState>, { payload }: PayloadAction<boolean>) => {
      state.quantityShown = payload;
    },
    setActiveTransactionLineItem: (state: Draft<OrderState>, { payload }: PayloadAction<TransactionLineItem | undefined>) => {
      state.activeTransactionLineItem = payload;
    },
    setMessage: (state: Draft<OrderState>, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    setAddToTransactionInProgress: (state: Draft<OrderState>, { payload }: PayloadAction<boolean>) => {
      state.addToTransactionInProgress = payload;
    },
    setDisableSignOff: (state: Draft<OrderState>, { payload }: PayloadAction<boolean>) => {
      state.disableSignOff = payload;
    },
  },
});
export const {
  setSidebarState,
  setLayoutDefinitions,
  setActiveLayoutDefinition,
  clearOrderState,
  setActiveCategory,
  setMenuPage,
  setMenuItems,
  setMenuItemCategories,
  setUnitOfSales,
  setActiveMenuItems,
  setQuantity,
  setQuantityShow,
  setMenuItemCondiments,
  setTransaction,
  setActiveTransactionLineItem,
  setMessagePresets,
  setMessage,
  setVoidReasons,
  setMenuVersion,
  setAddToTransactionInProgress,
  setPackages,
  setDisableSignOff,
} = orderSlice.actions;

export default orderSlice.reducer;

const selectTransaction = (state: RootState) => state.order.transaction;
const selectShowSidebar = (state: RootState) => state.order.showSidebar;
const selectLayoutDefinitions = (state: RootState) => state.order.layoutDefinitions;
const selectMenuItems = (state: RootState) => state.order.menuItems;
const selectMenuItemCondiments = (state: RootState) => state.order.menuItemCondiments;
const selectMessagePresets = (state: RootState) => state.order.messagePresets;
const selectMenuItemCategories = (state: RootState) => state.order.menuItemCategories;
const selectUnitOfSales = (state: RootState) => state.order.unitOfSales;
const selectVoidReasons = (state: RootState) => state.order.voidReasons;
const selectActiveLayoutDefinitions = (state: RootState) => state.order.activeLayoutDefinition;
const selectActiveCategory = (state: RootState) => state.order.activeCategory;
const selectMenuPage = (state: RootState) => state.order.menuPage;
const selectActiveSubCategories = (state: RootState) => state.order.activeSubCategories;
const selectActiveCategoryIds = (state: RootState) => state.order.activeCategoryIds;
const selectActiveMenuItems = (state: RootState) => state.order.activeMenuItems;
const selectActiveTransactionLineItem = (state: RootState) => state.order.activeTransactionLineItem;
const selectMessage = (state: RootState) => state.order.message;
const selectAddToTransactionInProgress = (state: RootState) => state.order.addToTransactionInProgress;
const selectPackages = (state: RootState) => state.order.packages;

const selectQuantity = (state: RootState) => state.order.quantity;
const selectQuantityShown = (state: RootState) => state.order.quantityShown;
const selectMenuVersionNumber = (state: RootState) => state.order.menuVersion;
const selectActivePriceTiers = (state: RootState) => state.order.activePriceTiers;
const selectIsMenuVersionWorkstationSpecific = (state: RootState) => state.order.isMenuVersionWorkstationSpecific;
const selectLayoutDefinitionVersion = (state: RootState) => state.order.layoutVersion;
const selectPackageVersion = (state: RootState) => state.order.packageVersion;
const selectDisableSignOff = (state: RootState) => state.order.disableSignOff;

const selectNumberOfFastBarPages = createSelector(selectLayoutDefinitions, (layoutDefinitions) => {
  return layoutDefinitions
    ? Math.max(
        ...layoutDefinitions
          .filter((x) => x.layoutType === LayoutType.FastBar)
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((p) => p.pageNumber),
      )
    : 0;
});

const selectHasLineItems = createSelector(selectTransaction, (transaction) => {
  return transaction.length == 1 ? transaction[0].transactionLineItems.length > 0 : false;
});

export const useShowSidebar = () => useAppSelector(selectShowSidebar);
export const useLayoutDefinitions = () => useAppSelector(selectLayoutDefinitions);
export const useMenuItems = () => useAppSelector(selectMenuItems);
export const useMenuItemCondiments = () => useAppSelector(selectMenuItemCondiments);
export const useMenuItemCategories = () => useAppSelector(selectMenuItemCategories);
export const useMenuItemCategory = (categoryId: number) => useAppSelector(selectMenuItemCategories).find((x) => x.id === categoryId);
export const useUnitOfSales = () => useAppSelector(selectUnitOfSales);
export const useActiveLayoutDefinition = () => useAppSelector(selectActiveLayoutDefinitions);
export const useActiveCategory = () => useAppSelector(selectActiveCategory);
export const useMenuPage = () => useAppSelector(selectMenuPage);
export const useNumberOfFastBarPages = () => useAppSelector(selectNumberOfFastBarPages);
export const useActiveSubCategories = () => useAppSelector(selectActiveSubCategories);
export const useActiveCategoryIds = () => useAppSelector(selectActiveCategoryIds);
export const useActiveMenuItems = () => useAppSelector(selectActiveMenuItems);
export const useTransaction = () => useAppSelector(selectTransaction);
export const useQuantity = () => useAppSelector(selectQuantity);
export const useQuantityShown = () => useAppSelector(selectQuantityShown);
export const useActiveTransactionLineItem = () => useAppSelector(selectActiveTransactionLineItem);
export const useMessage = () => useAppSelector(selectMessage);
export const useMessagePresets = () => useAppSelector(selectMessagePresets);
export const useVoidReasons = () => useAppSelector(selectVoidReasons);
export const useMenuVersionNumber = () => useAppSelector(selectMenuVersionNumber);
export const useAddToTransactionInProgress = () => useAppSelector(selectAddToTransactionInProgress);
export const useSelectHasLineItems = () => useAppSelector(selectHasLineItems);
export const usePackages = () => useAppSelector(selectPackages);
export const useActivePriceTiers = () => useAppSelector(selectActivePriceTiers);
export const useIsMenuVersionWorkstationSpecific = () => useAppSelector(selectIsMenuVersionWorkstationSpecific);
export const useLayoutDefinitionVersion = () => useAppSelector(selectLayoutDefinitionVersion);
export const usePackageVersion = () => useAppSelector(selectPackageVersion);
export const useDisableSignOff = () => useAppSelector(selectDisableSignOff);
