import { Navigate, useLocation } from 'react-router-dom';

type NotFoundHandlerProps = {
  notFoundPath: string;
};

function NotFoundHandler({ notFoundPath }: NotFoundHandlerProps) {
  const location = useLocation();

  return <Navigate to={notFoundPath} replace state={{ path: location.pathname }}></Navigate>;
}

export default NotFoundHandler;
