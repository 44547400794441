import React from 'react';
import { Provider } from 'react-redux';

import { createRoot } from 'react-dom/client';

import App from './app/App';
import { store } from './app/redux/store';
import reportWebVitals from './reportWebVitals';

import './styles/sass/index.sass';
import './styles/override.sass';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
