import { Outlet, useNavigate } from 'react-router-dom';

import './style.sass';
import { useAppDispatch } from '../../app/redux/hooks';

import { clear } from './signOnSlice';

function SignOn() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') {
            navigate('/');
            dispatch(clear());
          }
        }}
      >
        <div className="pos-modal pos-modal_role_sign-in">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default SignOn;
