import spinner from './assets/spinner.svg';
import './assets/styles.sass';

function Loader() {
  return (
    <div className="x-pos-loader-body">
      <div className="x-pos-loader-container">
        <div className="x-pos-loader-box">
          <img className="loader-element" src={spinner} height={200} />
        </div>
      </div>
    </div>
  );
}

export default Loader;
