import { useEffect, useState } from 'react';

import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import { usePacSignatureVerifyMutation } from '../api';

import { PacSignatureVerifyMessage } from './pacSignatureVerifyMessage';
import './pacSignatureVerify.sass';

function PacSignatureVerify() {
  const [message, setMessage] = useState<PacSignatureVerifyMessage | undefined>();
  const [triggerVerify] = usePacSignatureVerifyMutation();

  useEffect(() => {
    const sub = appSubscriptionService.cardSignatureObservable().subscribe((val) => {
      setMessage(val);
    });
    return () => sub.unsubscribe();
  }, [setMessage]);

  const dismiss = () => {
    setMessage(undefined);
  };
  const verify = (accepted: boolean) => {
    if (message) {
      triggerVerify({
        accepted: accepted,
        verificationRequestId: message.verificationRequestId,
      })
        .unwrap()
        .then(() => {
          dismiss();
        })
        .catch(() => {
          dismiss();
        });
    }
  };

  return (
    <>
      {message && (
        <div className="pos-modal-portal">
          <div className={`pos-modal-overlay`}>
            <div className="pos-popup pos-popup_role_error">
              <div className="pos-popup__content">
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Signature Verification!</h3>
                  <p className="pos-popup__text">
                    Signature Verification required for {message.transactionNumber} on {message.terminalId}
                  </p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={() => verify(true)}>
                    Accept
                  </button>
                  <button className="pos-popup__action" onClick={() => verify(false)}>
                    Decline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PacSignatureVerify;
