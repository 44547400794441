export enum QrCodeReaderType {
  Scanner = 1,
  Camera = 2,
}

export interface Workstation {
  id: number;
  terminalNumber: number;
  hasFobReader: boolean;
  qrCodeReaderType: QrCodeReaderType;
  hasReceiptPrinter: boolean;
  hasSession: boolean;
  hideTables: boolean;
  isSessionBlockNewTransactions: boolean;
  isAppTill: boolean;
  requireTableToCompleteTransaction: boolean;
}
