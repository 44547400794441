import { useAppDispatch } from '../../../../app/redux/hooks';
import { LayoutDefinition, LayoutLinkType, LayoutType } from '../../../api';
import {
  setActiveLayoutDefinition,
  setMenuPage,
  useLayoutDefinitions,
  useMenuItems,
  useMenuPage,
  useNumberOfFastBarPages,
} from '../../orderSlice';

import { FastBarCategoryButton } from './FastBarCategoryButton';
import { FastBarPackageButton } from './FastBarPackageButton';
import MenuButton from './MenuButton';

function FastBarPanel() {
  const dispatch = useAppDispatch();
  const menuPage = useMenuPage();
  const layoutDefinitions = useLayoutDefinitions();
  const numberOfPages = useNumberOfFastBarPages();
  const menuItems = useMenuItems();

  const categoryClick = (layoutDefinition: LayoutDefinition) => {
    dispatch(setActiveLayoutDefinition(layoutDefinition));
    dispatch(setMenuPage(1));
  };
  const fastBarLayoutDefinitions = layoutDefinitions.filter((x) => x.layoutType === LayoutType.FastBar);
  let categoryIds = fastBarLayoutDefinitions.filter((x) => x.layoutLinkType === LayoutLinkType.Category).map((x) => x.menuItemCategoryId);
  const menuItemLayoutDefinitions = fastBarLayoutDefinitions.filter((x) => x.layoutLinkType === LayoutLinkType.MenuItem);
  for (const miLd of menuItemLayoutDefinitions) {
    if (miLd.menuItemId !== undefined) {
      const mi = menuItems.find((x) => x.id == miLd.menuItemId);
      if (mi) for (const cid of mi.menuItemCategoryIds) categoryIds.push(cid);
    }
  }
  categoryIds = categoryIds.filter((v, i, a) => a.indexOf(v) === i);
  const color = (categoryId: number) => `category-color-${categoryIds.indexOf(categoryId) + 1}`;
  const FastBarButton = ({ position }: { position: number }) => {
    const layoutDefinition = layoutDefinitions.find(
      (x) => x.layoutType === LayoutType.FastBar && x.pageNumber === menuPage && x.positionNumber === position,
    );

    if (layoutDefinition !== undefined) {
      if (layoutDefinition.layoutLinkType === LayoutLinkType.MenuItem && layoutDefinition.menuItemId !== undefined) {
        const menuItem = menuItems.find((x) => x.id === layoutDefinition.menuItemId);
        if (menuItem) return <MenuButton menuItem={menuItem} color={color(menuItem.menuItemCategoryIds[0])} />;
        else return <div className="pos-menu__item hidden-button"></div>;
      } else if (layoutDefinition.layoutLinkType === LayoutLinkType.Category && layoutDefinition.menuItemCategoryId !== undefined)
        return <FastBarCategoryButton layoutDefinition={layoutDefinition} />;
      else if (layoutDefinition.layoutLinkType === LayoutLinkType.Package && layoutDefinition.packageId !== undefined)
        return <FastBarPackageButton layoutDefinition={layoutDefinition} />;
      else if (layoutDefinition.layoutLinkType === LayoutLinkType.Packages) {
        return (
          <div className={`pos-menu__item category-button-fastbar`} onClick={() => categoryClick(layoutDefinition)}>
            <p className="pos-menu__category-text">Packages</p>
          </div>
        );
      }
    }
    return <div className="pos-menu__item hidden-button"></div>;
  };

  return (
    <div className="pos-menu__items">
      <div className="pos-menu__items-row">
        <FastBarButton position={1} />
        <FastBarButton position={2} />
        <FastBarButton position={3} />
        <FastBarButton position={4} />
      </div>
      <div className="pos-menu__items-row">
        <FastBarButton position={5} />
        <FastBarButton position={6} />
        <FastBarButton position={7} />
        <FastBarButton position={8} />
      </div>
      <div className="pos-menu__items-row">
        <FastBarButton position={9} />
        <FastBarButton position={10} />
        <FastBarButton position={11} />
        <FastBarButton position={12} />
      </div>
      <div className="pos-menu__items-row">
        <FastBarButton position={13} />
        <FastBarButton position={14} />
        <FastBarButton position={15} />
        <FastBarButton position={16} />
      </div>
      <div className="pos-menu__items-row">
        <FastBarButton position={17} />
        <FastBarButton position={18} />
        <FastBarButton position={19} />
        <FastBarButton position={20} />
      </div>
      <div className="pos-menu__items-row">
        <FastBarButton position={21} />
        <FastBarButton position={22} />
        <FastBarButton position={23} />
        <FastBarButton position={24} />
      </div>
      <div className="pos-menu__items-row">
        <button
          className="pos-menu__items-control pos-menu__items-control_role_previous"
          type="button"
          onClick={() => dispatch(setMenuPage(menuPage - 1))}
          disabled={menuPage === 1}
        >
          Previous
        </button>
        <FastBarButton position={25} />
        <FastBarButton position={26} />
        <button
          className="pos-menu__items-control pos-menu__items-control_role_next"
          type="button"
          onClick={() => dispatch(setMenuPage(menuPage + 1))}
          disabled={menuPage >= numberOfPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default FastBarPanel;
