import { useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../../../app/redux/hooks';
import Price from '../../../../common/components/price/Price';
import { Booking, useGetBookingsQuery, useTransactionRedeemBookingMutation } from '../../../api';
import { useOperatorSession } from '../../../appState';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { useTransaction } from '../../orderSlice';

import 'swiper/css/bundle';
import './style.sass';

function Bookings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const currentTransaction = useTransaction();

  const { data: bookings, isLoading } = useGetBookingsQuery();
  const [bookingSelected, setBookingSelected] = useState<Booking | undefined>();
  const [triggerBookingRedemption] = useTransactionRedeemBookingMutation();
  const selectBooking = (booking: Booking) => {
    if (booking.redeemed === false) {
      if (booking.amountPaid < booking.amount) {
        dispatch(
          showErrorDialog({
            message: 'Please ask the customer to complete payment for the booking before it is available to use on the till',
            dismissible: true,
          }),
        );
      } else setBookingSelected(booking);
    }
  };
  const redeemBooking = (booking: Booking) => {
    /*if (currentTransaction.length > 0 && currentTransaction[0].totalValue > 0 && booking.amountPaid < booking.amount) {
      dispatch(
        showErrorDialog({
          message: 'You cannot assign a booking when the transaction has items already added',
          dismissible: true,
        }),
      );
      return;
    }*/
    if (currentTransaction[0].tableId == undefined) {
      dispatch(
        showErrorDialog({
          message: 'You must have a table allocated to the transaction first',
          dismissible: true,
        }),
      );
      return;
    }
    if (operatorSession) {
      triggerBookingRedemption({
        bookingId: booking.id,
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          navigate('/order');
        })
        .catch((err) => {
          if (err && err.status && err.status === 412) dispatch(showErrorDialog({ message: err.data.title, dismissible: true }));
        });
    }
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_bookings">
          {bookings && (
            <div className="pos-modal__content">
              <div className="pos-bookings js-bookings">
                <div className="pos-bookings__header">
                  <h2 className="pos-bookings__title">Bookings</h2>
                </div>
                <div className="pos-bookings__info">
                  <div className="pos-bookings__meta">
                    <div className="pos-bookings__meta-date">Today</div>
                    <div className="pos-bookings__meta-count">{bookings.length}</div>
                  </div>
                  <div className="pos-bookings__list">
                    <div className="pos-bookings__list-scroll js-bookings-scroll">
                      <Swiper
                        freeMode={true}
                        height={300}
                        slidesPerView="auto"
                        spaceBetween={30}
                        mousewheel={true}
                        scrollbar={true}
                        direction="vertical"
                        pagination={{ clickable: true }}
                        modules={[FreeMode, Pagination]}
                      >
                        <SwiperSlide>
                          <div className="pos-bookings__list-content">
                            {bookings.map((booking) => (
                              <div
                                key={booking.id}
                                onClick={() => selectBooking(booking)}
                                className={`pos-bookings__item${booking.redeemed ? ' is-redeemed' : ''}${
                                  booking.redeemed === false && booking.inUse === true ? ' is-in-use' : ''
                                }${booking.amountPaid < booking.amount ? ' is-not-paid' : ''}`}
                              >
                                {booking.redeemed && <div className="pos-bookings__item-status">Redeemed</div>}
                                {booking.redeemed === false && booking.inUse === true && (
                                  <div className="pos-bookings__item-status">IN USE</div>
                                )}
                                {booking.amountPaid < booking.amount && booking.redeemed === false && booking.inUse === false && (
                                  <div className="pos-bookings__item-status">NOT PAID</div>
                                )}
                                <div className="pos-bookings__item-area pos-bookings__item-area_role_summary">
                                  <h3 className="pos-bookings__item-name">{booking.name}</h3>
                                  <div className="pos-bookings__item-summary">
                                    <div className="pos-bookings__item-summary-line">
                                      <span className="pos-bookings__item-summary-label">Time:</span>
                                      <span className="pos-bookings__item-summary-value">
                                        <Moment format="HH:mm:ss" date={booking.startDateTime} />
                                      </span>
                                    </div>
                                    <div className="pos-bookings__item-summary-line">
                                      <span className="pos-bookings__item-summary-label">Deposit:</span>
                                      <span className="pos-bookings__item-summary-value">
                                        <Price price={booking.amount} />
                                      </span>
                                    </div>
                                    <div className="pos-bookings__item-summary-line">
                                      <span className="pos-bookings__item-summary-label">Party Size:</span>
                                      <span className="pos-bookings__item-summary-value">{booking.guestCount}</span>
                                    </div>
                                    <div className="pos-bookings__item-summary-line">
                                      <span className="pos-bookings__item-summary-label">Booking ID:</span>
                                      <span className="pos-bookings__item-summary-value">{booking.bookingId}</span>
                                    </div>
                                    {booking.area !== null && (
                                      <div className="pos-bookings__item-summary-line">
                                        <span className="pos-bookings__item-summary-label">Area:</span>
                                        <span className="pos-bookings__item-summary-value">{booking.area}</span>
                                      </div>
                                    )}
                                    {booking.booth !== null && (
                                      <div className="pos-bookings__item-summary-line">
                                        <span className="pos-bookings__item-summary-label">Booth:</span>
                                        <span className="pos-bookings__item-summary-value">{booking.booth}</span>
                                      </div>
                                    )}
                                    {booking.tableNumber !== null && (
                                      <div className="pos-bookings__item-summary-line">
                                        <span className="pos-bookings__item-summary-label">Table:</span>
                                        <span className="pos-bookings__item-summary-value">{booking.tableNumber}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="pos-bookings__item-area pos-bookings__item-area_role_details">
                                  <div className="pos-bookings__item-details">
                                    <div className="pos-bookings__item-details-record">
                                      <span className="pos-bookings__item-details-label">Requirements:</span>
                                      <span className="pos-bookings__item-details-value">MISSING DATA</span>
                                    </div>
                                    <div className="pos-bookings__item-details-record">
                                      <span className="pos-bookings__item-details-label">Notes:</span>
                                      <span className="pos-bookings__item-details-value">{booking.description}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="pos-bookings__actions">
                  <button className="pos-bookings__action hidden-button" type="button">
                    Select Date
                  </button>
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="pos-modal__loader">
              <div className="pos-loader pos-loader_type_light">
                <div className="pos-loader__spinner"></div>
                <p className="pos-loader__label">Loading...</p>
              </div>
            </div>
          )}
          {bookingSelected && (
            <div className="pos-modal__popup">
              <div className="pos-popup pos-popup_role_redeem-deposit">
                <div className="pos-popup__content">
                  <div className="pos-popup__info">
                    <h3 className="pos-popup__title">
                      {bookingSelected.amountPaid === bookingSelected.amount ? 'Redeem Deposit?' : 'Assign Booking?'}
                    </h3>
                    {bookingSelected.amount !== bookingSelected.amountPaid && (
                      <p className="pos-popup__label booking-not-paid">
                        This booking has not been paid. The payment will be taken first before the booking is available.
                      </p>
                    )}
                    <p className="pos-popup__label">{bookingSelected.name}</p>
                    <p className="pos-popup__text">
                      <Price price={bookingSelected.amount} />
                    </p>
                  </div>
                  <div className="pos-popup__actions">
                    <button className="pos-popup__action pos-popup__action_role_no" onClick={() => setBookingSelected(undefined)}>
                      No
                    </button>
                    <button className="pos-popup__action pos-popup__action_role_yes" onClick={() => redeemBooking(bookingSelected)}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bookings;
