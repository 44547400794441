import { useNavigate } from 'react-router-dom';

import { useGetOrderDevicesQuery, useGetPrintersQuery } from '../../../api';
import './styles.sass';

export default function WorkstationOrderDevices() {
  const navigate = useNavigate();
  const { data: orderDevices } = useGetOrderDevicesQuery();
  const { data: printers } = useGetPrintersQuery();
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Order Devices</h2>
              </div>
              <div className="wod">
                <table>
                  <thead>
                    <tr>
                      <th>Order Device</th>
                      <th>Is Routed To</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDevices &&
                      orderDevices.map((od) => (
                        <tr key={od.id}>
                          <td>{od.orderDeviceName}</td>
                          <td>
                            {od.overridePrinterId != null
                              ? printers?.find((p) => p.id === od.overridePrinterId)?.name ?? 'Unknown'
                              : 'Default'}
                          </td>
                          <td>
                            <div className="button">
                              <button onClick={() => navigate(`./${od.id}`)}>Override</button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
