import { useEffect, useState } from 'react';

import { Package } from '../../../api';
import { usePackages } from '../../orderSlice';

import PackageButton from './PackageButton';

function PackageButtonListPanel() {
  const packages = usePackages();
  const [numPages, setNumPages] = useState(1);
  const [packagePage, setPackagePage] = useState(1);

  const [displayPackages, setDisplayPackages] = useState<Package[]>([]);

  const PackagePositionedButton = ({ position }: { position: number }) => {
    const index = position - 1;
    if (displayPackages.length >= position) {
      const pkg = displayPackages[index];
      return <PackageButton packageItem={pkg} />;
    }
    return <div className="pos-menu__item hidden-button"></div>;
  };

  useEffect(() => {
    const pageTotal = Math.ceil(packages.length / 26);
    setNumPages(pageTotal);
  }, [packages]);

  useEffect(() => {
    if (packages) {
      let offsetStart = (packagePage - 1) * 26 + 1;
      let offsetEnd = packagePage * 26;

      if (offsetEnd > packages.length) offsetEnd = packages.length;
      if (offsetStart > 0) offsetStart = offsetStart - 1;
      const items = packages.slice(offsetStart, offsetEnd);

      setDisplayPackages(items);
    }
  }, [packagePage, packages]);

  return (
    <div className="pos-menu__items">
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={1} />
        <PackagePositionedButton position={2} />
        <PackagePositionedButton position={3} />
        <PackagePositionedButton position={4} />
      </div>
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={5} />
        <PackagePositionedButton position={6} />
        <PackagePositionedButton position={7} />
        <PackagePositionedButton position={8} />
      </div>
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={9} />
        <PackagePositionedButton position={10} />
        <PackagePositionedButton position={11} />
        <PackagePositionedButton position={12} />
      </div>
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={13} />
        <PackagePositionedButton position={14} />
        <PackagePositionedButton position={15} />
        <PackagePositionedButton position={16} />
      </div>
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={17} />
        <PackagePositionedButton position={18} />
        <PackagePositionedButton position={19} />
        <PackagePositionedButton position={20} />
      </div>
      <div className="pos-menu__items-row">
        <PackagePositionedButton position={21} />
        <PackagePositionedButton position={22} />
        <PackagePositionedButton position={23} />
        <PackagePositionedButton position={24} />
      </div>
      <div className="pos-menu__items-row">
        <button
          className="pos-menu__items-control pos-menu__items-control_role_previous"
          type="button"
          onClick={() => setPackagePage(packagePage - 1)}
          disabled={packagePage === 1}
        >
          Previous
        </button>
        <PackagePositionedButton position={25} />
        <PackagePositionedButton position={26} />
        <button
          className="pos-menu__items-control pos-menu__items-control_role_next"
          type="button"
          onClick={() => setPackagePage(packagePage + 1)}
          disabled={packagePage >= numPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PackageButtonListPanel;
