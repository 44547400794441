import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from '../../features/api/baseApi';
import appStateReducer from '../../features/appState/appStateSlice';
import authReducer from '../../features/auth/authSlice';
import configReducer from '../../features/config/configSlice';
import errorDialogReducer from '../../features/error-dialog/errorDialogSlice';
import modalReducer from '../../features/modal/modalDialogSlice';
import orderReducer from '../../features/order/orderSlice';
import salesReviewReducer from '../../features/sales-review/salesReviewSlice';
import splitReducer from '../../features/split/splitSplice';
import tableReducer from '../../features/tables/tableSlice';
import tenderReducer from '../../features/tender/tenderSlice';
import signOnReducer from '../../features/welcome/signOnSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  appState: appStateReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  errorDialog: errorDialogReducer,
  signOn: signOnReducer,
  modal: modalReducer,
  order: orderReducer,
  table: tableReducer,
  tender: tenderReducer,
  split: splitReducer,
  salesReview: salesReviewReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
