export enum OperatorLevel {
  Admin = 1,
  Manager = 2,
  Supervisor = 3,
  Operator = 4,
}

export interface Operator {
  id: number;
  firstName: string;
  lastName: string;
  operatorLevel: OperatorLevel;
}

export interface OperatorSearchResult extends Operator {
  hasActiveSession: boolean;
  terminalNumber: string | null;
  startDateTime: string | null;
}
