import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/redux/hooks';
import appLogger from '../../../common/logger/AppLogger';
import {
  OperatorLevel,
  useDealExclusionMutation,
  useNoSaleTransactionMutation,
  useOperatorSignOffMutation,
  usePriceTierMutation,
  usePrintLastTransactionReceiptMutation,
} from '../../api';
import { useEngineInfo, useOperatorSession } from '../../appState';
import { selectVersion } from '../../config';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import { showModalDialog } from '../../modal/modalDialogSlice';
import { setSidebarState, useActivePriceTiers, useTransaction } from '../orderSlice';

function Sidebar() {
  const dispatch = useAppDispatch();
  const engineInfo = useEngineInfo();
  const navigate = useNavigate();
  const version = useAppSelector(selectVersion);
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const [triggerNoSale] = useNoSaleTransactionMutation();
  const [triggerDealDisable] = useDealExclusionMutation();
  const [triggerPriceTierOverride] = usePriceTierMutation();
  const [triggerReprintLast] = usePrintLastTransactionReceiptMutation();
  const [triggerSignOff] = useOperatorSignOffMutation();
  const activePriceTiers = useActivePriceTiers();

  const noSale = () => {
    if (operatorSession != null) {
      triggerNoSale({
        operatorId: operatorSession.operator.id,
        transactionId: transaction[0].id,
      })
        .unwrap()
        .then(() => {
          appLogger.info('Transaction no saled');
        })
        .catch(() => {
          dispatch(
            showErrorDialog({
              message: 'Failed to no sale, is the transaction in progress on this till?',
              buttonText: 'OK',
              dismissible: true,
            }),
          );
        });
    }
  };

  const quitApp = () => {
    if (operatorSession) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const theWindow = window as any;
          theWindow.hostApp.quit();
        })
        .catch((err) => {
          if (err.status === 412) {
            dispatch(
              showErrorDialog({
                message: err.data.title,
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Something went wrong',
                dismissible: true,
              }),
            );
          }
        });
    }
  };

  const restartApp = () => {
    if (operatorSession) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          if (err.status === 412) {
            dispatch(
              showErrorDialog({
                message: err.data.title,
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Something went wrong',
                dismissible: true,
              }),
            );
          }
        });
    }
  };

  const isOperatorPermittedSalesReview = operatorSession
    ? operatorSession.operator.operatorLevel !== OperatorLevel.Operator
      ? true
      : false
    : false;
  return (
    <div className="pos-sidebar-overlay js-sidebar-overlay is-visible">
      <div className="pos-sidebar">
        <div className="pos-sidebar__menu">
          <ul className="pos-sidebar__menu-list">
            <li className="pos-sidebar__menu-item">
              <button className="pos-sidebar__menu-action waiter-id-button" type="button">
                PED Waiter ID - {operatorSession?.pedWaiterId}
              </button>
            </li>
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  navigate('./sign-off-confirmation');
                  dispatch(setSidebarState(false));
                }}
              >
                Sign Out
              </button>
            </li>
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  dispatch(setSidebarState(false));
                  if (operatorSession) triggerReprintLast(operatorSession.operator.id);
                }}
              >
                Print Last Receipt
              </button>
            </li>
            {isOperatorPermittedSalesReview && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    if (transaction.length == 0 || (transaction.length == 1 && transaction[0].transactionLineItems.length === 0))
                      navigate('/sales-review');
                    else {
                      dispatch(
                        showErrorDialog({
                          message: 'A transaction is in progress, please complete before opening Sales Review',
                          dismissible: true,
                        }),
                      );
                    }
                  }}
                >
                  Sales Review
                </button>
              </li>
            )}
            {isOperatorPermittedSalesReview && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    navigate('./workstation-sessions');
                  }}
                >
                  Terminal Sessions
                </button>
              </li>
            )}
            {isOperatorPermittedSalesReview && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    noSale();
                  }}
                >
                  No Sale
                </button>
              </li>
            )}
            {operatorSession?.operator.operatorLevel === OperatorLevel.Admin && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    triggerDealDisable();
                  }}
                >
                  Disable Deals
                </button>
              </li>
            )}
            {operatorSession?.operator.operatorLevel === OperatorLevel.Admin && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    triggerPriceTierOverride();
                  }}
                >
                  Disable Price Tiers
                </button>
              </li>
            )}
            {operatorSession?.operator.operatorLevel && operatorSession.operator.operatorLevel <= OperatorLevel.Manager && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    dispatch(setSidebarState(false));
                    navigate('./workstation-order-devices');
                  }}
                >
                  Order Device Routing
                </button>
              </li>
            )}
            <li className="pos-sidebar__menu-item">
              <button className="pos-sidebar__menu-action" type="button" onClick={restartApp}>
                Restart
              </button>
            </li>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              isOperatorPermittedSalesReview && (window as any).hostApp !== undefined && (
                <li className="pos-sidebar__menu-item">
                  <button className="pos-sidebar__menu-action" type="button" onClick={quitApp}>
                    Quit
                  </button>
                </li>
              )
            }
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  dispatch(setSidebarState(false));
                  dispatch(
                    showModalDialog({
                      title: 'System Information',
                      message: `<p>
                      <b>API Version:</b> ${engineInfo?.versionNumber}<br />
                      <b>UI Version:</b> ${version?.informational}<br />
                      <b>Database:</b> ${engineInfo?.databaseName}<br/>
                      <b>Environment:</b> ${engineInfo?.environment}
                      </p>
                      <br />
                      <p>
                        <b>Active Price Tiers:</b><br /> ${activePriceTiers.map((pt) => pt.name).join('<br />')}
                      </p>`,
                      height: '400px',
                      width: '400px',
                    }),
                  );
                }}
              >
                System Info
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
