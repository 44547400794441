import { useCallback, useEffect, useRef, useState } from 'react';
import { createMemoryRouter, RouterProvider } from 'react-router-dom';

import Loader from '../common/components/loader/Loader';
import { setTrainingModeSource } from '../features/appState';
import { IConfig, selectConfig, selectVersion, store, Version, versionStore } from '../features/config';
import { getRoutes } from '../routes/routes';

import { useAppDispatch, useAppSelector } from './redux/hooks';
import TraceProvider, { traceSpan } from './tracing/TraceProvider';

const router = createMemoryRouter(getRoutes());

function App() {
  console.info('APPLICATION LOADING');

  const configFetchedRef = useRef(false);
  const versionFetchedRef = useRef(false);
  const [configLoadError, setConfigLoadError] = useState(false);
  const config = useAppSelector<IConfig | undefined>(selectConfig);
  const version = useAppSelector<Version | undefined>(selectVersion);

  const dispatch = useAppDispatch();

  const isTrainingModeCb = useCallback(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const source = params.get('source');
      if (source) if (params.has('trainingMode') && params.get('trainingMode') == 'true') dispatch(setTrainingModeSource(source));
    }
  }, [dispatch]);

  const getVersion = useCallback(() => {
    traceSpan('version.load', () => {
      if (version === undefined && versionFetchedRef.current === false) {
        versionFetchedRef.current = true;
        fetch('/version.json')
          .then((resp) => resp.json())
          .then((data: Version) => {
            dispatch(versionStore(data));
          })
          .catch((err) => {
            setConfigLoadError(true);
            console.error(err);
          });
      }
    });
  }, [version, dispatch]);
  const getConfig = useCallback(() => {
    traceSpan('config.load', () => {
      if (config === undefined && configFetchedRef.current === false) {
        configFetchedRef.current = true;
        fetch('/config/config.json')
          .then((resp) => resp.json())
          .then((data: IConfig) => {
            dispatch(store(data));
          })
          .catch((err) => {
            setConfigLoadError(true);
            console.error(err);
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(getConfig, [getConfig]);
  useEffect(getVersion, [getVersion]);
  useEffect(isTrainingModeCb, [window.location.origin, isTrainingModeCb]);

  if (configLoadError) return <div>Failed to load application configuration</div>;

  if (config === undefined || version === undefined) return <Loader />;
  else {
    return (
      <TraceProvider>
        <RouterProvider router={router} />
      </TraceProvider>
    );
  }
}

export default App;
