/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logger, SeverityNumber } from '@opentelemetry/api-logs';
import { OTLPLogExporter } from '@opentelemetry/exporter-logs-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { BatchLogRecordProcessor, LoggerProvider } from '@opentelemetry/sdk-logs';

import { User } from '../../features/auth';
import { IConfig } from '../../features/config';

let logExporter: OTLPLogExporter | undefined;
let loggerProvider: LoggerProvider | undefined;
let logger: Logger | undefined;

class AppLogger {
  private static config: IConfig | undefined;
  private static user: User | undefined;

  static initialise(config: IConfig, user: User | undefined) {
    this.config = config;
    this.user = user;
  }

  private static getLogger(): Logger | undefined {
    if (logger === undefined) {
      if (this.config !== undefined && this.config.telemetry.collectorUrl != '') {
        const getAttributes = () => {
          if (this.user !== undefined) {
            return {
              'service.name': this.config?.telemetry.serviceName,
              'deployment.environment': this.config?.app.environmentName.toLowerCase(),
              'user.id': this.user.id,
              'user.name': this.user.userName,
              'user.domain': this.user.firstName,
            };
          }

          return {
            'service.name': this.config?.telemetry.serviceName,
            'deployment.environment': this.config?.app.environmentName.toLowerCase(),
          };
        };

        const resource = new Resource(getAttributes());

        logExporter = new OTLPLogExporter({
          url: `${this.config.telemetry.collectorUrl}/v1/logs`,
        });
        loggerProvider = new LoggerProvider({
          resource,
        });

        loggerProvider.addLogRecordProcessor(new BatchLogRecordProcessor(logExporter));
        logger = loggerProvider.getLogger('default');
      } else return;
    }
    return logger;
  }

  private static formatMessage(message?: any, ...optionalParams: any[]): any {
    if (message) {
      if (message instanceof String) return `${message} ${JSON.stringify(optionalParams)}`;
      return `${JSON.stringify(message)} ${JSON.stringify(optionalParams)}`;
    }
    return message;
  }

  static debug(message?: any, ...optionalParams: any[]): void {
    console.debug(message, ...optionalParams);
    const logger = this.getLogger();
    if (logger) {
      const messageFormatted = this.formatMessage(message, optionalParams);
      logger.emit({
        severityNumber: SeverityNumber.DEBUG,
        severityText: 'debug',
        body: messageFormatted,
        attributes: {
          'log.type': 'custom',
        },
      });
    }
  }

  static error(message?: any, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams);
    const logger = this.getLogger();
    if (logger) {
      const messageFormatted = this.formatMessage(message, optionalParams);
      logger.emit({
        severityNumber: SeverityNumber.ERROR,
        severityText: 'error',
        body: messageFormatted,
        attributes: {
          'log.type': 'custom',
        },
      });
    }
  }

  static info(message?: any, ...optionalParams: any[]): void {
    console.info(message, ...optionalParams);
    const logger = this.getLogger();
    if (logger) {
      const messageFormatted = this.formatMessage(message, optionalParams);
      logger.emit({
        severityNumber: SeverityNumber.INFO,
        severityText: 'info',
        body: messageFormatted,
        attributes: {
          'log.type': 'custom',
        },
      });
    }
  }

  static warn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams);
    const logger = this.getLogger();
    if (logger) {
      const messageFormatted = this.formatMessage(message, optionalParams);
      logger.emit({
        severityNumber: SeverityNumber.WARN,
        severityText: 'warning',
        body: messageFormatted,
        attributes: {
          'log.type': 'custom',
        },
      });
    }
  }
}
export default AppLogger;
