import { useNavigate } from 'react-router-dom';

function ReloadConfirm() {
  const navigate = useNavigate();
  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay pos-modal-overlay_type_transparent">
        <div className="pos-modal pos-modal_role_sign-out">
          <div className="pos-modal__content">
            <div className="pos-sign-out">
              <div className="pos-sign-out__header">
                <h2 className="pos-sign-out__title">Restarting Till</h2>
              </div>
              <div className="pos-sign-out__message">
                <p className="pos-sign-out__message-text">Are you sure?</p>
              </div>
              <div className="pos-sign-out__actions">
                <button
                  className="pos-sign-out__action pos-sign-out__action_role_change-fob"
                  type="button"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  No
                </button>
                <button
                  className="pos-sign-out__action pos-sign-out__action_role_confirm"
                  type="button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReloadConfirm;
