import { useEffect, useRef } from 'react';

import appLogger from '../../logger/AppLogger';

export function WakeLockController() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lock = useRef<any | undefined>();

  useEffect(() => {
    try {
      if ('wakeLock' in navigator) {
        if (lock.current === undefined) {
          navigator.wakeLock
            .request('screen')
            .then((lockResult) => {
              lock.current = lockResult;
              appLogger.info('We got a wake lock', lock.current);
            })
            .catch((err) => {
              appLogger.error(err);
            });
        }
      }

      return () => {
        if (lock.current !== undefined) lock.current.release();
      };
    } catch {
      appLogger.warn('wake lock API not supported or had an error');
    }
  }, []);

  return <></>;
}
