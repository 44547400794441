import { Outlet, useNavigate } from 'react-router-dom';

import '../style.sass';

function SessionOverview() {
  const navigate = useNavigate();

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default SessionOverview;
