import './environmentBanner.sass';
import { useShowEnvironmentBanner } from '../../../features/config';

function EnvironmentBanner() {
  const showEnvironmentBanner = useShowEnvironmentBanner();
  return (
    <>
      {showEnvironmentBanner && (
        <div className="pos-environment-banner">
          <h3>NON PROD</h3>
        </div>
      )}
    </>
  );
}

export default EnvironmentBanner;
