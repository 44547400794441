import { baseApi } from '../baseApi';

import { Workstation } from './Workstation';

const workstationApiSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Workstations'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWorkstations: builder.query<Workstation, void>({
        query: () => ({
          url: `/Workstations`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'Workstations', id: result.id }] : []),
      }),
    }),
  });

export const { useGetWorkstationsQuery, useLazyGetWorkstationsQuery } = workstationApiSlice;
