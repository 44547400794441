// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateQueryString = (query: any) => {
  if (query === undefined) return '';

  const keys = Object.keys(query);

  for (const param in keys) {
    const key = keys[param];

    if (query[key] === undefined /* In case of undefined assignment */ || query[key] === null || query[key] === '') delete query[key];
  }

  return new URLSearchParams(query).toString();
};
