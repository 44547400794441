import { baseApi } from '../baseApi';

import { VoidReason } from './VoidReason';

const voidReasonSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['VoidReasons'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVoidReasons: builder.query<VoidReason[], void>({
        keepUnusedDataFor: 60,
        query: () => ({
          url: '/VoidReasons',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'VoidReasons' as const,
                  id,
                })),
                { type: 'VoidReasons', id: 'LIST' },
              ]
            : [{ type: 'VoidReasons', id: 'LIST' }],
      }),
    }),
  });

export const { useGetVoidReasonsQuery, useLazyGetVoidReasonsQuery } = voidReasonSlice;
