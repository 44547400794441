import { baseApi } from '../baseApi';

import { Booking } from './Booking';

const bookingSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Bookings'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBookings: builder.query<Booking[], void>({
        query: () => ({
          url: '/bookings',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        keepUnusedDataFor: 0,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Bookings' as const,
                  id,
                })),
                { type: 'Bookings', id: 'LIST' },
              ]
            : [{ type: 'Bookings', id: 'LIST' }],
      }),
      getBookingById: builder.query<Booking, number>({
        query: (id: number) => ({
          url: `/bookings/${id}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'Bookings', id: result.id }] : []),
      }),
    }),
  });

export const { useGetBookingByIdQuery, useGetBookingsQuery, useLazyGetBookingByIdQuery, useLazyGetBookingsQuery } = bookingSlice;
