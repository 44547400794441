import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { MenuItemCategory } from '../../../api/menu';
import {
  setActiveCategory,
  setMenuPage,
  useActiveCategory,
  useActiveSubCategories,
  useMenuItemCategories,
  useMenuItems,
} from '../../orderSlice';

function SubCategoryPanel() {
  const dispatch = useAppDispatch();
  const activeSubCategories = useActiveSubCategories();
  const menuCategories = useMenuItemCategories();
  const activeCategory = useActiveCategory();
  const menuItems = useMenuItems();

  const [usableSubCategories, setUsableSubCategories] = useState<MenuItemCategory[]>([]);
  const [visibleCategories, setVisibleCategories] = useState<MenuItemCategory[]>([]);

  const [showIndex, setShowIndex] = useState(0);

  const hasCategoryGotMenuItems = useCallback(
    (category: MenuItemCategory) => {
      // We need to check if this category has menu items in, if so true, else has it got any child categories and they got items in
      const items = menuItems.filter((x) => x.menuItemCategoryIds.find((x) => x === category.id) !== undefined);
      if (items.length > 0) return true;

      const childCategories = menuCategories.filter((x) => x.parentCategoryId === category.id);
      const childItems = childCategories.map((x) => menuItems.filter((y) => y.menuItemCategoryIds.find((z) => z === x.id) !== undefined));
      return childItems.length > 0;
    },
    [menuCategories, menuItems],
  );

  useEffect(() => {
    setUsableSubCategories(activeSubCategories.filter(hasCategoryGotMenuItems));
  }, [activeSubCategories, hasCategoryGotMenuItems]);

  useEffect(() => {
    if (usableSubCategories.length > 0) {
      const categories = usableSubCategories.slice(showIndex, 7 + showIndex);
      console.debug(categories);
      setVisibleCategories(categories);
    } else setVisibleCategories([]);
  }, [usableSubCategories, hasCategoryGotMenuItems, showIndex]);

  const color = (index: number) => `category-color-${index + 2}`;
  const categoryClick = (category: MenuItemCategory) => {
    dispatch(setActiveCategory(category));
    dispatch(setMenuPage(1));
  };
  return (
    <div className="pos-menu__subcategories js-menu-subcategories">
      <button
        className="pos-menu__subcategories-control pos-menu__subcategories-control_role_previous js-menu-subcategories-previous"
        onClick={() => setShowIndex(showIndex - 7)}
        type="button"
        disabled={showIndex === 0}
      >
        Previous
      </button>

      <div className="pos-menu__subcategories-list">
        <div className="pos-menu__subcategories-scroll js-menu-subcategories-scroll">
          <div className="pos-menu__subcategories-row">
            {visibleCategories.map((x, i) => (
              <div
                key={x.id}
                className={`pos-menu__subcategory ${color(i)} ${activeCategory?.id === x.id ? 'is-active' : ''}`}
                onClick={() => categoryClick(x)}
              >
                <p className="pos-menu__subcategory-text">{x.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <button
        className="pos-menu__subcategories-control pos-menu__subcategories-control_role_next js-menu-subcategories-next"
        type="button"
        onClick={() => setShowIndex(showIndex + 7)}
        disabled={showIndex >= usableSubCategories.length - 7}
      >
        Next
      </button>
    </div>
  );
}

export default SubCategoryPanel;
