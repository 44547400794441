import { useWorkstation } from '../../appState';

import { SessionReviewFob } from './SessionReviewFob';
import { SignOnSearch } from './SignOnSearch';

export function SessionReviewSignOn() {
  const workstation = useWorkstation();
  if (workstation?.hasFobReader) return <SessionReviewFob />;
  return <SignOnSearch />;
}
