import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/redux/hooks';
import { selectOauthConfig } from '../../config';
import { selectAccessTokenExpiry, selectToken } from '../authSelectors';
import { login, logout } from '../authSlice';
import { refreshToken } from '../oauth';

function AuthVerify() {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const accessTokenExpiry = useAppSelector(selectAccessTokenExpiry);
  const oAuthConfig = useAppSelector(selectOauthConfig);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    if (accessTokenExpiry !== undefined && token) {
      const timeTillExpiry = Math.floor(accessTokenExpiry - new Date().getTime()) - 5000;
      timer = setTimeout(() => {
        if (oAuthConfig !== undefined) {
          refreshToken(oAuthConfig, token.refresh_token).then((newToken) => {
            if (newToken !== undefined) dispatch(login(newToken));
            else dispatch(logout());
          });
        }
      }, timeTillExpiry);
    }
    return () => {
      if (timer !== undefined) clearTimeout(timer);
    };
  }, [accessTokenExpiry, token, oAuthConfig, dispatch]);
  return <></>;
}

export default AuthVerify;
