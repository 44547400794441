import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { AuthState, AuthenticationToken } from './auth.interfaces';

const initialState: AuthState = {};

let storedState = localStorage.getItem('token');
const params = new URLSearchParams(window.location.search);
if (params.get('deleteAppToken') === 'true') {
  storedState = null;
  localStorage.removeItem('token');
  window.location.search = '';
}

if (storedState) {
  initialState.token = JSON.parse(storedState);
  if (initialState.token) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tokenInfo = jwtDecode<any>(initialState.token.access_token);
    if (tokenInfo) {
      initialState.user = {
        firstName: tokenInfo.given_name,
        lastName: tokenInfo.family_name,
        id: tokenInfo.sub,
        userName: tokenInfo.preferred_username,
      };
      initialState.accessTokenExpiry = (tokenInfo.exp - 30) * 1000;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refreshTokenInfo = jwtDecode<any>(initialState.token.refresh_token);
    if (refreshTokenInfo) initialState.refreshTokenExpiry = (refreshTokenInfo.exp - 30) * 1000;
    else initialState.refreshTokenExpiry = undefined;
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: Draft<AuthState>, action: PayloadAction<AuthenticationToken>) => {
      state.token = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tokenInfo = jwtDecode<any>(action.payload.access_token);
      if (tokenInfo) {
        state.user = {
          firstName: tokenInfo.given_name,
          lastName: tokenInfo.family_name,
          id: tokenInfo.sub,
          userName: tokenInfo.preferred_username,
        };
        state.accessTokenExpiry = (tokenInfo.exp - 30) * 1000;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const refreshTokenInfo = jwtDecode<any>(action.payload.refresh_token);
      if (refreshTokenInfo) state.refreshTokenExpiry = (refreshTokenInfo.exp - 30) * 1000;
      else state.refreshTokenExpiry = undefined;
    },
    logout: (state: Draft<AuthState>) => {
      state.token = undefined;
      state.user = undefined;
      state.accessTokenExpiry = undefined;
      state.refreshTokenExpiry = undefined;
    },
  },
});

export const { logout, login } = authSlice.actions;

export default authSlice.reducer;
