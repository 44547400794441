import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import logger from '../../common/logger/AppLogger';
import {
  Table,
  useGetStoredTransactionsQuery,
  useGetTablesQuery,
  useSaveToStoredTransactionMutation,
  useStoreTransactionMutation,
} from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState, useTransaction } from '../order/orderSlice';

import Covers from './Covers';
import TableList, { TransactionTable } from './TableList';
import { setSelectedTable, useSelectedTable } from './tableSlice';

function StoreTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: tableData } = useGetTablesQuery();
  const activeTable = useSelectedTable();
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const { data: storedTransactions } = useGetStoredTransactionsQuery();
  const [showCovers, setShowCovers] = useState(false);
  const [showSaveWarning, setShowSaveWarning] = useState(false);

  const [triggerStoreTable] = useStoreTransactionMutation();
  const [triggerSaveToStoredTable] = useSaveToStoredTransactionMutation();

  if (tableData === undefined || storedTransactions === undefined) return <LoadingModal />;

  const tableStoreClicked = () => {
    if (activeTable !== undefined) {
      if (activeTable.inUse === true) setShowSaveWarning(true);
      else setShowCovers(true);
    }
  };

  const saveToStoredTable = () => {
    if (operatorSession && transaction.length === 1 && activeTable) {
      triggerSaveToStoredTable({
        operatorId: operatorSession.operator.id,
        tableId: activeTable.id,
        transactionId: transaction[0].id,
      })
        .unwrap()
        .then(() => {
          dispatch(setSelectedTable(undefined));
          dispatch(clearOrderState());
          navigate('/order');
        })
        .catch((err) => {
          logger.warn(err);
          if (err.status && err.status === 412) {
            dispatch(
              showErrorDialog({
                message: err.data.detail,
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          }
        });
    }
  };

  const onCoversDone = (covers: number, table: Table) => {
    if (transaction.length === 1 && operatorSession) {
      triggerStoreTable({
        covers: covers,
        tableId: table.id,
        transactionId: transaction[0].id,
        transactionName: '',
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(setSelectedTable(undefined));
          dispatch(clearOrderState());
          navigate('/order');
        })
        .catch((err) => {
          logger.warn(err);
          if (err.status && err.status === 400) {
            dispatch(
              showErrorDialog({
                message: err.data.detail,
                dismissible: true,
                buttonText: 'OK',
              }),
            );
          }
        });
    }
  };

  return (
    <>
      <div className="pos-tables">
        <div className="pos-tables__header">
          <h2 className="pos-tables__title">Open Tables</h2>
        </div>
        <TableList
          tables={tableData.map<TransactionTable>((t) => ({
            table: t,
            transaction: [...storedTransactions, ...transaction].find((x) => x.tableId === t.id),
          }))}
        />
        <div className="pos-tables__actions">
          <button className="pos-tables__action pos-tables__action_role_transfer hidden-button" type="button"></button>
          <button
            className="pos-tables__action pos-tables__action_role_new"
            type="button"
            disabled={activeTable === undefined}
            onClick={tableStoreClicked}
          >
            Store Table
          </button>
        </div>
      </div>
      {showCovers && <Covers covers={1} onDone={onCoversDone} onCancel={() => setShowCovers(false)} />}
      {showSaveWarning && (
        <div className="pos-modal-portal">
          <div className="pos-modal-overlay">
            <div className="pos-popup pos-popup_role_info" style={{ width: '500px', height: '320px' }}>
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Please Confirm!</h3>
                  <p className="pos-popup__text">
                    You are trying to store the open transaction to a table that already has a stored transaction.
                    <br />
                    <br />
                    Please confirm you want to merge this transaction with the stored transaction.
                  </p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={saveToStoredTable}>
                    Confirm
                  </button>
                  <button className="pos-popup__action" onClick={() => setShowSaveWarning(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StoreTable;
