import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { baseApi } from '../../features/api/baseApi';

import { rootReducer, RootState } from './reducer';
import signalrMiddleware from './signalr-middleware';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([baseApi.middleware, signalrMiddleware]);
  },
});

store.subscribe(() => {
  const state = store.getState() as RootState;
  // Auth
  if (state.auth.token !== undefined) localStorage.setItem('token', JSON.stringify(state.auth.token));
  // else localStorage.removeItem('token');
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
