import { useState } from 'react';

import { useAppDispatch } from '../../app/redux/hooks';
import { Table, useGetTablesQuery } from '../api';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';

import { useSelectedTable } from './tableSlice';

function Covers({ covers, onDone, onCancel }: { covers: number; onDone: (covers: number, table: Table) => void; onCancel: () => void }) {
  const dispatch = useAppDispatch();
  const table = useSelectedTable();
  const { data: tables } = useGetTablesQuery();
  const [tableNum, setTableNum] = useState(table ? table.tableNumber.toString() : '');
  const [coversCount, setCoversCount] = useState(covers);
  if (tables === undefined) return <></>;

  const onDoneClicked = () => {
    const parsedTableNum = parseInt(tableNum);
    if (isNaN(parsedTableNum)) dispatch(showErrorDialog({ message: 'Table is invalid', dismissible: true }));
    else {
      if (table) {
        if (table.tableNumber !== parsedTableNum) {
          // Validate table
          const tableFound = tables.find((x) => x.tableNumber === parsedTableNum);
          if (tableFound === undefined) dispatch(showErrorDialog({ message: 'Table is invalid', dismissible: true }));
          else onDone(coversCount, tableFound);
        } else onDone(coversCount, table);
      }
    }
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay pos-modal-overlay_type_light"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className.indexOf('pos-modal-overlay') > -1) onCancel();
        }}
      >
        <div className="pos-modal pos-modal_role_new-table">
          <div className="pos-modal__content">
            <div className="pos-new-table">
              <div className="pos-new-table__header">
                <h2 className="pos-new-table__title">New Table</h2>
              </div>
              <div className="pos-new-table__controls">
                <div className="pos-new-table__number">
                  <p className="pos-new-table__number-label">Table No.</p>
                  <div className="pos-new-table__number-display">{tableNum}</div>
                </div>
                <div className="pos-new-table__numpad hidden-button">
                  <div className="pos-numpad pos-numpad_role_new-table">
                    <div className="pos-numpad__controls">
                      <div className="pos-numpad__controls-row">
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '1')}>
                          1
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '2')}>
                          2
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '3')}>
                          3
                        </button>
                      </div>
                      <div className="pos-numpad__controls-row">
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '4')}>
                          4
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '5')}>
                          5
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '6')}>
                          6
                        </button>
                      </div>
                      <div className="pos-numpad__controls-row">
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '7')}>
                          7
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '8')}>
                          8
                        </button>
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '9')}>
                          9
                        </button>
                      </div>
                      <div className="pos-numpad__controls-row">
                        <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '0')}>
                          0
                        </button>
                        <button
                          className="pos-numpad__control pos-numpad__control_role_clear"
                          type="button"
                          onClick={() => setTableNum('')}
                        >
                          CLR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-new-table__guests">
                  <p className="pos-new-table__guests-label">Guests</p>
                  <div className="pos-new-table__guests-number">
                    <div className="pos-quantity pos-quantity_role_new-table">
                      <button
                        className="pos-quantity__control"
                        type="button"
                        onClick={() => setCoversCount(coversCount === 1 ? coversCount : coversCount - 1)}
                        disabled={coversCount === 1}
                      >
                        -
                      </button>
                      <div className="pos-quantity__value">{coversCount}</div>
                      <button className="pos-quantity__control" type="button" onClick={() => setCoversCount(coversCount + 1)}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-new-table__actions">
                <button className="pos-new-table__action" type="button" onClick={onDoneClicked}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Covers;
