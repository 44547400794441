import Moment from 'react-moment';

import Price from '../../common/components/price/Price';
import { useSite } from '../appState';

import { useCardStatus } from './signOnSlice';
import './cardPaymentTerminalStatus.sass';

export default function CardPaymentTerminalStatus() {
  const site = useSite();
  const statusItems = useCardStatus();

  if (site == undefined) return <></>;
  if (site.eft === false) return <></>;
  if (statusItems.length === 0) return <></>;

  return (
    <div className="pos-card-payment-status">
      <h3>Card Machine Status</h3>
      {statusItems.map((m) => (
        <div className="pos-terminal-container" key={m.terminalId}>
          <p className={`pos-terminal-${m.success ? 'success' : 'failure'}`}>
            <b>{m.operatorName}</b> on {m.terminalName} for TX-{m.transactionNumber}
            <br />
            <Price price={m.lastAmount} /> @<Moment date={m.dateTimeAdded} format="HH:mm" />{' '}
            {m.success ? 'Payment Success' : 'Payment Failed'}
          </p>
        </div>
      ))}
    </div>
  );
}
