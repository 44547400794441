import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import { useLinkCustomerLoyaltyCodeMutation } from '../../api';
import { useOperatorSession } from '../../appState';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import { useTransaction } from '../orderSlice';

interface LoyaltyWarning {
  warningType:
    | 'not-verified'
    | 'not-active'
    | 'invalid-code'
    | 'expired-code'
    | 'not-loyalty'
    | 'customer-not-found'
    | 'code-redeemed'
    | 'failed-loyalty';
  transactionId: number;
}

export default function LoyaltyScanInline() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const transaction = useTransaction();
  const operatorSession = useOperatorSession();

  const [loyaltyWarning, setLoyaltyWarning] = useState<LoyaltyWarning | null>(null);
  const loyaltyKeyboardState = useRef<string>('');
  const [triggerLinkLoyalty] = useLinkCustomerLoyaltyCodeMutation();

  useEffect(() => {
    if (pathname === '/order') {
      const maxLoyaltyLength = 40;
      const minLoyaltylength = 32;
      const eventHandler = (e: KeyboardEvent) => {
        if (transaction && transaction.length === 1 && operatorSession !== undefined) {
          if (transaction[0].transactionLineItems.length === 0) return;

          if (e.key === 'Enter') {
            console.warn(`Length ${loyaltyKeyboardState.current.length} vax ${loyaltyKeyboardState.current.length >= minLoyaltylength}`);
            if (loyaltyKeyboardState.current.length >= minLoyaltylength) {
              triggerLinkLoyalty({
                transactionId: transaction[0].id,
                loyaltyCode: loyaltyKeyboardState.current,
                operatorId: operatorSession.operator.id,
              })
                .unwrap()
                .then(() => {
                  console.debug('loyalty code linked OK');
                  if (loyaltyWarning) setLoyaltyWarning(null);
                })
                .catch((err) => {
                  if (err && err.status) {
                    if (err.status === 412) {
                      console.warn('linking loyalty failed', err);
                      const typeErr: string = err.data.type;
                      const errorParts = typeErr.split('#');
                      if (errorParts.length === 2) {
                        const warning: LoyaltyWarning = {
                          transactionId: transaction[0].id,
                          warningType: 'not-loyalty',
                        };
                        const code = errorParts[1];
                        switch (code) {
                          case 'IS_VOUCHER_POOL':
                            warning.warningType = 'invalid-code';
                            break;
                          case 'CUSTOMER_NOT_FOUND':
                            warning.warningType = 'customer-not-found';
                            break;
                          case 'VOUCHER_EXPIRED':
                            warning.warningType = 'expired-code';
                            break;
                          case 'NOT_LOYALTY_SCANNED':
                            warning.warningType = 'not-loyalty';
                            break;
                          case 'VOUCHER_NOT_FOUND':
                            warning.warningType = 'invalid-code';
                            break;
                          case 'VOUCHER_REDEEMED':
                            warning.warningType = 'code-redeemed';
                            break;
                          case 'VOUCHER_NOT_VERIFIED':
                            warning.warningType = 'not-verified';
                            break;
                          case 'POOL_REQUIRES_VERIFICATION':
                            warning.warningType = 'invalid-code';
                            break;
                          case 'VOUCHER_REDEEM_FAILED':
                            warning.warningType = 'failed-loyalty';
                        }
                        setLoyaltyWarning(warning);
                      }
                    } else {
                      dispatch(
                        showErrorDialog({
                          dismissible: true,
                          message: 'An error occurred linking the loyalty code',
                        }),
                      );
                    }
                  }
                });
              console.debug('resetting loyalty input');
            }
            loyaltyKeyboardState.current = '';
          } else {
            if (e.key.length === 1) {
              if (loyaltyKeyboardState.current.length >= maxLoyaltyLength)
                loyaltyKeyboardState.current = loyaltyKeyboardState.current.substring(1) + e.key;
              else loyaltyKeyboardState.current = loyaltyKeyboardState.current + e.key;
            }
          }
        }
      };

      window.addEventListener('keydown', eventHandler);

      return () => window.removeEventListener('keydown', eventHandler);
    }
  }, [dispatch, loyaltyWarning, operatorSession, pathname, transaction, triggerLinkLoyalty]);

  useEffect(() => {
    if (loyaltyWarning !== null) {
      if (transaction !== undefined && transaction.length > 0) {
        if (loyaltyWarning.transactionId != transaction[0].id) setLoyaltyWarning(null);
      } else setLoyaltyWarning(null);
    }
  }, [loyaltyWarning, transaction]);

  return (
    <>
      {loyaltyWarning !== null && (
        <div className="pos-warning-loyalty">
          <h3>
            LOYALTY FAILED DUE TO{' '}
            <span>
              {loyaltyWarning.warningType == 'not-verified' && 'NOT VERIFIED'}
              {loyaltyWarning.warningType == 'not-active' && 'CUSTOMER DISABLED'}
              {loyaltyWarning.warningType == 'expired-code' && 'CODE EXPIRED'}
              {loyaltyWarning.warningType == 'invalid-code' && 'CODE INVALID'}
              {loyaltyWarning.warningType == 'not-loyalty' && 'INVALID QR CODE'}
              {loyaltyWarning.warningType == 'customer-not-found' && 'CUSTOMER NOT FOUND'}
              {loyaltyWarning.warningType == 'code-redeemed' && 'VOUCHER ALREADY REDEEMED'}
              {loyaltyWarning.warningType == 'failed-loyalty' && 'FAILURE WITH CRM'}
            </span>
          </h3>
        </div>
      )}
    </>
  );
}
