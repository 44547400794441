import { PayloadAction, createSlice, Draft } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import { Table } from '../api';

export interface TableState {
  selectedTable?: Table | undefined;
  showSidebar: boolean;
}

const initialState: TableState = {
  showSidebar: false,
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setShowSidebar: (state: Draft<TableState>, { payload }: PayloadAction<boolean>) => {
      state.showSidebar = payload;
    },
    setSelectedTable: (state: Draft<TableState>, { payload }: PayloadAction<Table | undefined>) => {
      state.selectedTable = payload;
    },
  },
});

export const { setSelectedTable, setShowSidebar } = tableSlice.actions;
export default tableSlice.reducer;
const selectSelectedTable = (state: RootState) => state.table.selectedTable;
const selectShowSideBar = (state: RootState) => state.table.showSidebar;

export const useSelectedTable = () => useAppSelector(selectSelectedTable);
export const useShowSidebar = () => useAppSelector(selectShowSideBar);
