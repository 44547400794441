import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import LoadingModal from '../../common/components/loading-modal/LoadingModal';
import logger from '../../common/logger/AppLogger';
import {
  Table,
  useClearTransactionTableMutation,
  useCompleteTransactionMutation,
  useGetStoredTransactionsQuery,
  useGetTablesQuery,
  useLazyCheckTableFreeQuery,
  useOpenTransactionMutation,
  useSetTransactionTableMutation,
} from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { setTransaction, useTransaction } from '../order/orderSlice';

import Covers from './Covers';
import TableList, { TransactionTable } from './TableList';
import { setSelectedTable, useSelectedTable } from './tableSlice';

function OpenTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: tableData } = useGetTablesQuery();
  const { data: storedTransactions } = useGetStoredTransactionsQuery();
  const [triggerSetTable] = useSetTransactionTableMutation();
  const operatorSession = useOperatorSession();
  const activeTable = useSelectedTable();
  const transaction = useTransaction();
  const [showCovers, setShowCovers] = useState(false);

  const transactionIsClean =
    transaction.length === 1
      ? transaction[0].transactionLineItems.length === 0 &&
        transaction[0].tableId == undefined &&
        transaction[0].bookingId == undefined &&
        transaction[0].totalDue === 0 &&
        transaction[0].transactionPayments.length === 0
      : false;

  const [triggerCompleteTransaction] = useCompleteTransactionMutation();
  const [triggerOpenTransaction] = useOpenTransactionMutation();
  const [triggerClearTable] = useClearTransactionTableMutation();
  const [triggerCheckTableFree] = useLazyCheckTableFreeQuery();

  if (tableData === undefined || storedTransactions === undefined) return <LoadingModal />;
  const openClicked = () => {
    if (activeTable !== undefined && operatorSession !== undefined) {
      const openingTransaction = [...storedTransactions, ...transaction].find((x) => x.tableId === activeTable.id);
      if (openingTransaction !== undefined) {
        triggerCheckTableFree(activeTable.id)
          .unwrap()
          .then(({ free }) => {
            if (free === false) {
              triggerCompleteTransaction({
                operatorId: operatorSession.operator.id,
                transactionId: transaction.length === 1 ? transaction[0].id : 0,
              })
                .unwrap()
                .then((completeResponse) => {
                  if (completeResponse) logger.info('Completed the current tender');
                  triggerOpenTransaction({
                    operatorId: operatorSession.operator.id,
                    transactionId: openingTransaction.id,
                  })
                    .unwrap()
                    .then((openResult) => {
                      dispatch(setTransaction([openResult]));
                      dispatch(setSelectedTable(undefined));
                      navigate('/order');
                    });
                })
                .catch((err) => dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true })));
            }
          });
      }
    }
  };

  const closeClicked = () => {
    // do nothing
    if (activeTable !== undefined && operatorSession !== undefined) {
      const toCloseTransaction = [...storedTransactions, ...transaction].find((x) => x.tableId === activeTable.id);
      if (toCloseTransaction !== undefined) {
        triggerClearTable({
          operatorId: operatorSession.operator.id,
          transactionId: toCloseTransaction.id,
        })
          .unwrap()
          .then(() => {
            dispatch(setSelectedTable(undefined));
            navigate('/order');
          });
      }
    }
  };

  const newClicked = () => {
    if (activeTable !== undefined && operatorSession !== undefined && transaction.length === 1) setShowCovers(true);
  };
  const onCoversDone = (covers: number, table: Table) => {
    if (operatorSession !== undefined && transaction.length === 1) {
      triggerSetTable({
        operatorId: operatorSession.operator.id,
        transactionId: transaction[0].id,
        tableId: table.id,
        covers: covers,
      })
        .unwrap()
        .then(() => {
          dispatch(setSelectedTable(undefined));
          navigate('/order');
        })
        .catch((err) => {
          if (err.status === 400) dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
          else dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true }));
        });
    }
  };
  return (
    <>
      <div className="pos-tables">
        <div className="pos-tables__header">
          {transactionIsClean === true && <h2 className="pos-tables__title">Open Tables</h2>}
          {transactionIsClean === false && <h2 className="pos-tables__title">View Tables</h2>}
        </div>
        <TableList
          tables={tableData.map<TransactionTable>((t) => ({
            table: t,
            transaction: [...storedTransactions, ...transaction].find((x) => x.tableId === t.id),
          }))}
        />

        <div className="pos-tables__actions">
          <button
            className="pos-tables__action pos-tables__action_role_transfer"
            type="button"
            disabled={activeTable === undefined || transaction[0].tableId !== activeTable.id}
            onClick={() => navigate(`/tables/transfer/${activeTable?.id}`)}
          >
            Transfer Table
          </button>
          <button
            className="pos-tables__action pos-tables__action_role_new"
            type="button"
            disabled={
              activeTable === undefined || (activeTable.inUse === false && transactionIsClean === false) || activeTable.inUse === true
            }
            onClick={newClicked}
          >
            New Table
          </button>
          <button
            className="pos-tables__action pos-tables__action_role_open"
            type="button"
            disabled={activeTable === undefined || activeTable.inUse === false || transactionIsClean === false}
            onClick={openClicked}
          >
            Open Table
          </button>
          <button
            className="pos-tables__action pos-tables__action_role_close"
            type="button"
            disabled={activeTable === undefined || transaction[0].tableId !== activeTable.id}
            onClick={closeClicked}
          >
            Close Table
          </button>
        </div>
      </div>

      {showCovers && <Covers covers={1} onDone={onCoversDone} onCancel={() => setShowCovers(false)} />}
    </>
  );
}

export default OpenTable;
