export enum TenderMediaType {
  Payment = 1,
  Discount = 2,
  Deposit = 3,
}

export enum PaymentMethodType {
  Cash = 1,
  Card = 2,
  Other = 3,
  Deposit = 4,
}

export interface TenderMedia {
  id: number;
  tenderMediaType: TenderMediaType;
  name: string;
  reasonRequired: boolean;
  allowOverTender: boolean;
  reportGroupId: number;
  reportGroupName: string;
  paymentMethodType: PaymentMethodType;
}
