import { baseApi } from '../baseApi';

import { Table } from './Table';

const tableSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Tables'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTables: builder.query<Table[], void>({
        query: () => ({
          url: `/Tables`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Tables' as const,
                  id,
                })),
                { type: 'Tables', id: 'LIST' },
              ]
            : [{ type: 'Tables', id: 'LIST' }],
        keepUnusedDataFor: 0,
      }),
    }),
  });
export const { useGetTablesQuery, useLazyGetTablesQuery } = tableSlice;
