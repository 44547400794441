import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { LayoutDefinition, LayoutLinkType, LayoutType } from '../../../api';
import { setActiveLayoutDefinition, setMenuPage, useActiveLayoutDefinition, useLayoutDefinitions } from '../../orderSlice';

import { MenuCategoryButton } from './MenuCategoryButton';
import { MenuItemName } from './MenuItemName';
import { PackageName } from './PackageName';

function CategoryPanel() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const layoutDefinitions = useLayoutDefinitions();
  const activeLayoutDefinition = useActiveLayoutDefinition();
  const CategoryButton = ({ position }: { position: number }) => {
    const layoutDefinition = layoutDefinitions.find(
      (x) => x.layoutType === LayoutType.Categories && x.pageNumber === 1 && x.positionNumber === position,
    );
    const categoryClick = (layoutDefinition: LayoutDefinition) => {
      dispatch(setActiveLayoutDefinition(layoutDefinition));
      dispatch(setMenuPage(1));
    };
    if (layoutDefinition !== undefined) {
      const isActive =
        (activeLayoutDefinition && activeLayoutDefinition.id === layoutDefinition.id) ||
        (activeLayoutDefinition &&
          activeLayoutDefinition.layoutLinkType === LayoutLinkType.FastBar &&
          layoutDefinition.layoutLinkType === LayoutLinkType.FastBar) ||
        (activeLayoutDefinition &&
          activeLayoutDefinition.layoutLinkType === LayoutLinkType.Packages &&
          layoutDefinition.layoutLinkType === LayoutLinkType.Packages)
          ? ' is-active'
          : '';

      if (layoutDefinition.layoutLinkType === LayoutLinkType.FastBar) {
        return (
          <div className={`pos-menu__category pos-menu__category_role_home${isActive}`} onClick={() => categoryClick(layoutDefinition)}>
            <div className="pos-menu__category-icon pos-menu__category-icon_role_home">Home</div>
          </div>
        );
      }
      if (layoutDefinition.layoutLinkType === LayoutLinkType.Packages) {
        return (
          <div className={`pos-menu__category${isActive}`} onClick={() => categoryClick(layoutDefinition)}>
            <p className="pos-menu__category-text">Packages</p>
          </div>
        );
      }
      if (layoutDefinition.layoutLinkType == LayoutLinkType.Category) {
        return (
          <MenuCategoryButton layoutDefinition={layoutDefinition} onClick={() => categoryClick(layoutDefinition)} isActive={isActive} />
        );
      }

      if (layoutDefinition.layoutLinkType === LayoutLinkType.Package) {
        return (
          <div className={`pos-menu__category${isActive}`} onClick={() => navigate(`/order/package/${layoutDefinition.packageId}`)}>
            <p className="pos-menu__category-text">
              {layoutDefinition.layoutLinkType === LayoutLinkType.Package && <PackageName id={layoutDefinition.packageId} />}
            </p>
          </div>
        );
      }

      return (
        <div className={`pos-menu__category${isActive}`} onClick={() => categoryClick(layoutDefinition)}>
          <p className="pos-menu__category-text">
            {layoutDefinition.layoutLinkType === LayoutLinkType.MenuItem && <MenuItemName id={layoutDefinition.menuItemId} />}
          </p>
        </div>
      );
    } else return <div className="pos-menu__category hidden-button">Hidden</div>;
  };

  useEffect(() => {
    if (activeLayoutDefinition === undefined) {
      const active = layoutDefinitions.find((x) => x.layoutLinkType === LayoutLinkType.FastBar);
      dispatch(setActiveLayoutDefinition(active));
      dispatch(setMenuPage(1));
    }
  }, [layoutDefinitions, activeLayoutDefinition, dispatch]);

  return (
    <div className="pos-menu__categories">
      <div className="pos-menu__categories-row">
        <CategoryButton position={1} />
        <CategoryButton position={2} />
        <CategoryButton position={3} />
        <CategoryButton position={4} />
        <CategoryButton position={5} />
        <CategoryButton position={6} />
        <CategoryButton position={7} />
        <CategoryButton position={8} />
      </div>

      <div className="pos-menu__categories-row">
        <CategoryButton position={9} />
        <CategoryButton position={10} />
        <CategoryButton position={11} />
        <CategoryButton position={12} />
        <CategoryButton position={13} />
        <CategoryButton position={14} />
        <CategoryButton position={15} />
        <CategoryButton position={16} />
      </div>
      <div className="pos-menu__categories-row">
        <CategoryButton position={17} />
        <CategoryButton position={18} />
        <CategoryButton position={19} />
        <CategoryButton position={20} />
        <CategoryButton position={21} />
        <CategoryButton position={22} />
        <CategoryButton position={23} />
        <CategoryButton position={24} />
      </div>
    </div>
  );
}
export default CategoryPanel;
