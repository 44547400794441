import { Link } from 'react-router-dom';

type NotFoundProps = {
  homePath: string;
};

function NotFound({ homePath }: NotFoundProps) {
  return (
    <div className="x-pos-error-body">
      <div className="x-pos-error-container">
        <div className="x-pos-error-box">
          <h1>Page Not Found</h1>
          <Link to={homePath}>Home</Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
