import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import { Operator, OperatorSession, useForceOperatorOffMutation } from '../../api';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';

export function ServerOverviewSignOut() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { operator, manager } = location.state as { operator: OperatorSession; manager: Operator };

  const [triggerForceSignOut] = useForceOperatorOffMutation();

  const forceSignOut = () => {
    if (operator) {
      triggerForceSignOut({
        managerOperatorId: manager.id,
        operatorId: operator?.operator.id,
      })
        .unwrap()
        .then(() => {
          navigate('/');
        })
        .catch((err) => {
          if (err.status === 404) {
            dispatch(
              showErrorDialog({
                message: 'Manager Error',
                dismissible: true,
                buttonText: 'Close',
              }),
            );
          }
          if (err.status === 403) {
            dispatch(
              showErrorDialog({
                message: 'You cannot close this operator',
                dismissible: true,
                buttonText: 'Close',
              }),
            );
          }
          if (err.status === 412) {
            if (err.data.type === 'OperatorNotSignedOn') {
              dispatch(
                showErrorDialog({
                  message: 'Cannot close operator',
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
            } else if (err.data.type === 'OperatorHasOpenTransactions') {
              dispatch(
                showErrorDialog({
                  message: err.data.title,
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
            } else {
              dispatch(
                showErrorDialog({
                  message: 'Something went wrong with validation',
                  dismissible: true,
                  buttonText: 'Close',
                }),
              );
            }
          }
        });
    }
  };

  return (
    <div className="pos-modal__popup">
      <div className="pos-popup pos-popup_role_confirmation">
        <div className="pos-popup__content">
          <div className="pos-popup__info">
            <h3 className="pos-popup__title">Are You Sure?</h3>
            <p className="pos-popup__label">Sign Out</p>
            <p className="pos-popup__text">
              {operator?.operator.firstName} {operator?.operator.lastName}
            </p>
          </div>
          <div className="pos-popup__actions">
            <button className="pos-popup__action pos-popup__action_role_no" type="button" onClick={() => navigate('../')}>
              No
            </button>
            <button className="pos-popup__action pos-popup__action_role_yes" type="button" onClick={forceSignOut}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
