import { useAppDispatch } from '../../../../app/redux/hooks';
import { setMessage, useMessagePresets } from '../../orderSlice';

function MessagePresetSelect() {
  const dispatch = useAppDispatch();
  const messagePresets = useMessagePresets();

  const MessagePresetButton = ({ position }: { position: number }) => {
    const index = position - 1;
    const messagePreset = messagePresets.length <= position ? messagePresets[index] : undefined;
    if (messagePreset !== undefined) {
      return (
        <div className="pos-message__preset" onClick={() => dispatch(setMessage(messagePreset.message))}>
          <p className="pos-message__preset-text">{messagePreset.message}</p>
        </div>
      );
    }
    return <div className="pos-message__preset hidden-button"></div>;
  };

  return (
    <div className="pos-message__presets">
      <div className="pos-message__presets-row">
        <MessagePresetButton position={1} />
        <MessagePresetButton position={2} />
        <MessagePresetButton position={3} />
      </div>
      <div className="pos-message__presets-row">
        <MessagePresetButton position={4} />
        <MessagePresetButton position={5} />
        <MessagePresetButton position={6} />
      </div>
      <div className="pos-message__presets-row">
        <MessagePresetButton position={7} />
        <MessagePresetButton position={8} />
        <MessagePresetButton position={9} />
      </div>
      <div className="pos-message__presets-row">
        <MessagePresetButton position={10} />
        <MessagePresetButton position={11} />
        <MessagePresetButton position={12} />
      </div>
    </div>
  );
}

export default MessagePresetSelect;
