import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import Price from '../../../../common/components/price/Price';
import { useAddTransactionLineItemMessageMutation } from '../../../api';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { setActiveTransactionLineItem, setMessage, useMessage, useTransaction } from '../../orderSlice';

function Message() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const message = useMessage();
  const transaction = useTransaction();
  const params = useParams();
  const [triggerAddLineItemMessage] = useAddTransactionLineItemMessageMutation();
  let lineItemId = -1;
  if (params && params.lineItemId) lineItemId = parseInt(params.lineItemId);

  const activeLineItem = transaction[0].transactionLineItems.find((x) => x.id == lineItemId);

  if (activeLineItem == undefined) return <Navigate to="/order" />;

  const addMessage = () => {
    if (message.length > 0 && transaction) {
      triggerAddLineItemMessage({
        message: message,
        transactionLineItemId: activeLineItem.id,
      })
        .unwrap()
        .catch((err) => dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true })));
    }
    dispatch(setMessage(''));
    navigate('/order');
  };
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') {
            dispatch(setActiveTransactionLineItem(undefined));
            navigate('/order');
          }
        }}
      >
        <div className="pos-modal pos-modal_role_message">
          <div className="pos-modal__content">
            <div className="pos-message">
              <div className="pos-message__header">
                <h2 className="pos-message__title">Message</h2>
              </div>
              <div className="pos-message__info">
                <div className="pos-message__box js-message-box">
                  <div className="pos-message__box-scroll js-message-box-scroll">
                    <div className="pos-message__box-scroll-content">
                      <p className="pos-message__text">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="pos-message__box">
                  <div className="pos-invoice pos-invoice_role_message-item js-invoice">
                    <div className="pos-invoice__list">
                      <div className="pos-invoice__list-labels">
                        <p className="pos-invoice__list-label">Product</p>
                        <p className="pos-invoice__list-label">Qty</p>
                      </div>
                      <div className="pos-invoice__list-scroll js-invoice-scroll">
                        <div className="pos-invoice__items">
                          <div className="pos-invoice__item">
                            {activeLineItem && (
                              <>
                                <div className="pos-invoice__item-info">
                                  <p className="pos-invoice__item-text">{activeLineItem.description}</p>
                                  <div className="pos-invoice__item-meta">
                                    {activeLineItem.childTransactionLineItems.map((c) => (
                                      <div key={c.id} className="pos-invoice__item-meta-group">
                                        <p className="pos-invoice__item-meta-text">
                                          {c.description} <Price price={c.itemPrice} />
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="pos-invoice__item-qty">
                                  <p className="pos-invoice__item-text">{activeLineItem.quantity}</p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-message__controls">
                <div className="pos-message__switch">
                  <div className="pos-switch pos-switch_role_message">
                    <div className="pos-switch__controls">
                      <button
                        className={`pos-switch__control${location.pathname.indexOf('free-text') === -1 ? ' is-active' : ''}`}
                        type="button"
                        onClick={() => {
                          dispatch(setMessage(''));
                          navigate(`/order/message/${activeLineItem?.id}`);
                        }}
                      >
                        Presets
                      </button>
                      <button
                        className={`pos-switch__control${location.pathname.indexOf('free-text') > -1 ? ' is-active' : ''}`}
                        type="button"
                        onClick={() => {
                          dispatch(setMessage(''));
                          navigate(`/order/message/${activeLineItem?.id}/free-text`);
                        }}
                      >
                        Keyboard
                      </button>
                    </div>
                  </div>
                </div>
                <Outlet />
              </div>
              <div className="pos-message__actions">
                <button className="pos-message__action" type="button" onClick={addMessage}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
