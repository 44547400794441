import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import { useLazyOperatorCheckFobUseQuery, useOperatorAssignTokenMutation, useOperatorStartShiftMutation } from '../api';
import { setOperatorSession, useWorkstation } from '../appState';
import { clearErrorDialog, showErrorDialog } from '../error-dialog/errorDialogSlice';

import { useRemoteSignOn, useSignOnOperator, useSignOnPin } from './signOnSlice';

function SignOnFob() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const workstation = useWorkstation();
  const operator = useSignOnOperator();
  const pin = useSignOnPin();
  const remoteSignOn = useRemoteSignOn();

  const keyboardState = useRef('');
  const [fob, setFob] = useState('');

  const [validateFobTrigger] = useLazyOperatorCheckFobUseQuery();
  const [startOperator] = useOperatorStartShiftMutation();
  const [assignFob] = useOperatorAssignTokenMutation();

  useEffect(() => {
    if (operator === undefined) navigate('../search');
    if (pin === undefined) navigate('../pin');
  }, [operator, pin, navigate]);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOn = /^0100([a-z|A-Z|0-9]{12})$/gm;
        const regexSignOff = /^0{3,4}(00)$/gm;
        const regexSignOnMatch = keyboardState.current.match(regexSignOn);
        if (regexSignOnMatch !== null) setFob(regexSignOnMatch[0]);
        const regexSignOffMatch = keyboardState.current.match(regexSignOff);
        if (regexSignOffMatch !== null) {
          setFob('');
          dispatch(clearErrorDialog());
        }
        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };

    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [dispatch]);

  const onDoneClick = () => {
    validateFobTrigger(fob)
      .unwrap()
      .then((fobInUse) => {
        if (fobInUse === true) {
          dispatch(
            showErrorDialog({
              message: 'This fob is already assigned to another operator',
              dismissible: false,
            }),
          );
        } else if (operator) {
          startOperator({
            pinCode: pin ? pin : '',
            operatorId: operator.id,
            remote: remoteSignOn,
          })
            .unwrap()
            .then((operatorSession) => {
              const operator = operatorSession.operator;
              assignFob({
                operatorId: operator.id,
                payload: {
                  token: fob,
                },
              })
                .unwrap()
                .then((operatorSession) => {
                  dispatch(setOperatorSession(operatorSession));
                  if (workstation?.hasSession) navigate('/order');
                  else navigate('/start-session');
                })
                .catch(() => dispatch(showErrorDialog({ message: 'An error occurred assigning fob', dismissible: true })));
            })
            .catch((err) => {
              if (err.status && err.status === 412) {
                if (err.data.type === 'OperatorNotClockedIn')
                  dispatch(showErrorDialog({ message: 'You are not clocked in', dismissible: true }));
                else if (err.data.type === 'OperatorSessionOnAnotherWorkstation')
                  dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
                else if (err.data.type === 'OperatorSessionActive')
                  dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
                else if (err.data.type === 'RemoteSignInForbid') dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
              } else dispatch(showErrorDialog({ message: 'An error occurred starting session', dismissible: true }));
            });
        }
      });
  };
  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Signing In</h2>
        </div>
        <div className="pos-sign-in__info">
          <p className="pos-sign-in__name">
            {operator?.firstName} {operator?.lastName}
          </p>
          <p className="pos-sign-in__label">Please place your FOB on the FOB reader:</p>
        </div>
        <div className="pos-sign-in__controls">
          <div className="pos-sign-in__fob">
            <div className="pos-field pos-field_role_fob">
              <div className="pos-field__group">
                <div className="pos-field__box">{fob}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-sign-in__actions">
          <button className="pos-sign-in__action" type="button" onClick={onDoneClick} disabled={fob.length === 0}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
export default SignOnFob;
