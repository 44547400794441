import { baseApi } from '../baseApi';

import { Customer } from './Customer';
import { LoyaltyTransactionDetails } from './LoyaltyTransactionDetails';

const loyaltySlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Customer'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCustomer: builder.query<Customer, string>({
        keepUnusedDataFor: 60,
        query: (customerId: string) => ({
          url: `/loyalty/${customerId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'Customer', id: result.id }] : []),
      }),
      loyaltyDetails: builder.query<LoyaltyTransactionDetails, number>({
        keepUnusedDataFor: 0,
        query: (transactionId: number) => ({
          url: `/loyalty/details/${transactionId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
    }),
  });

export const { useLazyGetCustomerQuery, useGetCustomerQuery, useLoyaltyDetailsQuery, useLazyLoyaltyDetailsQuery } = loyaltySlice;
