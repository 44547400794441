import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Result } from 'react-zxing';

import { useAppDispatch } from '../../../../app/redux/hooks';
import QrScanner from '../../../../common/components/qr-scanner/QrScanner';
import { QrCodeReaderType, useLinkCustomerLoyaltyCodeMutation } from '../../../api';
import { useOperatorSession, useWorkstation } from '../../../appState';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { useTransaction } from '../../orderSlice';

import './loyalty.sass';

const KeyboardScanner = ({ onResult }: { onResult: (data: string) => void }) => {
  const keyboardState = useRef('');
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onResult(keyboardState.current);
        keyboardState.current = '';
      } else if (e.key.length === 1) keyboardState.current = keyboardState.current + e.key;
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [onResult]);

  return <div className="pos-servers-overview__icon pos-servers-overview__icon_role_scan"></div>;
};

interface LoyaltyWarning {
  warningType:
    | 'not-verified'
    | 'not-active'
    | 'invalid-code'
    | 'expired-code'
    | 'not-loyalty'
    | 'customer-not-found'
    | 'code-redeemed'
    | 'failed-loyalty';
  transactionId: number;
}

function Loyalty() {
  const workstation = useWorkstation();
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [triggerLinkLoyalty, { isLoading }] = useLinkCustomerLoyaltyCodeMutation();
  const [loyaltyCode, setLoyaltyCode] = useState('');
  const [loyaltyWarning, setLoyaltyWarning] = useState<LoyaltyWarning | null>(null);

  const [hasScanned, setHasScanned] = useState(false);

  const keyboardResult = (data: string) => {
    if (data.length >= 32) {
      setHasScanned(true);
      setLoyaltyCode(data);
    }
  };

  const scannerResult = (data: Result) => {
    const text = data.getText();
    if (text.length >= 32) {
      setHasScanned(true);
      setLoyaltyCode(text);
    }
  };

  useEffect(() => {
    if (loyaltyCode.length >= 32 && transaction && transaction.length === 1 && operatorSession) {
      triggerLinkLoyalty({
        transactionId: transaction[0].id,
        loyaltyCode: loyaltyCode,
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          navigate('../');
        })
        .catch((err) => {
          if (err && err.status) {
            if (err.status === 412) {
              console.warn('linking loyalty failed', err);
              const typeErr: string = err.data.type;
              const errorParts = typeErr.split('#');
              if (errorParts.length === 2) {
                const warning: LoyaltyWarning = {
                  transactionId: transaction[0].id,
                  warningType: 'not-loyalty',
                };
                const code = errorParts[1];
                switch (code) {
                  case 'IS_VOUCHER_POOL':
                    warning.warningType = 'invalid-code';
                    break;
                  case 'CUSTOMER_NOT_FOUND':
                    warning.warningType = 'customer-not-found';
                    break;
                  case 'VOUCHER_EXPIRED':
                    warning.warningType = 'expired-code';
                    break;
                  case 'NOT_LOYALTY_SCANNED':
                    warning.warningType = 'not-loyalty';
                    break;
                  case 'VOUCHER_NOT_FOUND':
                    warning.warningType = 'invalid-code';
                    break;
                  case 'VOUCHER_REDEEMED':
                    warning.warningType = 'code-redeemed';
                    break;
                  case 'VOUCHER_NOT_VERIFIED':
                    warning.warningType = 'not-verified';
                    break;
                  case 'POOL_REQUIRES_VERIFICATION':
                    warning.warningType = 'invalid-code';
                    break;
                  case 'VOUCHER_REDEEM_FAILED':
                    warning.warningType = 'failed-loyalty';
                }
                setLoyaltyWarning(warning);
              }
            } else {
              dispatch(
                showErrorDialog({
                  dismissible: true,
                  message: 'An error occurred linking the loyalty code',
                }),
              );
            }
          }
        });

      setLoyaltyCode('');
    }
  }, [dispatch, loyaltyCode, loyaltyWarning, navigate, operatorSession, transaction, triggerLinkLoyalty]);

  if (workstation === undefined) return <div>No WS</div>;
  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Scan QR Code</h2>
              </div>
              {hasScanned === false && workstation.qrCodeReaderType === QrCodeReaderType.Scanner && (
                <KeyboardScanner onResult={keyboardResult} />
              )}
              {hasScanned === false && workstation.qrCodeReaderType === QrCodeReaderType.Camera && (
                <div className="pos-qr-scanner">
                  <QrScanner onResult={scannerResult} constraints={{ video: { facingMode: 'environment' } }} />
                </div>
              )}
              {hasScanned === true && (
                <div className="loyalty-failed">
                  {loyaltyWarning && (
                    <p>
                      Loyalty failed due to{' '}
                      <span>
                        {loyaltyWarning.warningType == 'not-verified' && 'NOT VERIFIED'}
                        {loyaltyWarning.warningType == 'not-active' && 'CUSTOMER DISABLED'}
                        {loyaltyWarning.warningType == 'expired-code' && 'CODE EXPIRED'}
                        {loyaltyWarning.warningType == 'invalid-code' && 'CODE INVALID'}
                        {loyaltyWarning.warningType == 'not-loyalty' && 'INVALID QR CODE'}
                        {loyaltyWarning.warningType == 'customer-not-found' && 'CUSTOMER NOT FOUND'}
                        {loyaltyWarning.warningType == 'code-redeemed' && 'VOUCHER ALREADY REDEEMED'}
                        {loyaltyWarning.warningType == 'failed-loyalty' && 'FAILURE WITH CRM'}
                      </span>
                    </p>
                  )}
                  <div className="pos-bookings__actions">
                    <button onClick={() => setHasScanned(false)} className="pos-bookings__action" type="button">
                      Try Again
                    </button>
                  </div>
                </div>
              )}
              {isLoading && <div>Checking Loyalty and processing, please wait.</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loyalty;
