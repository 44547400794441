import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import logger from '../../common/logger/AppLogger';
import {
  PinEntryDevice,
  TenderMedia,
  TransactionStatus,
  useCardTransactionMutation,
  useGetPinEntryDevicesQuery,
  useTenderTransactionMutation,
} from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { clearOrderState } from '../order/orderSlice';

import { setTransaction } from './tenderSlice';

function OfflinePinEntryDeviceModal() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const transactionId = Number(state.activeTransactionId ?? '0');
  const cardTender = state.cardTender;

  const [tenderTransactionTrigger] = useTenderTransactionMutation();
  const operator = useOperatorSession();
  const { data: pinEntryDevices, isLoading: pinEntryDevicesLoading } = useGetPinEntryDevicesQuery(true);

  const addTenderToTransaction = (tenderMedia: TenderMedia, amount: number, pedId: number) => {
    if (transactionId && operator) {
      tenderTransactionTrigger({
        transactionId: transactionId,
        tenderMediaId: tenderMedia.id,
        amount: amount,
        operatorId: operator.operator.id,
        pinEntryDeviceId: pedId,
      })
        .unwrap()
        .then((data) => {
          if (data.transaction.status === TransactionStatus.Closed) {
            dispatch(setTransaction([]));
            dispatch(clearOrderState());
            navigate(`/tender/summary/${data.transaction.id}`);
          } else navigate('../');
        })
        .catch((err) =>
          dispatch(showErrorDialog({ message: err.data && err.data.detail ? err.data.detail : JSON.stringify(err), dismissible: true })),
        );
    }
  };
  const tenderOfflineCard = (ped: PinEntryDevice) => {
    //return nothing
    if (cardTender == undefined) return;

    addTenderToTransaction(cardTender, state.amount, ped.id);
  };

  const PinEntryDevice = ({ position }: { position: number }) => {
    const index = position - 1;
    if (pinEntryDevices && pinEntryDevices.length >= position) {
      const pinEntryDevice = pinEntryDevices[index];
      return (
        <div className={`pos-pin-entry-devices__device`} onClick={() => tenderOfflineCard(pinEntryDevice)}>
          <h3 className="pos-pin-entry-devices__device-text">{pinEntryDevice.name}</h3>
          <p className="pos-pin-entry-devices__device-meta">
            <span className="pos-pin-entry-devices__device-meta-line">{pinEntryDevice.terminalId}</span>
          </p>
        </div>
      );
    }
    return <div className="pos-pin-entry-devices__device hidden-button"></div>;
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('../');
        }}
      >
        <div className="pos-modal pos-modal_role_pin-entry-devices">
          <div className="pos-modal__content">
            <div className="pos-pin-entry-devices js-pin-entry-devices">
              <div className="pos-pin-entry-devices__header">
                <h2 className="pos-pin-entry-devices__title">Pin Entry Devices</h2>
                <p className="pos-pin-entry-devices__subtitle">Please select a PED from the list below:</p>
              </div>
              {pinEntryDevicesLoading == false && pinEntryDevices?.length == 0 && (
                <div className="pos-pin-entry-devices__empty">No Pin Entry Devices are available</div>
              )}
              <div className="pos-pin-entry-devices__info">
                <div className="pos-pin-entry-devices__devices">
                  <div className="pos-pin-entry-devices__devices-list">
                    <div className="pos-pin-entry-devices__devices-scroll js-pin-entry-devices-servers-scroll">
                      <div className="pos-pin-entry-devices__devices-row">
                        <div className="pos-pin-entry-devices__devices-group">
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={1} />
                            <PinEntryDevice position={2} />
                            <PinEntryDevice position={3} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={4} />
                            <PinEntryDevice position={5} />
                            <PinEntryDevice position={6} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={7} />
                            <PinEntryDevice position={8} />
                            <PinEntryDevice position={9} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={10} />
                            <PinEntryDevice position={11} />
                            <PinEntryDevice position={12} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={13} />
                            <PinEntryDevice position={14} />
                            <PinEntryDevice position={15} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={16} />
                            <PinEntryDevice position={17} />
                            <PinEntryDevice position={18} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfflinePinEntryDeviceModal;
