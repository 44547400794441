import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import { LoyaltyType } from '../../api';
import { useIsTrainingMode, useOperatorSession, useWorkstation } from '../../appState';
import { setSidebarState, useShowSidebar, useTransaction } from '../orderSlice';

import Sidebar from './Sidebar';

export function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workstation = useWorkstation();
  const operatorSession = useOperatorSession();
  const showSidebar = useShowSidebar();
  const transaction = useTransaction();
  const isTrainingMode = useIsTrainingMode();

  const primaryTransaction = transaction.length > 0 ? transaction[0] : undefined;
  return (
    <>
      {isTrainingMode && <div className="training-mode-banner">TRAINING MODE</div>}
      <div className="pos-header pos-header_role_main">
        <div className="pos-header__area pos-header__area_role_info">
          <div className="pos-header__info">
            <button
              className="pos-header__sidebar-toggle js-sidebar-toggle"
              type="button"
              onClick={() => dispatch(setSidebarState(!showSidebar))}
            >
              Toggle Sidebar
            </button>
            <div className="pos-header__labels">
              <div className="pos-header__label">Till No. {workstation?.terminalNumber}</div>
              <div className="pos-header__label">
                {operatorSession?.operator.firstName} {operatorSession?.operator.lastName.substring(0, 1)}
              </div>
              <div className="pos-header__label">
                <Moment interval={1000} format="ddd DD-MM-yyyy" />
              </div>
              <div className="pos-header__label">
                <Moment interval={1000} format="HH:mm:ss" />
              </div>
            </div>
            {workstation?.isAppTill === false && (
              <button className="pos-header__search-toggle" type="button" onClick={() => navigate('./search')}>
                Search
              </button>
            )}
          </div>
        </div>
        <div className="pos-header__area pos-header__area_role_actions">
          <div className="pos-header__actions">
            {primaryTransaction?.isWaste === false && workstation?.isAppTill === false && (
              <>
                <button className="pos-header__action" type="button" onClick={() => navigate('./bookings')}>
                  Bookings
                </button>
                {primaryTransaction?.loyaltyType == LoyaltyType.None && (
                  <button
                    className="pos-header__action"
                    type="button"
                    onClick={() => navigate('./loyalty')}
                    disabled={primaryTransaction?.loyaltyType != LoyaltyType.None}
                  >
                    Loyalty
                  </button>
                )}{' '}
                {primaryTransaction?.loyaltyType == LoyaltyType.Customer && (
                  <button
                    className="pos-header__action"
                    type="button"
                    onClick={() => navigate(`./loyalty/details/${primaryTransaction?.id}`)}
                  >
                    Loyalty
                  </button>
                )}
                {workstation?.hideTables === false && (
                  <button className="pos-header__action" type="button" onClick={() => navigate('/tables')}>
                    Tables
                  </button>
                )}
              </>
            )}
            {primaryTransaction?.isWaste === true && <div className="waste-mode-banner">WASTE MODE</div>}
            {workstation?.isAppTill === true && <div className="waste-mode-banner">SELF SERVICE APP TILL</div>}
          </div>
        </div>
      </div>
      {showSidebar && <Sidebar />}
    </>
  );
}
