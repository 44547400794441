import { useCallback, useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/redux/hooks';
import logger from '../../common/logger/AppLogger';
import { useLazyGetOperatorsQuery, useOperatorSignOnMutation } from '../api';
import {
  clearOperatorSession,
  setOperatorSession,
  useEngineInfo,
  useIsTrainingMode,
  useOperatorSession,
  useServerLastPolled,
  useSite,
  useTrainingModeSourceUrl,
  useWorkstation,
} from '../appState';
import { selectVersion } from '../config';
import { clearErrorDialog, showErrorDialog } from '../error-dialog/errorDialogSlice';

import CardPaymentTerminalStatus from './CardPaymentTerminalStatus';
import Sidebar from './Sidebar';

function Welcome() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const workstation = useWorkstation();
  const version = useAppSelector(selectVersion);
  const site = useSite();
  const engine = useEngineInfo();
  const operatorSession = useOperatorSession();
  const serverLastPolled = useServerLastPolled();
  const [triggerSignOn] = useOperatorSignOnMutation();
  const [triggerGetLazyOperator] = useLazyGetOperatorsQuery();
  const isTrainingMode = useIsTrainingMode();
  const trainingModeSource = useTrainingModeSourceUrl();

  const keyboardState = useRef<string>('');
  const [signOnForceFob, setSignOnForceFob] = useState<{ fob: string; message: string } | undefined>(undefined);
  const [showSidebar, setShowSidebar] = useState(false);

  const signOn = useCallback(
    (fob: string, force: boolean) => {
      triggerSignOn({
        force: force,
        token: fob,
      })
        .unwrap()
        .then((signOnResult) => {
          logger.info('Sign On Result', signOnResult);
          dispatch(setOperatorSession(signOnResult));
          if (workstation?.hasSession) navigate('/order');
          else navigate('/start-session');
        })
        .catch((err) => {
          if (err && err.status === 404) {
            dispatch(
              showErrorDialog({
                message: 'Fob is not assigned to an active session.',
                dismissible: true,
              }),
            );
          } else if (err && err.status === 412) {
            setSignOnForceFob({
              fob,
              message: err.data.detail,
            });
          } else if (err && err.status === 402) {
            dispatch(
              showErrorDialog({
                message: err.data.detail,
                dismissible: true,
              }),
            );
          } else {
            logger.error(err);
            dispatch(
              showErrorDialog({
                message: 'A technical error occurred',
                dismissible: true,
              }),
            );
          }
        });
    },
    [dispatch, navigate, triggerSignOn, workstation],
  );

  const forceSignOn = () => {
    if (signOnForceFob) signOn(signOnForceFob.fob, true);

    setSignOnForceFob(undefined);
  };

  useEffect(() => {
    if (location.pathname === '/') {
      const eventHandler = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          const regexSignOn = /^0100([a-z|A-Z|0-9]{12})$/gm;
          const regexSignOff = /^0{3,4}(00)$/gm;
          if (operatorSession === undefined) {
            const regexSignOnMatch = keyboardState.current.match(regexSignOn);
            if (regexSignOnMatch !== null) {
              logger.info('Signing on with fob ', regexSignOnMatch);
              signOn(regexSignOnMatch[0], false);
            }
            const regexSignOffMatch = keyboardState.current.match(regexSignOff);
            if (regexSignOffMatch !== null) {
              logger.warn('Sign Off but not signed on');
              dispatch(clearErrorDialog());
            }
          } else {
            const regexSignOffMatch = keyboardState.current.match(regexSignOff);
            if (regexSignOffMatch !== null) {
              logger.info('Sign Off needed');
              dispatch(clearOperatorSession());
            }
          }
          keyboardState.current = '';
        } else {
          if (e.key.length === 1) {
            if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
            else keyboardState.current = keyboardState.current + e.key;
          }
        }
      };
      window.addEventListener('keydown', eventHandler);
      return () => window.removeEventListener('keydown', eventHandler);
    }
  }, [operatorSession, signOn, dispatch, location]);

  useEffect(() => {
    if (workstation !== undefined && workstation.hasFobReader == false) {
      logger.info('NO FOB READER, CHECKING IF OPERATOR SESSION EXISTS');
      triggerGetLazyOperator()
        .unwrap()
        .then((operatorSession) => {
          if (operatorSession !== undefined) {
            dispatch(setOperatorSession(operatorSession));
            if (workstation?.hasSession) navigate('/order');
            else navigate('/start-session');
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    }
  }, [workstation, dispatch, navigate, triggerGetLazyOperator]);

  return (
    <>
      <div className="pos-container pos-container_role_welcome">
        <div className="pos-header pos-header_role_welcome">
          <div className="pos-header__area pos-header__area_role_info">
            <div className="pos-header__info">
              <div className="pos-header__labels">
                <div className="pos-header__label">Till No. {workstation && <>{workstation.terminalNumber}</>}</div>
                <div className="pos-header__label">
                  <Moment interval={1000} format="ddd DD-MM-yyyy" />
                </div>
                <div className="pos-header__label">
                  <Moment interval={1000} format="HH:mm:ss" />
                </div>
              </div>
              <button className="pos-header__menu-toggle" type="button" onClick={() => setShowSidebar(!showSidebar)}>
                Toggle Menu
              </button>
            </div>
          </div>
        </div>
        <div className="pos-welcome">
          <div className="pos-welcome__info">
            <h1 className="pos-welcome__title">
              <span className="pos-welcome__title-label">
                Welcome <br /> to
              </span>
              <span className="pos-welcome__title-site-name">{site?.name}</span>
              {workstation?.hasSession === false && (
                <span className="pos-welcome__title-site-name pos-terminal_closed">Terminal Closed</span>
              )}
              {workstation?.isSessionBlockNewTransactions === true && (
                <span className="pos-welcome__title-site-name pos-terminal_blocked">
                  Till Session is from Previous Business Day.
                  <br /> No New Transactions Allowed
                </span>
              )}
              {isTrainingMode && trainingModeSource && (
                <div onClick={() => (window.location.href = trainingModeSource)} className="pos-welcome__title-site-name pos-training-mode">
                  Training Mode
                </div>
              )}
              {workstation?.isAppTill && <div className="pos-welcome__title-site-name pos-training-mode">Self Service App Till</div>}
            </h1>
          </div>

          <div className="pos-welcome__actions">
            <button className="pos-welcome__action pos-welcome__action_role_users" onClick={() => navigate('/servers-overview')}>
              Users
            </button>
            <button className="pos-welcome__action pos-welcome__action_role_sign-in" onClick={() => navigate('/sign-on/search')}>
              Sign In
            </button>
          </div>
          <div className="pos-welcome__version">
            PUB INVEST GROUP LTD | API VERSION {engine?.versionNumber} | UI VERSION {version?.version} | Last Pinged&nbsp;
            {serverLastPolled != 'Never' ? <Moment interval={1000} date={serverLastPolled} format="DD-MM-yyyy HH:mm:ss" /> : <>Never</>}
          </div>
        </div>
      </div>

      {signOnForceFob !== undefined && (
        <div className="pos-modal-portal">
          <div className="pos-modal-overlay">
            <div className="pos-popup pos-popup_role_error">
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Already Signed On!</h3>
                  <p className="pos-popup__text">{signOnForceFob.message}</p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={() => setSignOnForceFob(undefined)}>
                    Dismiss
                  </button>
                  <button className="pos-popup__action" onClick={forceSignOn}>
                    Override
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Outlet />
      {showSidebar && <Sidebar onClose={() => setShowSidebar(false)} />}
      <CardPaymentTerminalStatus />
    </>
  );
}

export default Welcome;
