import { useEffect, useState } from 'react';
import { ReactFitty } from 'react-fitty';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { PackagePurchaseRequest, PackageRule, useAddPackageMutation } from '../../../api';
import { useMenuItems, usePackages, useTransaction } from '../../orderSlice';

import './styles.sass';

export default function Package() {
  const { packageId } = useParams();
  const navigate = useNavigate();
  const packages = usePackages();
  const menuItems = useMenuItems();
  const transaction = useTransaction();

  const packageItem = packages.find((pkg) => pkg.packageId === Number(packageId));
  const [triggerAddPackage] = useAddPackageMutation();

  const [packageBuild, setPackageBuild] = useState<PackagePurchaseRequest | undefined>();

  const [ruleOpen, setRuleOpen] = useState<PackageRule | undefined>();
  const [ruleIndex, setRuleIndex] = useState(0);
  const [addError, setAddError] = useState<string | undefined>();
  const [itemIndex, setItemIndex] = useState(0);
  const [itemPages, setItemPages] = useState(0);

  const [itemQuantities, setItemQuantities] = useState<{ [key: number]: number }>({});

  const addPackage = () => {
    if (packageBuild) {
      triggerAddPackage(packageBuild)
        .unwrap()
        .then(() => {
          navigate('/order');
        })
        .catch((err) => {
          if (err && err.status === 412) setAddError(err.data.detail);
        });
    }
  };

  useEffect(() => {
    console.warn(itemIndex);
  }, [itemIndex]);

  useEffect(() => {
    if (packageBuild === undefined) {
      if (packageItem) {
        if (transaction && transaction.length === 1) {
          setPackageBuild({
            transactionId: transaction[0].id,
            packageId: packageItem?.packageId,
            items: [],
          });
        }
      }
    }
  }, [packageBuild, packageItem, transaction]);

  useEffect(() => {
    if (ruleOpen) {
      if (ruleOpen.items.length > 20) {
        // Calculate pages
        const pages = Math.ceil(ruleOpen.items.length / 18);
        setItemPages(pages);
      } else setItemPages(1);
    }
  }, [ruleOpen]);

  useEffect(() => {
    if (ruleOpen === undefined) if (packageBuild) if (packageItem && packageItem.rules.length > 0) setRuleOpen(packageItem.rules[0]);
  }, [ruleOpen, packageBuild, packageItem]);

  useEffect(() => {
    setItemQuantities({});
  }, [ruleOpen]);

  if (packageItem === undefined) return <Navigate to="/order" />;

  const checkRuleIsValid = (rule: PackageRule) => {
    if (packageBuild === undefined) return false;
    console.debug('checking rule', rule);
    if (rule.unique === false) {
      console.debug('unique false');
      const ruleItemsInBuild = packageBuild.items.filter((p) => p.ruleId === rule.ruleId);
      const quantityNeeded = rule.quantity;
      const quantityFound = ruleItemsInBuild.reduce((a, b) => a + b.quantity, 0);

      if (quantityFound !== quantityNeeded) return false;
    } else {
      console.debug('unique true');
      const ruleItemsInBuild = packageBuild.items.filter((p) => p.ruleId === rule.ruleId);
      if (rule.quantity != null) {
        console.debug('quantity not null');
        // All need to be same and quantity is on the rule
        const quantityNeeded = rule.quantity;
        const quantityFound = ruleItemsInBuild.reduce((a, b) => a + b.quantity, 0);

        if (quantityFound !== quantityNeeded) return false;
      } else {
        console.debug('quantity null');
        // There is no quantity on rule, check the quantity of the item is right
        const hasMultipleMenuItemsForRule = ruleItemsInBuild.map((p) => p.menuItemId);
        const uniqueMenuItems = [...new Set(hasMultipleMenuItemsForRule)];
        console.debug('uniqueMenuItems', uniqueMenuItems);
        if (uniqueMenuItems.length !== 1) return false;

        console.debug('has items');
        const itemInRule = rule.items.find((p) => p.menuItemId === uniqueMenuItems[0]);
        if (itemInRule) {
          const quantityNeeded = itemInRule.quantity;
          const quantityFound = ruleItemsInBuild.reduce((a, b) => a + b.quantity, 0);

          if (quantityFound !== quantityNeeded) return false;
        }
      }
    }
    return true;
  };

  const checkPackageIsBuilt = () => {
    if (packageBuild) {
      console.debug('checking');
      // For each rule, check it's there fully
      for (const rule of packageItem.rules) {
        console.debug('checking rule', rule);
        if (checkRuleIsValid(rule) === false) {
          console.debug('returning false');
          return false;
        }
      }
      console.debug('returning out');
      return true;
    }
    return false;
  };

  const hasPackageBuildComplete = checkPackageIsBuilt();

  const choiceClick = (rule: PackageRule, index: number) => {
    if (index > ruleIndex) return;
    // do nothing
    if (packageBuild) {
      const packageBuildLocal = { ...packageBuild };
      const items = packageBuildLocal.items.filter((p) => p.ruleId !== rule.ruleId);
      console.warn(items);
      packageBuildLocal.items = items;
      setPackageBuild(packageBuildLocal);
      setItemQuantities({});
      setRuleIndex(index);
      setRuleOpen(rule);
      setItemIndex(0);
    }
  };

  const fittyDims = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    let maxWidth = '430px';

    if (windowHeight === 800 && windowWidth === 1280) maxWidth = '473px';
    else if (windowHeight === 768 && windowWidth === 1024) maxWidth = '300px';
    else if (windowHeight === 600 && windowWidth === 1024) maxWidth = '300px';

    if (windowHeight >= 900 && windowHeight <= 905) {
      return {
        min: 30,
        max: 50,
        width: maxWidth,
      };
    } else if (windowHeight == 800) {
      return {
        min: 30,
        max: 45,
        width: maxWidth,
      };
    } else if (windowHeight == 768) {
      return {
        min: 25,
        max: 35,
        width: maxWidth,
      };
    } else if (windowHeight >= 600 && windowHeight <= 605) {
      return {
        min: 25,
        max: 35,
        width: maxWidth,
      };
    }
    return { min: 30, max: 60, width: maxWidth };
  };

  const PackageItem = ({ position }: { position: number }) => {
    if (ruleOpen) {
      if (ruleOpen.items.length >= position) {
        const index = itemIndex + position - 1;
        const ruleItem = ruleOpen.items[index];
        if (ruleItem !== undefined) {
          const menuItem = menuItems.find((p) => p.id === ruleItem.menuItemId);
          if (menuItem) {
            return (
              <div
                className={`pos-item__condiment ${itemQuantities[ruleItem.menuItemId] ? 'is-active' : ''}`}
                onClick={() => {
                  const newItemQuantities = { ...itemQuantities };
                  let totalQuantity = Object.values(newItemQuantities).reduce((a, b) => a + b, 0);
                  const quantityToAdd = ruleOpen.unique ? ruleItem.quantity ?? 1 : 1;
                  const quantity = (newItemQuantities[ruleItem.menuItemId] ?? 0) + quantityToAdd;
                  console.debug(totalQuantity, quantity);
                  if (totalQuantity < (ruleOpen.unique ? ruleItem.quantity : ruleOpen.quantity ?? 0)) {
                    newItemQuantities[ruleItem.menuItemId] = quantity;
                    setItemQuantities(newItemQuantities);
                    console.debug(newItemQuantities);
                    // Add to package item rules
                    setPackageBuild((prev) => {
                      if (prev) {
                        const items = [...prev.items];
                        const x = items.find((p) => p.menuItemId === menuItem.id);
                        if (x !== undefined) x.quantity = quantity;
                        else items.push({ menuItemId: menuItem.id, quantity: quantity, ruleId: ruleOpen.ruleId });
                        return {
                          ...prev,
                          items,
                        };
                      }
                    });
                  }
                  totalQuantity = Object.values(newItemQuantities).reduce((a, b) => a + b, 0);
                  if (totalQuantity >= (ruleOpen.quantity ?? ruleItem.quantity)) {
                    const totalRules = packageItem.rules.length;
                    if (ruleIndex + 1 < totalRules) {
                      setRuleOpen(packageItem.rules[ruleIndex + 1]);
                      setRuleIndex(ruleIndex + 1);
                    } else setRuleIndex(ruleIndex + 1);
                  }
                }}
              >
                <p className="pos-item__condiment-text">{menuItem.name}</p>
                <p className="pos-item__condiment-price">
                  Quantity x{ruleOpen.unique ? ruleItem.quantity : itemQuantities[ruleItem.menuItemId] ?? 1}
                </p>
              </div>
            );
          }
        }
      }
    }
    return <div className="pos-item__condiment hidden-button"></div>;
  };
  return (
    <div className="pos-modal-portal package-window">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_item">
          <div className="pos-modal__content">
            <div className="pos-item js-item">
              <div className="pos-item__header">
                <div className="pos-item__info">
                  <h2 className="pos-item__title">
                    <span className="pos-item__title-text js-item-title-text">
                      <ReactFitty style={{ width: fittyDims().width }} minSize={fittyDims().min} maxSize={fittyDims().max}>
                        {packageItem.name}
                      </ReactFitty>
                    </span>
                  </h2>
                </div>
              </div>
              <div className="pos-item__options js-item-options">
                <div className="pos-item__modifiers js-menu-modifiers">
                  <div className="pos-item__modifiers-list">
                    <div className="pos-item__modifiers-scroll js-item-modifiers-scroll">
                      <div className="pos-item__modifiers-row">
                        {packageItem.rules.map((rule, index) => (
                          <div
                            onClick={() => choiceClick(rule, index)}
                            className={`pos-item__modifier ${rule.ruleId === ruleOpen?.ruleId ? 'is-active' : ''} ${
                              checkRuleIsValid(rule) ? 'package-rule-complete' : ''
                            }`}
                            key={rule.ruleId}
                          >
                            <p className="pos-item__modifier-text">Choice {index + 1}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {hasPackageBuildComplete === false && (
                  <div className="pos-item__condiments">
                    <div className="pos-item__condiments-row">
                      <PackageItem position={1} />
                      <PackageItem position={2} />
                      <PackageItem position={3} />
                      <PackageItem position={4} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <PackageItem position={5} />
                      <PackageItem position={6} />
                      <PackageItem position={7} />
                      <PackageItem position={8} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <PackageItem position={9} />
                      <PackageItem position={10} />
                      <PackageItem position={11} />
                      <PackageItem position={12} />
                    </div>
                    <div className="pos-item__condiments-row">
                      <PackageItem position={13} />
                      <PackageItem position={14} />
                      <PackageItem position={15} />
                      <PackageItem position={16} />
                    </div>
                    <div className="pos-item__condiments-row">
                      {itemPages > 1 && (
                        <>
                          <button
                            disabled={itemIndex == 0}
                            className="pos-item__condiments-control pos-item__condiments-control_role_previous"
                            type="button"
                            onClick={() => setItemIndex(itemIndex - 18)}
                          >
                            Previous
                          </button>
                          <PackageItem position={17} />
                          <PackageItem position={18} />
                          <button
                            disabled={itemIndex >= (ruleOpen?.items.length ?? 0) - 18}
                            className="pos-item__condiments-control pos-item__condiments-control_role_next"
                            type="button"
                            onClick={() => setItemIndex(itemIndex + 18)}
                          >
                            Next
                          </button>
                        </>
                      )}
                      {itemPages == 1 && (
                        <>
                          <PackageItem position={17} />
                          <PackageItem position={18} />
                          <PackageItem position={19} />
                          <PackageItem position={20} />
                        </>
                      )}
                    </div>
                  </div>
                )}
                {hasPackageBuildComplete === true && (
                  <div className="package-add-overview-outer">
                    <h3 className="package-add-overview-title">The package has been built as below and is ready to add:</h3>
                    <div className="package-add-overview-items">
                      {packageBuild?.items.map((item, idx) => {
                        const menuItem = menuItems.find((p) => p.id === item.menuItemId);
                        return (
                          <div key={`item${idx}`} className="package-add-overview-item">
                            {menuItem?.name} x{item.quantity}
                          </div>
                        );
                      })}
                    </div>
                    {addError && <div>{addError}</div>}
                  </div>
                )}
              </div>
              <div className="pos-item__meta" style={{ marginTop: '100px' }}>
                <p className="pos-item__meta-text" style={{ marginBottom: '10px' }}>
                  <button
                    className="pos-item__add-button"
                    onClick={addPackage}
                    disabled={packageBuild === undefined || hasPackageBuildComplete === false}
                  >
                    Add
                  </button>
                </p>
                {hasPackageBuildComplete === false && (
                  <>
                    <p className="pos-item__meta-label">
                      {ruleOpen && ruleOpen.quantity !== null && <span>Quantity To Choose x{ruleOpen?.quantity}</span>}
                    </p>
                    {ruleOpen?.unique && <p className="pos-item__meta-label">Items Must Match</p>}
                    {ruleOpen?.unique === false && <p className="pos-item__meta-label">Any Item Combination</p>}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
