import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import appLogger from '../../common/logger/AppLogger';
import { PaymentMethodType, TransactionChangeTenderRequest, TransactionPayment, useChangeTenderMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { useTenderMedias } from '../tender/tenderSlice';

import SalesReviewOfflineCardPayment from './SaleReviewOfflineCardPayment';
import SalesReviewPinEntryDevice from './SalesReviewPinEntryDevice';
import { useSelectedTransaction } from './salesReviewSlice';

import './salesReview.sass';

function SalesReviewChangeTender() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tenderMedias = useTenderMedias();
  const operatorSession = useOperatorSession();

  const [triggerChangeTender] = useChangeTenderMutation();

  const [showAuthCode, setShowAuthCode] = useState(false);
  const [showEftPed, setShowEftPed] = useState(false);
  const [offlinePedId, setOfflinePedId] = useState<undefined | number>();
  const [showOfflinePed, setShowOfflinePed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [activePayment, setActivePayment] = useState<TransactionPayment | undefined>();

  const primaryTransaction = useSelectedTransaction();

  const cashTender = tenderMedias.find((x) => x.paymentMethodType === PaymentMethodType.Cash);
  const cardTenders = tenderMedias.filter((x) => x.paymentMethodType === PaymentMethodType.Card);
  const cardTender = cardTenders.find((p) => p.name === 'Card') ?? cardTenders.length > 0 ? cardTenders[0] : undefined;

  const changeTender = (type: 'CASH' | 'OFFLINE_CARD' | 'EFT_CARD', authCode?: string) => {
    console.info('off', offlinePedId);
    if (activePayment && primaryTransaction && operatorSession) {
      const params: TransactionChangeTenderRequest = {
        eft: type === 'EFT_CARD',
        transactionId: primaryTransaction.id,
        transactionPaymentId: activePayment.id,
        authCode: authCode,
        operatorId: operatorSession.operator.id,
        pedId: offlinePedId,
      };
      switch (type) {
        case 'CASH':
          params.newTenderMediaId = cashTender?.id;
          break;
        case 'OFFLINE_CARD':
          params.newTenderMediaId = cardTender?.id;
          break;
      }

      if (params.newTenderMediaId == undefined) {
        dispatch(
          showErrorDialog({
            message: 'No Valid Tender found',
            dismissible: true,
          }),
        );
        return;
      }

      triggerChangeTender(params)
        .unwrap()
        .then(() => {
          navigate('/order');
        })
        .catch((err) => {
          if (err && err.status && err.status === 400) {
            appLogger.error(err);
            dispatch(
              showErrorDialog({
                message: JSON.stringify(err.data.detail),
                dismissible: true,
              }),
            );
          } else {
            appLogger.error(err);
            dispatch(
              showErrorDialog({
                message: 'An error occurred',
                dismissible: true,
              }),
            );
          }
        });
    }
  };

  console.info('dbg', offlinePedId);

  const eftTender = (pedId: number) => {
    if (activePayment && primaryTransaction && operatorSession) {
      const params: TransactionChangeTenderRequest = {
        eft: true,
        transactionId: primaryTransaction.id,
        transactionPaymentId: activePayment.id,
        pedId: pedId,
        operatorId: operatorSession.operator.id,
      };

      triggerChangeTender(params)
        .unwrap()
        .then(() => {
          navigate('/order');
        })
        .catch((err) => {
          if (err && err.status && err.status === 400) {
            appLogger.error(err);
            dispatch(
              showErrorDialog({
                message: JSON.stringify(err.data.detail),
                dismissible: true,
              }),
            );
          } else {
            appLogger.error(err);
            dispatch(
              showErrorDialog({
                message: 'An error occurred',
                dismissible: true,
              }),
            );
          }
        });
    }
  };

  if (primaryTransaction === undefined) return <></>;

  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay">
        <div className="pos-modal pos-modal_role_void">
          <div className="pos-modal__content">
            <div className="pos-void">
              <div className="pos-void__header">
                <h2 className="pos-void__title">Change Tender</h2>
              </div>
              <div className="pos-void__group">
                <div className="pos-void__item-select">
                  <div className="pos-invoice pos-invoice_role_void-item-select js-invoice">
                    <div className="pos-invoice__panel">
                      <div className="pos-invoice__panel-content">
                        <div className="pos-invoice__list">
                          <div className="pos-invoice__list-labels">
                            <p className="pos-invoice__list-label">Tender</p>
                            <p className="pos-invoice__list-label">EFT</p>
                            <p className="pos-invoice__list-label"></p>
                            <p className="pos-invoice__list-label">Value</p>
                          </div>
                          <div className="pos-invoice__list-scroll js-invoice-scroll">
                            <Swiper
                              freeMode={true}
                              height={300}
                              slidesPerView="auto"
                              spaceBetween={30}
                              mousewheel={true}
                              scrollbar={true}
                              direction="vertical"
                              pagination={{ clickable: true }}
                              modules={[FreeMode, Pagination]}
                            >
                              <SwiperSlide>
                                <div className="pos-invoice__items">
                                  {primaryTransaction.transactionPayments.map((payment) => (
                                    <div
                                      key={payment.id}
                                      className={`pos-invoice__item ${activePayment?.id == payment.id ? 'is-active' : ''} ${
                                        payment.isEftPayment ? 'is-eft-row' : ''
                                      }`}
                                      onClick={() => {
                                        if (payment.isEftPayment === true) return;
                                        if (activePayment && activePayment.id === payment.id) setActivePayment(undefined);
                                        else setActivePayment(payment);
                                      }}
                                    >
                                      <div className="pos-invoice__item-info">
                                        <p className="pos-invoice__item-text">{payment.tenderMediaName}</p>
                                      </div>
                                      <div className="pos-invoice__item-qty">
                                        <p className="pos-invoice__item-text">{payment.isEftPayment ? 'YES' : ''}</p>
                                      </div>
                                      <div className="pos-invoice__item-price">
                                        <p className="pos-invoice__item-text"></p>
                                      </div>
                                      <div className="pos-invoice__item-total">
                                        <p className="pos-invoice__item-text">
                                          <Price price={payment.tendered - payment.change} />
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </SwiperSlide>
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="pos-invoice__panel-footer"></div>
                    </div>
                    <div className="pos-invoice__info">
                      <p className="pos-invoice__total">
                        <span className="pos-invoice__total-label">Total</span>
                        <span className="pos-invoice__total-value">
                          <Price price={primaryTransaction.totalValue} />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pos-void__actions">
                  <button
                    disabled={activePayment === undefined || processing || (activePayment && activePayment.tenderMediaName === 'Cash')}
                    className="pos-void__action"
                    type="button"
                    onClick={() => changeTender('CASH')}
                  >
                    Cash
                  </button>
                  <button
                    disabled={activePayment === undefined || processing || (activePayment && activePayment.tenderMediaName === 'Card')}
                    className="pos-void__action"
                    type="button"
                    onClick={() => setShowOfflinePed(true)}
                  >
                    Offline Card
                  </button>
                  <button
                    disabled={activePayment === undefined || processing}
                    className="pos-void__action"
                    type="button"
                    onClick={() => setShowEftPed(true)}
                  >
                    EFT Card
                  </button>
                </div>
              </div>
              <div className="pos-void__footer">
                <button className="pos-sale__action pos-sale__action_role_done" type="button" onClick={() => navigate('../')}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOfflinePed && activePayment && (
        <SalesReviewPinEntryDevice
          process={(pedId) => {
            if (pedId === undefined) setShowOfflinePed(false);
            else {
              console.info(pedId);
              setOfflinePedId(pedId);
              setShowAuthCode(true);
            }
          }}
        />
      )}
      {showAuthCode && activePayment && (
        <SalesReviewOfflineCardPayment
          amount={activePayment.tendered - activePayment.change}
          process={(authCode) => {
            if (authCode === undefined) setShowAuthCode(false);
            else changeTender('OFFLINE_CARD', authCode);
          }}
        />
      )}
      {showEftPed && activePayment && (
        <SalesReviewPinEntryDevice
          process={(pedId) => {
            if (pedId === undefined) setShowEftPed(false);
            else eftTender(pedId);
          }}
        />
      )}
    </div>
  );
}

export default SalesReviewChangeTender;
