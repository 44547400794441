import { useAppDispatch } from '../../../../app/redux/hooks';
import { setMessage, useMessage } from '../../orderSlice';

function MessageKeyboard() {
  const dispatch = useAppDispatch();
  const message = useMessage();
  const keyPress = (key: string) => dispatch(setMessage(message + key));
  const backSpace = () => dispatch(setMessage(message.length > 1 ? message.substring(0, message.length - 1) : ''));

  return (
    <div className="pos-message__keyboard">
      <div className="pos-keyboard pos-keyboard_role_message">
        <div className="pos-keyboard__controls">
          <div className="pos-keyboard__controls-row">
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Q')}>
              q
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('W')}>
              w
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('E')}>
              e
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('R')}>
              r
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('T')}>
              t
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Y')}>
              y
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('U')}>
              u
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('I')}>
              i
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('O')}>
              o
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('P')}>
              p
            </button>
          </div>
          <div className="pos-keyboard__controls-row">
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('A')}>
              a
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('S')}>
              s
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('D')}>
              d
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('F')}>
              f
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('G')}>
              g
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('H')}>
              h
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('J')}>
              j
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('K')}>
              k
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('L')}>
              l
            </button>
          </div>
          <div className="pos-keyboard__controls-row">
            <button className="pos-keyboard__control pos-keyboard__control_role_shift" type="button">
              Shift
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Z')}>
              z
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('X')}>
              x
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('C')}>
              c
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('V')}>
              v
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('B')}>
              b
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('N')}>
              n
            </button>
            <button className="pos-keyboard__control" type="button" onClick={() => keyPress('M')}>
              m
            </button>
            <button className="pos-keyboard__control pos-keyboard__control_role_backspace" type="button" onClick={backSpace}>
              Backspace
            </button>
          </div>
          <div className="pos-keyboard__controls-row">
            <button className="pos-keyboard__control pos-keyboard__control_role_space" type="button" onClick={() => keyPress(' ')}>
              Space
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageKeyboard;
