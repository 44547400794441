import { baseApi } from '../baseApi';

import { Site } from './Site';

const siteApiSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Sites'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSites: builder.query<Site, void>({
        query: () => ({
          url: '/Sites',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'Sites', id: result.id }] : []),
      }),
    }),
  });

export const { useGetSitesQuery } = siteApiSlice;
