import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { MenuItem } from '../../../api/menu';
import {
  setMenuPage,
  useActiveCategory,
  useActiveCategoryIds,
  useActiveMenuItems,
  useMenuItems,
  useMenuPage,
  setActiveMenuItems,
} from '../../orderSlice';

import MenuButton from './MenuButton';

function MenuButtonListPanel() {
  const dispatch = useAppDispatch();
  const menuPage = useMenuPage();
  const menuItems = useMenuItems();
  const activeCategory = useActiveCategory();
  const activeCategoryIds = useActiveCategoryIds();
  const activeMenuItems = useActiveMenuItems();
  const [numPages, setNumPages] = useState(1);

  const [displayMenuItems, setDisplayMenuItems] = useState<MenuItem[]>([]);

  const MenuPositionedButton = ({ position }: { position: number }) => {
    const index = position - 1;
    if (displayMenuItems.length >= position) {
      const menuItem = displayMenuItems[index];
      return <MenuButton menuItem={menuItem} />;
    }
    return <div className="pos-menu__item hidden-button"></div>;
  };

  useEffect(() => {
    const pageTotal = Math.ceil(activeMenuItems.length / 26);
    setNumPages(pageTotal);
  }, [activeMenuItems]);

  useEffect(() => {
    if (activeCategory !== undefined) {
      // category ids needs to be current category id and child category ids
      console.debug(activeCategoryIds);
      //console.debug(activeCategory);
      const filteredMenuItems = menuItems.filter(
        (x) => x.prices.length > 0 && x.menuItemCategoryIds.filter((y) => activeCategoryIds.includes(y)).length > 0,
      );
      //console.debug(filteredMenuItems);
      dispatch(setActiveMenuItems(filteredMenuItems));
    } else setActiveMenuItems([]);
  }, [activeCategory, menuItems, activeCategoryIds, dispatch]);

  useEffect(() => {
    if (activeMenuItems) {
      let offsetStart = (menuPage - 1) * 26 + 1;
      let offsetEnd = menuPage * 26;

      if (offsetEnd > activeMenuItems.length) offsetEnd = activeMenuItems.length;
      if (offsetStart > 0) offsetStart = offsetStart - 1;
      const items = activeMenuItems.slice(offsetStart, offsetEnd);

      setDisplayMenuItems(items);
    }
  }, [activeMenuItems, menuPage]);

  if (activeCategory === undefined) return <></>;
  return (
    <div className="pos-menu__items">
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={1} />
        <MenuPositionedButton position={2} />
        <MenuPositionedButton position={3} />
        <MenuPositionedButton position={4} />
      </div>
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={5} />
        <MenuPositionedButton position={6} />
        <MenuPositionedButton position={7} />
        <MenuPositionedButton position={8} />
      </div>
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={9} />
        <MenuPositionedButton position={10} />
        <MenuPositionedButton position={11} />
        <MenuPositionedButton position={12} />
      </div>
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={13} />
        <MenuPositionedButton position={14} />
        <MenuPositionedButton position={15} />
        <MenuPositionedButton position={16} />
      </div>
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={17} />
        <MenuPositionedButton position={18} />
        <MenuPositionedButton position={19} />
        <MenuPositionedButton position={20} />
      </div>
      <div className="pos-menu__items-row">
        <MenuPositionedButton position={21} />
        <MenuPositionedButton position={22} />
        <MenuPositionedButton position={23} />
        <MenuPositionedButton position={24} />
      </div>
      <div className="pos-menu__items-row">
        <button
          className="pos-menu__items-control pos-menu__items-control_role_previous"
          type="button"
          onClick={() => dispatch(setMenuPage(menuPage - 1))}
          disabled={menuPage === 1}
        >
          Previous
        </button>
        <MenuPositionedButton position={25} />
        <MenuPositionedButton position={26} />
        <button
          className="pos-menu__items-control pos-menu__items-control_role_next"
          type="button"
          onClick={() => dispatch(setMenuPage(menuPage + 1))}
          disabled={menuPage >= numPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default MenuButtonListPanel;
