export enum LayoutType {
  Categories = 1,
  FastBar = 2,
}
export enum LayoutLinkType {
  Category = 1,
  FastBar = 3,
  MenuItem = 2,
  Packages = 4,
  Package = 5,
}

export interface LayoutDefinition {
  id: number;
  layoutType: LayoutType;
  pageNumber: number;
  positionNumber: number;
  layoutLinkType: LayoutLinkType;
  menuItemCategoryId?: number | undefined;
  menuItemId?: number | undefined;
  text: string;
  fromPrice?: number | undefined;
  packageId?: number | undefined;
}

export interface LayoutDefinitionVersion {
  layoutDefinitions: LayoutDefinition[];
  version: number;
}
