import { useWorkstation } from '../../appState';

import { ServerOverviewFob } from './ServerOverviewFob';
import { SignOnSearch } from './SignOnSearch';

export function ServerOverviewSignOn() {
  const workstation = useWorkstation();
  if (workstation?.hasFobReader) return <ServerOverviewFob />;
  return <SignOnSearch />;
}
