import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { OperatorLevel, useLazyOperatorSessionGetByFobQuery, useOperatorSignOffMutation } from '../../../api';
import { clearOperatorSession, useOperatorSession, useWorkstation } from '../../../appState';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';
import { clearOrderState, setDisableSignOff } from '../../orderSlice';

export default function VoidLogon() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const workstation = useWorkstation();
  const operatorSession = useOperatorSession();

  const keyboardState = useRef<string>('');
  const [fob, setFob] = useState<string | undefined>(undefined);
  const [fail, setFail] = useState<boolean>(false);

  const [triggerSignOff] = useOperatorSignOffMutation();
  const [triggerFobCheck] = useLazyOperatorSessionGetByFobQuery();

  const signOffCurrentOperator = () => {
    if (operatorSession) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          dispatch(
            showErrorDialog({
              message: 'Failed to sign off operator',
              dismissible: true,
              buttonText: 'OK',
            }),
          );
          navigate('/');
        });
    }
  };

  useEffect(() => {
    if (fob) {
      triggerFobCheck(fob)
        .unwrap()
        .then((response) => {
          // Do soemthing
          if (response.operator.operatorLevel <= OperatorLevel.Supervisor) {
            dispatch(setDisableSignOff(false));
            navigate('/order/void', { state: { voidOperatorId: response.operator.id } });
          } else setFail(true);
        })
        .catch(() => {
          setFob(undefined);
          setFail(true);
        });
    }
  }, [dispatch, fob, navigate, triggerFobCheck]);

  useEffect(() => {
    if (operatorSession) {
      if (operatorSession.operator.operatorLevel <= OperatorLevel.Supervisor)
        navigate('/order/void', { state: { voidOperatorId: operatorSession.operator.id } });
      else {
        if (workstation?.hasFobReader) dispatch(setDisableSignOff(true));
        else navigate('/order/void-tablet-logon');
      }
    }
  }, [dispatch, navigate, operatorSession, workstation?.hasFobReader]);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOn = /^0100([a-z|A-Z|0-9]{12})$/gm;
        const regexSignOnMatch = keyboardState.current.match(regexSignOn);
        if (regexSignOnMatch !== null) {
          console.info('Signing on with fob void', regexSignOnMatch);
          setFob(regexSignOnMatch[0]);
        }
        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, []);

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') signOffCurrentOperator();
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">VOID CONFIRM IDENTITY</h2>
                <p className="pos-sign-in__subtitle">To Process a Void you must identify yourself, and be a supervisor or higher.</p>
                {fob === undefined && <p className="pos-sign-in__subtitle">Please Remove the Operators fob, and place your fob on.</p>}
                {fob !== undefined && <p className="pos-sign-in__subtitle">Validating Fob, Please Wait...</p>}
                {fail === true && <p className="pos-sign-in__error">Invalid Fob for Void</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
