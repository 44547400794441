import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import { Transaction, usePrintTransactionMutation } from '../api';
import { useOperatorSession } from '../appState';

import { setActiveTransactionLineItem, setVisibleSplitIndex, useActiveTransactionLineItem, useVisibleSplitIndex } from './splitSplice';

import 'swiper/css/bundle';

function SplitOrder({ transaction, splitNumber, totalSplits }: { transaction: Transaction; splitNumber: number; totalSplits: number }) {
  const dispatch = useAppDispatch();
  const [triggerPrint] = usePrintTransactionMutation();
  const operatorSession = useOperatorSession();
  const activeTransactionLineItem = useActiveTransactionLineItem();
  const visibleSplitIndex = useVisibleSplitIndex();

  const printClick = () => {
    triggerPrint({
      operatorId: operatorSession ? operatorSession.operator.id : 0,
      transactionId: transaction.id,
    });
  };
  return (
    <div className="pos-split__order">
      <div className="pos-split__invoice">
        <div className="pos-invoice pos-invoice_role_split js-invoice">
          <div className={`pos-invoice__panel pos-invoice__panel-split-${splitNumber === 1 ? 'left' : 'right'}`}>
            <div className="pos-invoice__panel-header">
              <p className="pos-invoice__panel-title">Split {splitNumber}</p>
            </div>
            <div className="pos-invoice__panel-content">
              <div className="pos-invoice__list">
                <div className="pos-invoice__list-labels">
                  <p className="pos-invoice__list-label">Product</p>
                  <p className="pos-invoice__list-label">Qty</p>
                  <p className="pos-invoice__list-label">Each</p>
                  <p className="pos-invoice__list-label">Total</p>
                </div>
                <div className="pos-invoice__list-scroll js-invoice-scroll">
                  <Swiper
                    freeMode={true}
                    height={300}
                    slidesPerView="auto"
                    spaceBetween={30}
                    mousewheel={true}
                    scrollbar={true}
                    direction="vertical"
                    pagination={{ clickable: true }}
                    modules={[FreeMode, Pagination]}
                  >
                    <SwiperSlide>
                      <div className="pos-invoice__items">
                        {transaction.transactionLineItems.map((lineItem) => (
                          <div
                            key={lineItem.id}
                            className={`pos-invoice__item${
                              activeTransactionLineItem !== undefined && activeTransactionLineItem.id === lineItem.id ? ' is-active' : ''
                            }`}
                            onClick={() => dispatch(setActiveTransactionLineItem(lineItem))}
                          >
                            <div className="pos-invoice__item-info">
                              <p className="pos-invoice__item-text">{lineItem.description}</p>
                              <div className="pos-invoice__item-meta">
                                {lineItem.childTransactionLineItems.map((childLineItem) => (
                                  <div key={childLineItem.id} className="pos-invoice__item-meta-group">
                                    <p className="pos-invoice__item-meta-text">
                                      {childLineItem.menuItemId > 0 && (
                                        <>
                                          {childLineItem.description} <Price price={childLineItem.itemPrice} />
                                        </>
                                      )}
                                      {childLineItem.menuItemId === 0 && <i>{childLineItem.description}</i>}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="pos-invoice__item-qty">
                              <p className="pos-invoice__item-text">{lineItem.quantity}</p>
                            </div>
                            <div className="pos-invoice__item-price">
                              <p className="pos-invoice__item-text">
                                <Price price={lineItem.itemPrice} />
                              </p>
                            </div>
                            <div className="pos-invoice__item-total">
                              <p className="pos-invoice__item-text">
                                <Price price={lineItem.totalPrice} />
                              </p>
                            </div>
                          </div>
                        ))}
                        {transaction.transactionAppliedDeals.map((deal) => (
                          <div key={deal.id} className={`pos-invoice__item pos-invoice__item-deal`}>
                            <div className="pos-invoice__item-info">
                              <p className="pos-invoice__item-text">{deal.dealName}</p>
                            </div>
                            <div className="pos-invoice__item-qty"></div>
                            <div className="pos-invoice__item-price">
                              <p className="pos-invoice__item-text"></p>
                            </div>
                            <div className="pos-invoice__item-total">
                              <p className="pos-invoice__item-text">
                                <Price price={-deal.amount} />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-invoice__info">
            {transaction.tableId && <p className="pos-invoice__table-number">T{transaction.tableNumber}</p>}
            <p className="pos-invoice__total">
              <span className="pos-invoice__total-label">Total</span>
              <span className="pos-invoice__total-value">
                <Price price={transaction.totalValue} />
              </span>
            </p>
          </div>
        </div>
      </div>
      {splitNumber > 1 && (
        <div className="pos-split__pagination">
          <div className="pos-pagination pos-pagination_role_split">
            <button
              className="pos-pagination__control pos-pagination__control_role_previous"
              type="button"
              disabled={visibleSplitIndex <= 1}
              onClick={() => dispatch(setVisibleSplitIndex(visibleSplitIndex - 1))}
            >
              Previous
            </button>
            <div className="pos-pagination__info">
              <span className="pos-pagination__label">Split</span>
              <span className="pos-pagination__value">
                {splitNumber}/{totalSplits}
              </span>
            </div>
            <button
              className="pos-pagination__control pos-pagination__control_role_next"
              type="button"
              disabled={visibleSplitIndex + 1 === totalSplits}
              onClick={() => dispatch(setVisibleSplitIndex(visibleSplitIndex + 1))}
            >
              Next
            </button>
          </div>
        </div>
      )}
      <div className="pos-split__actions">
        <button className="pos-split__action pos-split__action_role_receipt" type="button" onClick={printClick}>
          Receipt
        </button>
      </div>
    </div>
  );
}

export default SplitOrder;
