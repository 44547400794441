import { useCallback, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import LoadingModal from '../../../common/components/loading-modal/LoadingModal';
import logger from '../../../common/logger/AppLogger';
import {
  useGetLayoutDefinitionsQuery,
  useGetMenuCurrentVersionQuery,
  useGetMessagePresetsQuery,
  useGetVoidReasonsQuery,
  useOperatorSignOffMutation,
  useGetUnitOfSalesQuery,
  useCreateTransactionMutation,
  useLazyGetTransactionForOperatorQuery,
  useGetMenuCategoriesQuery,
  useGetPackagesQuery,
} from '../../api';
import {
  clearOperatorSession,
  useLayoutVersionLastPoll,
  useMenuVersionLastPoll,
  useOperatorSession,
  usePackageVersionLastPoll,
  useWorkstation,
} from '../../appState';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import {
  clearOrderState,
  setLayoutDefinitions,
  setMenuItemCategories,
  setMenuVersion,
  setMessagePresets,
  setPackages,
  setTransaction,
  setUnitOfSales,
  setVoidReasons,
  useDisableSignOff,
  useMenuVersionNumber,
  useTransaction,
  useUnitOfSales,
} from '../orderSlice';

import { Header } from './Header';
import LoyaltyScanInline from './LoyaltyScanInline';
import MenuSection from './MenuSection';
import OrderAddToTransaction from './OrderAddToTransaction';
import OrderSection from './OrderSection';

import '../styles.sass';

function Order() {
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const transaction = useTransaction();
  const dispatch = useAppDispatch();
  const workstation = useWorkstation();

  const hasFobReader = workstation?.hasFobReader;

  const layoutLastPoll = useLayoutVersionLastPoll();
  const menuLastPoll = useMenuVersionLastPoll();
  const packageLastPoll = usePackageVersionLastPoll();
  const disableSignOff = useDisableSignOff();

  const [triggerSignOff] = useOperatorSignOffMutation();
  const {
    data: layoutDefinitions,
    isLoading: isLayoutDefinitionsLoading,
    refetch: refetchLayoutDefinitions,
  } = useGetLayoutDefinitionsQuery();
  const { data: menuItemCategories, isLoading: isMenuItemCategoriesLoading } = useGetMenuCategoriesQuery();
  const { data: unitOfSales, isLoading: isUnitOfSalesLoading } = useGetUnitOfSalesQuery();
  const { data: messagePresets, isLoading: messagePresetsLoading } = useGetMessagePresetsQuery();
  const { data: voidReasons, isLoading: voidReasonsLoading } = useGetVoidReasonsQuery();
  const { data: menuVersion, isLoading: menuVersionLoading, refetch: refetchMenu } = useGetMenuCurrentVersionQuery();
  const { data: packages, isLoading: packagesLoading, refetch: refetchPackages } = useGetPackagesQuery();

  const stateUnitOfSales = useUnitOfSales();
  const menuVersionNumber = useMenuVersionNumber();

  const [triggerGetTransactionForOperator, { data: transactionForOperator, error: transactionForOperatorError }] =
    useLazyGetTransactionForOperatorQuery();

  const [createTransaction] = useCreateTransactionMutation();
  const isLoading =
    isLayoutDefinitionsLoading ||
    menuVersionLoading ||
    isMenuItemCategoriesLoading ||
    isUnitOfSalesLoading ||
    messagePresetsLoading ||
    voidReasonsLoading ||
    packagesLoading ||
    transaction === undefined;

  const keyboardState = useRef<string>('');

  const signOff = useCallback(() => {
    if (operatorSession !== undefined) {
      triggerSignOff({
        operatorId: operatorSession.operator.id,
      })
        .unwrap()
        .then(() => {
          dispatch(clearOperatorSession());
          dispatch(clearOrderState());
          navigate('/');
        })
        .catch(() => {
          clearOperatorSession();
          navigate('/');
        });
    }
  }, [dispatch, navigate, operatorSession, triggerSignOff]);

  useEffect(() => {
    if (layoutDefinitions) dispatch(setLayoutDefinitions(layoutDefinitions));
  }, [layoutDefinitions, dispatch, layoutLastPoll]);

  // When the polled version and redux version is different, let's get the latest layout version
  useEffect(() => {
    if (layoutDefinitions && layoutDefinitions.version !== layoutLastPoll) refetchLayoutDefinitions();
  }, [layoutDefinitions, layoutLastPoll, refetchLayoutDefinitions]);

  useEffect(() => {
    if (voidReasons) dispatch(setVoidReasons(voidReasons));
  }, [voidReasons, dispatch]);

  useEffect(() => {
    if (packages) dispatch(setPackages(packages));
  }, [packages, dispatch]);

  // When the polled version and redux version is different, let's get the latest package version
  useEffect(() => {
    if (packages && packages.version !== packageLastPoll) refetchPackages();
  }, [packageLastPoll, packages, refetchPackages]);

  useEffect(() => {
    if (messagePresets) dispatch(setMessagePresets(messagePresets));
  }, [messagePresets, dispatch]);

  useEffect(() => {
    if (menuItemCategories) dispatch(setMenuItemCategories(menuItemCategories));
  }, [menuItemCategories, dispatch]);

  useEffect(() => {
    if (unitOfSales && stateUnitOfSales.length === 0) dispatch(setUnitOfSales(unitOfSales));
  }, [unitOfSales, dispatch, stateUnitOfSales.length]);

  useEffect(() => {
    if (menuVersion && menuVersion.versionNumber > menuVersionNumber) dispatch(setMenuVersion(menuVersion));
  }, [menuVersion, menuVersionNumber, dispatch]);

  // Check the polled menu date and our menu date, refetch
  useEffect(() => {
    if (menuVersion && menuVersion.versionNumber !== menuLastPoll) refetchMenu();
  }, [menuLastPoll, menuVersion, refetchMenu]);

  useEffect(() => {
    if (disableSignOff || hasFobReader === false) return;
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOff = /^0{3,4}(00)$/gm;
        if (operatorSession === undefined) navigate('/');
        else {
          const regexSignOffMatch = keyboardState.current.match(regexSignOff);
          if (regexSignOffMatch !== null) {
            logger.info('Sign Off needed');
            signOff();
          }
        }

        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };
    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [operatorSession, signOff, dispatch, navigate, disableSignOff, hasFobReader]);

  useEffect(() => {
    if (operatorSession === undefined) navigate('/');
    else triggerGetTransactionForOperator(operatorSession.operator.id);
  }, [operatorSession, navigate, triggerGetTransactionForOperator]);

  useEffect(() => {
    if (transactionForOperatorError !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorGeneric = transactionForOperatorError as any;
      if (errorGeneric.status && errorGeneric.status === 404 && operatorSession) {
        createTransaction(operatorSession.operator.id)
          .unwrap()
          .then((createdTransaction) => {
            dispatch(setTransaction([createdTransaction]));
          })
          .catch((err) => {
            if (err.status && err.status === 412) {
              dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
              navigate('/');
            } else if (err.status && err.status === 406) {
              dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
              navigate('/start-session');
            }
          });
      } else logger.error(`Error getting transaction for operator with error ${JSON.stringify(transactionForOperatorError)}`);
    }
  }, [transactionForOperatorError, createTransaction, dispatch, navigate, operatorSession]);

  useEffect(() => {
    if (transactionForOperator !== undefined) {
      dispatch(setTransaction(transactionForOperator));
      if (transactionForOperator.length > 1) navigate('../tender');
      if (transactionForOperator.length === 1) {
        if (transactionForOperator[0].bookingId && transactionForOperator[0].bookingPaid && transactionForOperator[0].bookingAmount)
          if (transactionForOperator[0].bookingPaid < transactionForOperator[0].bookingAmount) navigate('../tender');

        if (transactionForOperator[0].cardPaymentInProgress === true) navigate('../tender');
        if (transactionForOperator[0].forbidNewLineItems === true) navigate('../tender');
      }
    }
  }, [transactionForOperator, dispatch, navigate]);

  if (isLoading) return <LoadingModal />;

  return (
    <>
      <div className="pos-container pos-container_role_main">
        <LoyaltyScanInline />
        <Header />

        <div className="pos-main">
          <MenuSection />
          <OrderSection />
        </div>
      </div>
      <OrderAddToTransaction />
      <Outlet />
    </>
  );
}
export default Order;
