import { useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import logger from '../../../common/logger/AppLogger';
import { useGetWorkstationSessionsQuery, usePrintWorkstationSessionMutation, WorkstationSession } from '../../api';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';

import './styles.sass';

function SessionReview() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [triggerPrintSession] = usePrintWorkstationSessionMutation();
  const { data } = useGetWorkstationSessionsQuery();

  const [resultOffset, setResultOffset] = useState(0);
  const [activeWorkstationSession, setActiveWorkstationSession] = useState<WorkstationSession | undefined>();
  const resultNextPage = () => {
    if (data && resultOffset < data.length - 15) setResultOffset(resultOffset + 15);
  };

  const resultPreviousPage = () => {
    if (data && resultOffset > 3 && data.length > 15) setResultOffset(resultOffset - 15);
    else setResultOffset(0);
  };

  const WorkstationSessionButton = ({ position }: { position: number }) => {
    const index = position - 1;
    if (data) {
      const currentData = data.slice(resultOffset, resultOffset + 15);
      if (currentData.length >= position) {
        const item = currentData[index];

        if (item) {
          return (
            <div
              className={`pos-servers-overview__server ${
                activeWorkstationSession && activeWorkstationSession.id === item.id ? 'is-active' : ''
              }`}
              onClick={() => setActiveWorkstationSession(item)}
            >
              <h3 className="pos-servers-overview__server-text">Session {item.id}</h3>
              <p className="pos-servers-overview__server-meta">
                <span className="pos-servers-overview__server-meta-line">
                  {item.dateEnded && (
                    <>
                      Stopped At: <Moment format="HH:mm:ss" date={item.dateEnded} />
                    </>
                  )}
                  {item.dateEnded == undefined && <>Not Ended</>}
                </span>
              </p>
            </div>
          );
        }
        return <></>;
      }
    }
    return <></>;
  };

  const print = () => {
    if (activeWorkstationSession) {
      triggerPrintSession(activeWorkstationSession.id)
        .unwrap()
        .then(() => {
          logger.info('Successfully requested print');
        })
        .catch((err) => {
          logger.error(err);
          dispatch(
            showErrorDialog({
              message: 'Failed to request print',
              dismissible: true,
            }),
          );
        });
    }
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Terminal Sessions</h2>
              </div>
              <div className="pos-servers-overview__info">
                <div className="pos-servers-overview__servers">
                  <button
                    className="pos-servers-overview__servers-control pos-servers-overview__servers-control_role_previous js-servers-overview-servers-previous"
                    type="button"
                    onClick={resultPreviousPage}
                    disabled={resultOffset === 0}
                  >
                    Previous
                  </button>
                  <div className="pos-servers-overview__servers-list">
                    <div className="pos-servers-overview__servers-scroll js-servers-overview-servers-scroll">
                      <div className="pos-servers-overview__servers-row">
                        <div className="pos-servers-overview__servers-group">
                          <div className="pos-servers-overview__servers-row">
                            <WorkstationSessionButton position={1} />
                            <WorkstationSessionButton position={2} />
                            <WorkstationSessionButton position={3} />
                          </div>
                          <div className="pos-servers-overview__servers-row">
                            <WorkstationSessionButton position={4} />
                            <WorkstationSessionButton position={5} />
                            <WorkstationSessionButton position={6} />
                          </div>
                          <div className="pos-servers-overview__servers-row">
                            <WorkstationSessionButton position={7} />
                            <WorkstationSessionButton position={8} />
                            <WorkstationSessionButton position={9} />
                          </div>
                          <div className="pos-servers-overview__servers-row">
                            <WorkstationSessionButton position={10} />
                            <WorkstationSessionButton position={11} />
                            <WorkstationSessionButton position={12} />
                          </div>
                          <div className="pos-servers-overview__servers-row">
                            <WorkstationSessionButton position={13} />
                            <WorkstationSessionButton position={14} />
                            <WorkstationSessionButton position={15} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="pos-servers-overview__servers-control pos-servers-overview__servers-control_role_next js-servers-overview-servers-next"
                    type="button"
                    disabled={data && resultOffset < data.length - 15 === false}
                    onClick={resultNextPage}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="pos-servers-overview__actions">
                {activeWorkstationSession && (
                  <button
                    className="pos-servers-overview__action server-overview-action  server-overview-action-print"
                    type="button"
                    onClick={print}
                  >
                    Print
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionReview;
