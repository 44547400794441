import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import appLogger from '../../common/logger/AppLogger';
import { useOperatorStartShiftMutation } from '../api';
import { setOperatorSession, useWorkstation } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';

import { useRemoteSignOn, useSignOnOperator, useSignOnPin } from './signOnSlice';

function SignOnNoFob() {
  const dispatch = useAppDispatch();
  const workstation = useWorkstation();
  const navigate = useNavigate();
  const remoteSignOn = useRemoteSignOn();

  const operator = useSignOnOperator();
  const pin = useSignOnPin();

  const [startOperator, { isLoading }] = useOperatorStartShiftMutation();

  useEffect(() => {
    if (operator === undefined) navigate('../search');
    if (pin === undefined) navigate('../pin');
  }, [operator, pin, navigate]);

  const onDoneClick = () => {
    if (operator) {
      startOperator({
        pinCode: pin ? pin : '',
        operatorId: operator.id,
        remote: remoteSignOn,
      })
        .unwrap()
        .then((operatorSession) => {
          dispatch(setOperatorSession(operatorSession));
          if (workstation?.hasSession) navigate('/order');
          else navigate('/start-session');
        })
        .catch((err) => {
          appLogger.warn(err);
          if (err.status && err.status === 412) {
            if (err.data.type === 'OperatorNotClockedIn') dispatch(showErrorDialog({ message: err.data.title, dismissible: true }));
            else if (err.data.type === 'OperatorSessionOnAnotherWorkstation')
              dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
            else if (err.data.type === 'OperatorSessionActive') dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
            else if (err.data.type === 'RemoteSignInForbid') dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
          } else dispatch(showErrorDialog({ message: 'An error occurred starting session', dismissible: true }));
        });
    } else dispatch(showErrorDialog({ message: 'Failed', dismissible: true }));
  };
  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Signing In</h2>
        </div>
        <div className="pos-sign-in__info">
          <p className="pos-sign-in__label">
            You are now signing in to this Till as {operator?.firstName} {operator?.lastName}
            <br />
            and will remain signed in until you Sign Out manually
          </p>
        </div>
        <div className="pos-sign-in__actions">
          <button className="pos-sign-in__action" type="button" onClick={onDoneClick} disabled={isLoading}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignOnNoFob;
