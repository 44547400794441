import { useNavigate } from 'react-router-dom';

import Price from '../../../../common/components/price/Price';
import { LayoutDefinition } from '../../../api';
import { usePackages } from '../../orderSlice';

import './category-fastbar.sass';

export function FastBarPackageButton({ layoutDefinition }: { layoutDefinition: LayoutDefinition }) {
  const navigate = useNavigate();
  const packages = usePackages();
  const item = packages.find((x) => x.packageId === layoutDefinition.packageId);

  if (item === undefined) return <div className="pos-menu__item hidden-button"></div>;
  const categoryClick = (layoutDefinition: LayoutDefinition) => {
    navigate(`/order/package/${layoutDefinition.packageId}`);
  };

  return (
    <div className={`pos-menu__item category-button-fastbar`} onClick={() => categoryClick(layoutDefinition)}>
      <p className="pos-menu__item-meta">Packages</p>
      <p className="pos-menu__category-text">{item.name}</p>
      <p className="pos-menu__item-meta">
        <Price price={item.price} />
      </p>
    </div>
  );
}
