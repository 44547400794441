import { baseApi } from '../baseApi';

import { PackageVersion } from './Package';

const slice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query<PackageVersion, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/Packages/v2',
        method: 'GET',
        headers: {
          'x-api-version': '1.0',
        },
      }),
    }),
  }),
});

export const { useGetPackagesQuery, useLazyGetPackagesQuery } = slice;
