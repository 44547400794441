import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/redux/hooks';
import appLogger from '../../common/logger/AppLogger';
import { useDiagnosticsMutation } from '../api';
import { useEngineInfo, useIsTrainingMode, useTrainingModeSourceUrl } from '../appState';
import { selectVersion, useTrainingModeUrl } from '../config';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { showModalDialog } from '../modal/modalDialogSlice';

import { setRemoteSignOn } from './signOnSlice';

export default function Sidebar({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const engineInfo = useEngineInfo();
  const trainingModeUrl = useTrainingModeUrl();
  const isTrainingMode = useIsTrainingMode();
  const trainingModeSourceUrl = useTrainingModeSourceUrl();

  const version = useAppSelector(selectVersion);
  const [triggerDiagnostics] = useDiagnosticsMutation();

  return (
    <div className="pos-sidebar-overlay js-sidebar-overlay is-visible">
      <div className="pos-sidebar pos-sidebar_role_welcome">
        <div className="pos-sidebar__menu">
          <ul className="pos-sidebar__menu-list">
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  navigate('/workstation-sessions');
                  onClose();
                }}
              >
                Terminal Sessions
              </button>
            </li>

            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  dispatch(
                    showModalDialog({
                      title: 'System Information',
                      message: `<p><b>API Version:</b> ${engineInfo?.versionNumber}<br /><b>UI Version:</b> ${version?.informational}<br /><b>Database:</b> ${engineInfo?.databaseName}<br/><b>Environment:</b> ${engineInfo?.environment}</p>`,
                      height: '320px',
                      width: '400px',
                    }),
                  );
                  onClose();
                }}
              >
                System Info
              </button>
            </li>

            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  triggerDiagnostics()
                    .unwrap()
                    .then(() => {
                      dispatch(
                        showModalDialog({
                          title: 'Requested',
                          message: 'Diagnostics print has been requested',
                          buttonText: 'OK',
                        }),
                      );
                    })
                    .catch((err) => {
                      appLogger.error(err);
                      dispatch(
                        showErrorDialog({
                          message: 'An error occurred requesting',
                          buttonText: 'OK',
                          dismissible: true,
                        }),
                      );
                    });
                  onClose();
                }}
              >
                Print Diagnostics
              </button>
            </li>
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  navigate('./reload');
                  onClose();
                }}
              >
                Restart
              </button>
            </li>
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  navigate('./ped-status');
                  onClose();
                }}
              >
                PED Status
              </button>
            </li>
            <li className="pos-sidebar__menu-item">
              <button
                className="pos-sidebar__menu-action"
                type="button"
                onClick={() => {
                  dispatch(setRemoteSignOn(true));
                  navigate('./sign-on/search');
                  onClose();
                }}
              >
                Remote Sign In
              </button>
            </li>
            {trainingModeUrl && isTrainingMode == false && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    navigate('./training-mode');
                    onClose();
                  }}
                >
                  Training Mode
                </button>
              </li>
            )}
            {isTrainingMode && trainingModeSourceUrl != undefined && (
              <li className="pos-sidebar__menu-item">
                <button
                  className="pos-sidebar__menu-action"
                  type="button"
                  onClick={() => {
                    window.location.href = trainingModeSourceUrl;
                  }}
                >
                  Exit Training Mode
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
