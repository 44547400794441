import { baseApi } from '../baseApi';

import { Printer } from './Printer';
import { WorkstationOrderDevice } from './WorkstationOrderDevice';

const slice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['WorkstationOrderDevices', 'Printers'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrderDevices: builder.query<WorkstationOrderDevice[], void>({
        query: () => ({
          url: `/workstation-order-devices`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'WorkstationOrderDevices' as const,
                  id,
                })),
                { type: 'WorkstationOrderDevices', id: 'LIST' },
              ]
            : [{ type: 'WorkstationOrderDevices', id: 'LIST' }],
      }),
      getPrinters: builder.query<Printer[], void>({
        query: () => ({
          url: `/workstation-order-devices/printers`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Printers' as const,
                  id,
                })),
                { type: 'Printers', id: 'LIST' },
              ]
            : [{ type: 'Printers', id: 'LIST' }],
      }),
      updateOrderDevice: builder.mutation<void, WorkstationOrderDevice>({
        query: (wod: WorkstationOrderDevice) => ({
          url: `/workstation-order-devices/${wod.id}${wod.overridePrinterId ? `?printerid=${wod.overridePrinterId}` : ''}`,
          method: 'POST',
        }),
        invalidatesTags: [{ type: 'WorkstationOrderDevices' }],
      }),
    }),
  });

export const {
  useGetOrderDevicesQuery,
  useLazyGetOrderDevicesQuery,
  useGetPrintersQuery,
  useLazyGetPrintersQuery,
  useUpdateOrderDeviceMutation,
} = slice;
