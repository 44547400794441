import './assets/styles.sass';
import { useAppStateLoadErors } from '../../../features/appState';
import logger from '../../logger/AppLogger';

function StateLoadError() {
  const appStateErrors = useAppStateLoadErors();
  logger.error(appStateErrors);
  return (
    <div className="x-pos-error-body">
      <div className="x-pos-error-container">
        <div className="x-pos-error-box">
          <h2>Something has gone wrong</h2>
          <p>Loading data for the following failed:</p>
          <ul>
            {appStateErrors.map((a) => (
              <li key={a}>{a}</li>
            ))}
          </ul>
        </div>
        <button type="button" className="reload-button" onClick={() => window.location.reload()}>
          Restart Till
        </button>
      </div>
    </div>
  );
}

export default StateLoadError;
