import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import { appSubscriptionService } from '../../../../common/subscriptions/appSubscriptionService';
import { useGetWasteReasonsQuery } from '../../../api';
import { setQuantity, useMenuItems } from '../../orderSlice';
import './waste-styles.sass';

interface TransactionItemAdd {
  menuItemId: number;
  unitOfSaleId: number;
  quantity: number;
  transactionId: number;
  condiments: { menuItemId: number; unitOfSaleId: number; condimentId: number }[];
  addOns: { menuItemId: number }[];
  takeOffs: { menuItemId: number }[];
}
export default function WasteReason() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const addItem: TransactionItemAdd = state as TransactionItemAdd;
  const menuItems = useMenuItems();

  const menuItem = menuItems.find((p) => p.id === addItem.menuItemId);

  const { data: wasteReasons } = useGetWasteReasonsQuery();

  const onItemClick = (wasteReasonId: number) => {
    appSubscriptionService.addToBasketNext({
      ...addItem,
      wasteReasonId: wasteReasonId,
    });
    dispatch(setQuantity(1));
    navigate('../');
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Waste Reason</h2>
                <p className="pos-sign-in__subtitle">
                  {menuItem?.name} x{addItem.quantity}
                </p>
              </div>
              <div className="waste-edit">
                <div className="grid">
                  {wasteReasons &&
                    wasteReasons.map((wasteReason) => (
                      <button key={wasteReason.id} onClick={() => onItemClick(wasteReason.id)}>
                        {wasteReason.name}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
