import { baseApi } from '../baseApi';

import { EngineInformation } from './Engine';

const engineSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Engine'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEngineInformation: builder.query<EngineInformation, void>({
        query: () => ({
          url: '/engine',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: ['Engine'],
      }),
      startup: builder.query<void, void>({
        query: () => ({
          url: '/engine/workstationStartup',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      diagnostics: builder.mutation<void, void>({
        query: () => ({
          url: '/engine/Diagnostics',
          method: 'POST',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
    }),
  });

export const {
  useGetEngineInformationQuery,
  useLazyGetEngineInformationQuery,
  useLazyStartupQuery,
  useStartupQuery,
  useDiagnosticsMutation,
} = engineSlice;
