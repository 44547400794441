import { baseApi } from '../baseApi';

import { LayoutDefinitionVersion } from './LayoutDefinition';

const layoutDefinitionSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['LayoutDefinitions'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLayoutDefinitions: builder.query<LayoutDefinitionVersion, void>({
        keepUnusedDataFor: 60,
        query: () => ({
          url: '/layoutDefinitions/v2',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.layoutDefinitions.map(({ id }) => ({
                  type: 'LayoutDefinitions' as const,
                  id,
                })),
                { type: 'LayoutDefinitions', id: 'LIST' },
              ]
            : [{ type: 'LayoutDefinitions', id: 'LIST' }],
      }),
    }),
  });

export const { useGetLayoutDefinitionsQuery, useLazyGetLayoutDefinitionsQuery } = layoutDefinitionSlice;
