import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import Price from '../../../common/components/price/Price';
import { usePrintTransactionMutation, useStoreTransactionMutation } from '../../api';
import { useOperatorSession, useWorkstation } from '../../appState';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import {
  clearOrderState,
  setQuantity,
  setQuantityShow,
  useActiveTransactionLineItem,
  useAddToTransactionInProgress,
  useQuantity,
  useQuantityShown,
  useTransaction,
} from '../orderSlice';

import TransactionPanel from './transaction-panel/TransactionPanel';

function OrderSection() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workstation = useWorkstation();
  const quantity = useQuantity();
  const operatorSession = useOperatorSession();
  const activeLineItem = useActiveTransactionLineItem();
  const showQuantity = useQuantityShown();
  const transaction = useTransaction();
  const [triggerPrintBill] = usePrintTransactionMutation();
  const [triggerStore] = useStoreTransactionMutation();
  const useIsAdding = useAddToTransactionInProgress();

  const [showFullBasket, setShowFullBasket] = useState(false);

  const updateQuantity = (numS: string) => {
    if (showQuantity === false) {
      dispatch(setQuantityShow(true));
      if (numS !== '1') dispatch(setQuantity(parseInt(numS)));
    } else dispatch(setQuantity(parseInt(`${quantity}${numS}`)));
  };

  const printBill = () => {
    if (operatorSession && transaction.length === 1) {
      triggerPrintBill({
        operatorId: operatorSession.operator.id,
        transactionId: transaction[0].id,
      });
    }
  };

  const storeTable = () => {
    if (transaction.length > 0) {
      if (operatorSession) {
        if (transaction[0].tableId) {
          triggerStore({
            tableId: transaction[0].tableId,
            transactionId: transaction[0].id,
            covers: transaction[0].covers,
            operatorId: operatorSession.operator.id,
            transactionName: transaction[0].transactionName,
          })
            .unwrap()
            .then(() => {
              dispatch(clearOrderState());
            })
            .catch((err) => dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true })));
        } else navigate('/tables/store');
      }
    }
  };

  return (
    <div className="pos-main__order">
      <TransactionPanel showFullHeight={showFullBasket} onTotalClicked={() => setShowFullBasket(!showFullBasket)} />
      {showFullBasket === false && (
        <div className="pos-main__controls">
          <div className="pos-main__numpad">
            <div className="pos-numpad pos-numpad_role_main">
              <div className="pos-numpad__controls">
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('1')}>
                    1
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('2')}>
                    2
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('3')}>
                    3
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('4')}>
                    4
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('5')}>
                    5
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('6')}>
                    6
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('7')}>
                    7
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('8')}>
                    8
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('9')}>
                    9
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => updateQuantity('0')}>
                    0
                  </button>
                  <button
                    className="pos-numpad__control pos-numpad__control_role_clear"
                    type="button"
                    onClick={() => {
                      dispatch(setQuantity(1));
                      dispatch(setQuantityShow(false));
                    }}
                  >
                    CLR
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-main__actions">
            {workstation && workstation.hideTables === false && workstation.isAppTill === false && (
              <button className="pos-main__action" type="button" onClick={storeTable} disabled={transaction[0]?.isWaste === true}>
                Store Table
              </button>
            )}
            <button
              disabled={
                (transaction.length === 1 && transaction[0].totalDue < transaction[0].totalValue) ||
                workstation?.isAppTill ||
                (transaction.length === 1 && transaction[0].transactionLineItems.length == 0 && transaction[0].bookingId == null)
              }
              className="pos-main__action"
              type="button"
              onClick={() => navigate('./void-logon')}
            >
              Void
            </button>
            <button
              className="pos-main__action"
              disabled={activeLineItem === undefined}
              type="button"
              onClick={() => navigate(`./message/${activeLineItem?.id}`)}
            >
              Message
            </button>
            {workstation && workstation.hasReceiptPrinter === true && workstation.isAppTill === false && (
              <button className="pos-main__action" type="button" onClick={printBill}>
                Print Bill
              </button>
            )}
          </div>
        </div>
      )}
      <button
        className="pos-main__subtotal"
        type="button"
        onClick={() => navigate('/tender')}
        disabled={
          transaction.length === 0 ||
          transaction[0].transactionLineItems.length === 0 ||
          useIsAdding ||
          (workstation?.requireTableToCompleteTransaction === true && transaction[0].tableId == null)
        }
      >
        {useIsAdding ? (
          'Adding..'
        ) : transaction.length === 1 && transaction[0].totalDue < transaction[0].totalValue ? (
          <span>
            Due <Price price={transaction[0].totalDue} />
          </span>
        ) : (
          <span>Sub Total</span>
        )}
      </button>
    </div>
  );
}
export default OrderSection;
