function LoadingModal() {
  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay">
        <div className="pos-loader pos-loader_type_light">
          <div className="pos-loader__spinner"></div>
          <p className="pos-loader__label">Loading...</p>
        </div>
      </div>
    </div>
  );
}

export default LoadingModal;
