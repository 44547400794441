import axios, { AxiosError } from 'axios';
import { encode } from 'base64-arraybuffer';

import appLogger from '../../common/logger/AppLogger';
import { IConfig } from '../config';

import { OauthConfiguration } from './auth.interfaces';
import { AuthError, AuthErrorCode } from './AuthError';

const sessionStorageStateKey = 'oauth-state';
const sessionStorageCodeVerifierKey = 'oauth-code';

export function generateRandomString(length: number) {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  const array2 = array.map((x) => validChars.codePointAt(x % validChars.length) as number);
  const randomState = String.fromCharCode.apply(null, array2 as unknown as number[]);
  return randomState;
}

export async function hashCodeVerifier(codeVerifier: string) {
  const data = new TextEncoder().encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = encode(digest);
  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export function saveState(state: string) {
  sessionStorage.setItem(sessionStorageStateKey, state);
}

export function removeState() {
  sessionStorage.removeItem(sessionStorageStateKey);
}

export function saveCodeVerifier(codeChallenge: string) {
  sessionStorage.setItem(sessionStorageCodeVerifierKey, codeChallenge);
}

export function removeCodeVerifier() {
  sessionStorage.removeItem(sessionStorageCodeVerifierKey);
}

export function checkState(receivedState: string) {
  const state = sessionStorage.getItem(sessionStorageStateKey);
  return state === receivedState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const objectToQuery = (object: any) => {
  return new URLSearchParams(object).toString();
};

export function queryToObject(query: string) {
  const parameters = new URLSearchParams(query);
  return Object.fromEntries(parameters.entries());
}

export async function deviceCode({ deviceAuthUrl, clientId, scope }: OauthConfiguration) {
  const formBody = {
    client_id: clientId,
    scope: scope,
  };

  const formParams = objectToQuery(formBody);
  try {
    const response = await axios.post(deviceAuthUrl, formParams);
    return response.data;
  } catch (err) {
    appLogger.error('errored getting token ', JSON.stringify(err));
    return;
  }
}

export async function getPosRegistrationCode({ api }: IConfig) {
  const state = generateRandomString(26);

  try {
    const response = await axios.post(`${api.baseUrl}/registration?state=${state}`);
    return {
      code: response.data.code,
      state: state,
    };
  } catch (err) {
    appLogger.error('errored getting pos registration code', JSON.stringify(err));
    return;
  }
}

export async function checkPosRegistration({ api }: IConfig, code: string, state: string) {
  const url = `${api.baseUrl}/registration/${code}?state=${state}`;
  try {
    const response = await axios.get(url);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    appLogger.warn('errored getting pos registration code', JSON.stringify(err));
    if (err instanceof AxiosError) {
      if (err.response?.status === 404) return { invalid: true };
      if (err.response?.status === 423) return { invalid: false };
      return;
    }
  }
}

export async function checkTokenAvailable({ tokenUrl, clientId }: OauthConfiguration, code: string) {
  const formBody = {
    grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
    client_id: clientId,
    device_code: code,
  };

  const formParams = objectToQuery(formBody);
  const response = await axios.post(tokenUrl, formParams, {
    validateStatus: () => true,
  });
  return response.data;
}

export async function getAccessTokenWithUserPassword({ tokenUrl, clientId }: OauthConfiguration, username: string, password: string) {
  const formBody = {
    grant_type: 'password',
    client_id: clientId,
    username: username,
    password: password,
  };

  const formParams = objectToQuery(formBody);
  const response = await axios.post(tokenUrl, formParams, {
    validateStatus: () => true,
  });
  return response.data;
}
export async function refreshToken({ tokenUrl, clientId }: OauthConfiguration, refreshToken: string) {
  const formBody = {
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
  };

  const formParams = objectToQuery(formBody);
  try {
    const response = await axios.post(tokenUrl, formParams);
    return response.data;
  } catch (err) {
    localStorage.setItem('refresh.token.error', JSON.stringify(err));
    appLogger.error('errored getting token ', JSON.stringify(err));
    if (err instanceof AxiosError) if (err.code === 'ERR_NETWORK') throw new AuthError(JSON.stringify(err), AuthErrorCode.NetworkError);
    return undefined;
  }
}
