import { useAppDispatch } from '../../../../app/redux/hooks';
import { LayoutDefinition } from '../../../api';
import { setActiveLayoutDefinition, setMenuPage, useMenuItemCategories } from '../../orderSlice';

import './category-fastbar.sass';

export function FastBarCategoryButton({ layoutDefinition }: { layoutDefinition: LayoutDefinition }) {
  const dispatch = useAppDispatch();
  const categories = useMenuItemCategories();
  const category = categories.find((x) => x.id === layoutDefinition.menuItemCategoryId);

  if (category === undefined) return <div className="pos-menu__item hidden-button"></div>;
  const categoryClick = (layoutDefinition: LayoutDefinition) => {
    dispatch(setActiveLayoutDefinition(layoutDefinition));
    dispatch(setMenuPage(1));
  };

  return (
    <div className={`pos-menu__item category-button-fastbar`} onClick={() => categoryClick(layoutDefinition)}>
      <p className="pos-menu__category-text">{category.name}</p>
    </div>
  );
}
