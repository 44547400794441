import { PayloadAction, createSlice, Draft } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import { Transaction } from '../api';

export interface SalesReviewState {
  selectedTransaction?: Transaction | undefined;
}

const initialState: SalesReviewState = {};

const salesReviewSlice = createSlice({
  name: 'salesReview',
  initialState,
  reducers: {
    setSelectedTransaction: (state: Draft<SalesReviewState>, { payload }: PayloadAction<Transaction | undefined>) => {
      state.selectedTransaction = payload;
    },
  },
});

export const { setSelectedTransaction } = salesReviewSlice.actions;
export default salesReviewSlice.reducer;
const selectSelectedTransaction = (state: RootState) => state.salesReview.selectedTransaction;

export const useSelectedTransaction = () => useAppSelector(selectSelectedTransaction);
