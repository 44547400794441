import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Operator, OperatorLevel, useLazySearchOperatorsQuery } from '../../api';

export function SignOnSearch() {
  const navigate = useNavigate();
  const [triggerOperatorSearch, { data: operatorsFound }] = useLazySearchOperatorsQuery();

  const [operatorSearchData, setOperators] = useState<Operator[]>([]);

  const [searchText, setSearchText] = useState('');
  const [resultOffset, setResultOffset] = useState(0);
  const keyPress = (key: string) => setSearchText(searchText + key);
  const backSpace = () => setSearchText(searchText.length > 1 ? searchText.substring(0, searchText.length - 1) : '');
  const resultNextPage = () => {
    if (operatorSearchData && resultOffset < operatorSearchData.length - 3) setResultOffset(resultOffset + 3);
  };
  const resultPreviousPage = () => {
    if (operatorSearchData && resultOffset > 3 && operatorSearchData.length > 3) setResultOffset(resultOffset - 3);
    else setResultOffset(0);
  };

  useEffect(() => {
    setResultOffset(0);
    if (operatorsFound)
      setOperators(operatorsFound.filter((x) => x.operatorLevel == OperatorLevel.Manager || x.operatorLevel == OperatorLevel.Admin));
    else setOperators([]);
  }, [operatorsFound]);

  const onOperatorSelected = (operator: Operator) => {
    navigate('./pin', { state: operator });
  };

  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Manager Validation</h2>
          <p className="pos-sign-in__subtitle">Please type and select your name below:</p>
          <p className="pos-sign-in__note">*** you must be clocked in this venue***</p>
        </div>
        <div className="pos-sign-in__info">
          <div className="pos-sign-in__servers">
            <button
              className={`pos-sign-in__servers-control pos-sign-in__servers-control_role_previous js-sign-in-servers-previous ${
                resultOffset === 0 ? 'is-disabled' : ''
              }`}
              type="button"
              onClick={resultPreviousPage}
            >
              Previous
            </button>
            <div className="pos-sign-in__servers-list">
              <div className="pos-sign-in__servers-scroll js-sign-in-servers-scroll">
                <div className="pos-sign-in__servers-row">
                  {operatorSearchData &&
                    operatorSearchData.slice(resultOffset, resultOffset + 3).map((operator) => (
                      <div key={operator.id} className="pos-sign-in__server" onClick={() => onOperatorSelected(operator)}>
                        <p className="pos-sign-in__server-text">
                          {operator.firstName} {operator.lastName}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <button
              className={`pos-sign-in__servers-control pos-sign-in__servers-control_role_next js-sign-in-servers-next ${
                operatorSearchData ? (resultOffset < operatorSearchData.length - 3 ? ' ' : 'is-disabled') : 'is-disabled'
              }`}
              type="button"
              onClick={resultNextPage}
            >
              Next
            </button>
          </div>
        </div>
        <div className="pos-sign-in__controls">
          <div className="pos-sign-in__search">
            <div className="pos-field pos-field_role_search">
              <div className="pos-field__group">
                <div className="pos-field__box">{searchText}</div>
                <button className="pos-field__action" onClick={() => triggerOperatorSearch(searchText)}>
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="pos-sign-in__keyboard">
            <div className="pos-keyboard pos-keyboard_role_sign-in">
              <div className="pos-keyboard__controls">
                <div className="pos-keyboard__controls-row">
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Q')}>
                    q
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('W')}>
                    w
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('E')}>
                    e
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('R')}>
                    r
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('T')}>
                    t
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Y')}>
                    y
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('U')}>
                    u
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('I')}>
                    i
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('O')}>
                    o
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('P')}>
                    p
                  </button>
                </div>
                <div className="pos-keyboard__controls-row">
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('A')}>
                    a
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('S')}>
                    s
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('D')}>
                    d
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('F')}>
                    f
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('G')}>
                    g
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('H')}>
                    h
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('J')}>
                    j
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('K')}>
                    k
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('L')}>
                    l
                  </button>
                </div>
                <div className="pos-keyboard__controls-row">
                  <button className="pos-keyboard__control pos-keyboard__control_role_shift" type="button">
                    Shift
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('Z')}>
                    z
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('X')}>
                    x
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('C')}>
                    c
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('V')}>
                    v
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('B')}>
                    b
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('N')}>
                    n
                  </button>
                  <button className="pos-keyboard__control" type="button" onClick={() => keyPress('M')}>
                    m
                  </button>
                  <button className="pos-keyboard__control pos-keyboard__control_role_backspace" type="button" onClick={backSpace}>
                    Backspace
                  </button>
                </div>
                <div className="pos-keyboard__controls-row">
                  <button className="pos-keyboard__control pos-keyboard__control_role_space" type="button" onClick={() => keyPress(' ')}>
                    Space
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
