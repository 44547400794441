import { baseApi } from '../baseApi';

import { Operator, OperatorSearchResult } from './Operator';
import {
  OperatorAssignTokenRequest,
  OperatorChangeFobRequest,
  OperatorCreateRequest,
  OperatorForceOffRequest,
  OperatorSignOffRequest,
  OperatorSignOnRequest,
  OperatorStartOverrideRequest,
  OperatorStartShiftRequest,
  OperatorValidatePinRequest,
} from './OperatorModels';
import { OperatorSession } from './OperatorSession';

const operatorSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Operators'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      /**
       * Get the Operator that is signed on the till
       */
      getOperators: builder.query<OperatorSession, void>({
        query: () => ({
          url: `/Operators`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        keepUnusedDataFor: 5,
      }),
      /**
       * Start of Shift for a member of staff. Assign their Dallas fob to their Operator for today's session if a till, or to logon to a tablet.
       */
      operatorStartShift: builder.mutation<OperatorSession, OperatorStartShiftRequest>({
        query: (request: OperatorStartShiftRequest) => ({
          url: '/Operators/Start',
          method: 'POST',
          body: request,
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Sign on to a Workstation, validates that not still signed on another Workstation
       */
      operatorSignOn: builder.mutation<OperatorSession, OperatorSignOnRequest>({
        query: (request: OperatorSignOnRequest) => ({
          url: `/Operators/SignOn`,
          method: 'POST',
          body: request,
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Assign a Token to the Operator Session, eg Dallas Fob
       */
      operatorAssignToken: builder.mutation<OperatorSession, OperatorAssignTokenRequest>({
        query: (request: OperatorAssignTokenRequest) => ({
          url: `/Operators/AssignToken/${request.operatorId}`,
          method: 'POST',
          body: request.payload,
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Sign Off Workstation and allow to use another
       */
      operatorSignOff: builder.mutation<void, OperatorSignOffRequest>({
        query: (request: OperatorSignOffRequest) => ({
          url: `/Operators/SignOff/${request.operatorId}?stopSession=${request.stop !== undefined ? request.stop : false}`,
          method: 'POST',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * For Manager/Supervisors, they can get a list of Operators on the Site that are not signed on today
       */
      getOperatorsSignedOff: builder.query<Operator[], number>({
        query: (operatorId: number) => ({
          url: `/Operators/OperatorsSignedOff?operatorId=${operatorId}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * For Manager/Supervisors, they can get a list of Operators on the Site.
       */
      getOperatorsActive: builder.query<Operator[], void>({
        query: () => ({
          url: `/Operators/ActiveOperators`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * A Manager/Supervisor can manually Start an Operator session
       */
      operatorStartOverride: builder.mutation<Operator, OperatorStartOverrideRequest>({
        query: (request: OperatorStartOverrideRequest) => ({
          url: `/Operators/StartOverride?loggedInOperatorId=${request.loggedInOperatorId}`,
          method: 'POST',
          body: request.payload,
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Manually Make an Operator if not in BOSS
       */
      createOperator: builder.mutation<Operator, OperatorCreateRequest>({
        query: (request: OperatorCreateRequest) => ({
          url: `/Operators/Create?operatorId=${request.operatorId}`,
          method: 'POST',
          body: request.payload,
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Search Operators
       */
      searchOperators: builder.query<OperatorSearchResult[], string>({
        query: (text: string) => ({
          url: `/Operators/Search?text=${text}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * Search Operators
       */
      getActiveOperators: builder.query<OperatorSession[], void>({
        query: () => ({
          url: `/Operators/ActiveOperators`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * Validate Pin
       */
      operatorValidatePin: builder.mutation<{ valid: boolean }, OperatorValidatePinRequest>({
        query: (request: OperatorValidatePinRequest) => ({
          url: `/Operators/ValidatePin?operatorId=${request.operatorId}&pinCode=${request.pinCode}`,
          method: 'POST',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: [{ type: 'Operators' }],
      }),
      /**
       * Returns True/False for fob in use
       */
      operatorCheckFobUse: builder.query<boolean, string>({
        query: (code: string) => ({
          url: `/Operators/CheckFobUse?code=${code}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * Returns True/False for fob in use
       */
      operatorSessionGetByFob: builder.query<OperatorSession, string>({
        query: (fob: string) => ({
          url: `/Operators/GetSessionByFob/${fob}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      /**
       * End Session
       */
      operatorEndSession: builder.mutation<void, number>({
        query: (id: number) => ({
          url: `/Operators/EndSession?id=${id}`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        invalidatesTags: ['Operators'],
      }),
      /**
       * Force Off
       */
      forceOperatorOff: builder.mutation<void, OperatorForceOffRequest>({
        query: (model: OperatorForceOffRequest) => ({
          url: `/Operators/ForceOff/${model.operatorId}?managerOperatorId=${model.managerOperatorId}`,
          method: 'POST',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
      changeFob: builder.mutation<void, OperatorChangeFobRequest>({
        query: (model: OperatorChangeFobRequest) => ({
          url: `/Operators/ChangeFob/${model.operatorSessionId}?managerId=${model.managerId}&token=${model.token}`,
          method: 'POST',
          headers: {
            'x-api-version': '1.0',
          },
        }),
      }),
    }),
  });

export const {
  useGetOperatorsQuery,
  useLazyGetOperatorsQuery,
  useCreateOperatorMutation,
  useGetOperatorsActiveQuery,
  useGetOperatorsSignedOffQuery,
  useLazyGetOperatorsActiveQuery,
  useLazyGetOperatorsSignedOffQuery,
  useLazyOperatorCheckFobUseQuery,
  useLazySearchOperatorsQuery,
  useOperatorAssignTokenMutation,
  useOperatorCheckFobUseQuery,
  useOperatorSignOffMutation,
  useOperatorSignOnMutation,
  useOperatorStartOverrideMutation,
  useOperatorStartShiftMutation,
  useOperatorValidatePinMutation,
  useSearchOperatorsQuery,
  useOperatorEndSessionMutation,
  useGetActiveOperatorsQuery,
  useForceOperatorOffMutation,
  useLazyGetActiveOperatorsQuery,
  useOperatorSessionGetByFobQuery,
  useLazyOperatorSessionGetByFobQuery,
  useChangeFobMutation,
} = operatorSlice;
