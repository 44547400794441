import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/redux/hooks';
import AppLogger from '../../../../common/logger/AppLogger';
import { useGetOrderDevicesQuery, useGetPrintersQuery, useUpdateOrderDeviceMutation } from '../../../api';
import { showErrorDialog } from '../../../error-dialog/errorDialogSlice';

export default function WorkstationOrderDeviceOverride() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const wodId = Number(params.id ?? '0');

  const { data: orderDevices } = useGetOrderDevicesQuery();
  const { data: printers } = useGetPrintersQuery();

  const [triggerUpdate] = useUpdateOrderDeviceMutation();

  const orderDevice = orderDevices?.find((od) => od.id === wodId);

  if (orderDevice == null) return <div></div>;

  const onClick = (printerId: number | null) => {
    // Do something
    const newWod = { ...orderDevice, overridePrinterId: printerId };
    triggerUpdate(newWod)
      .unwrap()
      .then(() => {
        navigate('/order/workstation-order-devices');
      })
      .catch((error) => {
        AppLogger.error('Failed to update', JSON.stringify(error));
        dispatch(
          showErrorDialog({
            message: 'Failed to update',
            dismissible: true,
            buttonText: 'OK',
          }),
        );
      });
  };

  return (
    <div className="pos-modal-portal">
      <div
        className="pos-modal-overlay"
        onClick={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e.target as any).className === 'pos-modal-overlay') navigate('/order');
        }}
      >
        <div className="pos-modal pos-modal_role_servers-overview">
          <div className="pos-modal__content">
            <div className="pos-servers-overview">
              <div className="pos-servers-overview__header">
                <h2 className="pos-servers-overview__title">Order Devices</h2>
                <p className="pos-sign-in__subtitle">{orderDevice.orderDeviceName}</p>
              </div>
              <div className="wod-edit">
                <div className="grid">
                  <button className="default" onClick={() => onClick(null)}>
                    Default Printer
                  </button>
                  {printers &&
                    printers
                      .filter((p) => p.id !== orderDevice.defaultPrinterId)
                      .map((printer) => (
                        <button key={printer.id} onClick={() => onClick(printer.id)}>
                          Override to {printer.name}
                        </button>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
