import { baseApi } from '../baseApi';

import { MenuItem } from './MenuItem';
import { MenuItemCategory } from './MenuItemCategory';
import { MenuItemCondiment } from './MenuItemCondiment';
import { MenuVersion } from './MenuVersion';
import { UnitOfSale } from './UnitOfSale';

const menuSlice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['MenuItems', 'MenuItemCategories', 'UnitOfSales', 'MenuItemCondiments', 'MenuVersion'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMenuItems: builder.query<MenuItem[], void>({
        keepUnusedDataFor: 300,
        query: () => ({
          url: '/MenuItems',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'MenuItems' as const,
                  id,
                })),
                { type: 'MenuItems', id: 'LIST' },
              ]
            : [{ type: 'MenuItems', id: 'LIST' }],
      }),
      getMenuCurrentVersion: builder.query<MenuVersion, void>({
        keepUnusedDataFor: 300,
        query: () => ({
          url: '/MenuItems/CurrentVersion',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) => (result ? [{ type: 'MenuVersion', id: result.versionNumber }] : []),
      }),
      getMenuCategories: builder.query<MenuItemCategory[], void>({
        keepUnusedDataFor: 300,
        query: () => ({
          url: '/MenuItems/GetCategories',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'MenuItemCategories' as const,
                  id,
                })),
                { type: 'MenuItemCategories', id: 'LIST' },
              ]
            : [{ type: 'MenuItemCategories', id: 'LIST' }],
      }),
      getUnitOfSales: builder.query<UnitOfSale[], void>({
        keepUnusedDataFor: 300,
        query: () => ({
          url: '/MenuItems/GetUnitsOfSale',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'UnitOfSales' as const,
                  id,
                })),
                { type: 'UnitOfSales', id: 'LIST' },
              ]
            : [{ type: 'UnitOfSales', id: 'LIST' }],
      }),
      getCondiments: builder.query<MenuItemCondiment[], void>({
        keepUnusedDataFor: 300,
        query: () => ({
          url: '/MenuItems/Condiments',
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'MenuItemCondiments' as const,
                  id,
                })),
                { type: 'MenuItemCondiments', id: 'LIST' },
              ]
            : [{ type: 'MenuItemCondiments', id: 'LIST' }],
      }),
    }),
  });

export const {
  useLazyGetMenuItemsQuery,
  useGetMenuItemsQuery,
  useGetCondimentsQuery,
  useGetMenuCategoriesQuery,
  useGetUnitOfSalesQuery,
  useLazyGetCondimentsQuery,
  useLazyGetMenuCategoriesQuery,
  useLazyGetUnitOfSalesQuery,
  useGetMenuCurrentVersionQuery,
  useLazyGetMenuCurrentVersionQuery,
} = menuSlice;
