import { Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../../app/redux/hooks';
import { selectIsAuthenticated } from '../authSelectors';

type Props = {
  children: ReactNode;

  loginPath?: string | undefined;
};

function RequireAuthentication({ children, loginPath }: Props) {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const location = useLocation();
  const loginUrl = loginPath ? loginPath : '/login';

  return isAuthenticated === true ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Navigate to={loginUrl} replace state={{ returnUrl: location.pathname }}></Navigate>
  );
}

export { RequireAuthentication };
