import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/redux/hooks';
import logger from '../../../common/logger/AppLogger';
import { selectConfig, selectOauthConfig, selectVersion } from '../../config/configSelectors';
import './styles/styles.sass';
import { AuthenticationToken } from '../auth.interfaces';
import { selectIsAuthenticated } from '../authSelectors';
import { login } from '../authSlice';
import { checkPosRegistration, getAccessTokenWithUserPassword, getPosRegistrationCode } from '../oauth';

interface CodeInfo {
  code: string;
  state: string;
}

function RegistrationLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const oAuthConfig = useAppSelector(selectOauthConfig);
  const appConfig = useAppSelector(selectConfig);
  const version = useAppSelector(selectVersion);

  const [codeInfo, setCodeInfo] = useState<CodeInfo | undefined>(undefined);
  const [authToken, setAuthToken] = useState<AuthenticationToken | undefined>();
  const [credentials, setCredentials] = useState<{ username: string; password: string } | undefined>();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    if (appConfig) {
      if (codeInfo === undefined) {
        getPosRegistrationCode(appConfig).then((result) => {
          if (result) {
            setCodeInfo({
              code: result.code,
              state: result.state,
            });
          }
        });
      }
    }
  }, [appConfig, codeInfo]);

  useEffect(() => {
    if (codeInfo !== undefined && appConfig !== undefined && credentials === undefined) {
      const timer = setInterval(() => {
        checkPosRegistration(appConfig, codeInfo.code, codeInfo.state).then((data) => {
          if (data.invalid === false) logger.warn('Token not yet validated by user');
          if (data.invalid === true) setCodeInfo(undefined);
          else if (data.username && data.password) setCredentials({ username: data.username, password: data.password });
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [codeInfo, appConfig, credentials]);

  useEffect(() => {
    if (credentials && oAuthConfig) {
      getAccessTokenWithUserPassword(oAuthConfig, credentials.username, credentials.password)
        .then((result) => {
          setAuthToken(result);
          dispatch(login(result));
        })
        .catch((err) => {
          logger.error('Failed to auth', JSON.stringify(err));
        });
    }
  }, [credentials, dispatch, oAuthConfig]);

  useEffect(() => {
    if (authToken !== undefined && isAuthenticated === true) navigate('/');
  }, [authToken, isAuthenticated, navigate]);

  if (appConfig === undefined) return <div>No App Configuration Found</div>;
  return (
    <div className="x-pos-auth-body">
      <div className="x-pos-auth-container">
        <div className="x-pos-auth-box">
          <h1>Enroll Workstation</h1>
          <p>Please use the below code to register the Workstation in Back Office</p>
          {codeInfo && authToken === undefined && <QRCode value={codeInfo.code} />}
          {authToken && <div>Authenticated, loading info</div>}
          <p>{codeInfo?.code}</p>
          <p>Version {version?.version}</p>
        </div>
      </div>
    </div>
  );
}
export { RegistrationLogin };
