import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';

export interface ModalDialogMessage {
  title: string;
  message: string;
  buttonText?: string;
  width?: string;
  height?: string;
}

export interface ModalDialogState {
  modal?: ModalDialogMessage | undefined;
}

const initialState: ModalDialogState = {};

const modalDialogSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModalDialog: (state: Draft<ModalDialogState>, { payload }: PayloadAction<ModalDialogMessage>) => {
      state.modal = payload;
    },
    clearModalDialog: (state) => {
      state.modal = undefined;
    },
  },
});

export const reducerName = modalDialogSlice.name;
export const { showModalDialog, clearModalDialog } = modalDialogSlice.actions;

export default modalDialogSlice.reducer;

const selectModalDialogMessage = (state: RootState) => state.modal.modal;

export const useModalDialogMessage = () => useAppSelector(selectModalDialogMessage);
