import Moment from 'react-moment';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { FreeMode, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppDispatch } from '../../app/redux/hooks';
import Price from '../../common/components/price/Price';
import logger from '../../common/logger/AppLogger';
import { PaymentMethodType, TenderMediaType, useGetTenderMediasQuery, usePrintTransactionMutation } from '../api';
import { useOperatorSession } from '../appState';

import { setSelectedTransaction, useSelectedTransaction } from './salesReviewSlice';

function SalesReviewDetails() {
  const primaryTransaction = useSelectedTransaction();
  const operatorSession = useOperatorSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [triggerPrint] = usePrintTransactionMutation();

  const { data: tenderMedias } = useGetTenderMediasQuery();

  const print = () => {
    if (operatorSession !== undefined && primaryTransaction !== undefined) {
      triggerPrint({
        operatorId: operatorSession.operator.id,
        transactionId: primaryTransaction.id,
      });
    }
  };
  const cashTender = tenderMedias?.find((x) => x.paymentMethodType === PaymentMethodType.Cash);
  const cardTenders = tenderMedias?.filter((x) => x.paymentMethodType === PaymentMethodType.Card);
  const loyaltyTenders = tenderMedias?.filter((x) => x.name.toLowerCase().indexOf('loyalty') > -1);
  const depositTender = tenderMedias?.find((x) => x.tenderMediaType === TenderMediaType.Discount);

  logger.info(primaryTransaction);
  const doneClick = () => {
    navigate('../');
    dispatch(setSelectedTransaction(undefined));
  };

  if (primaryTransaction === undefined) return <Navigate to="../" />;
  logger.info(primaryTransaction);

  const refund = () => {
    navigate('./refund', { state: primaryTransaction });
  };

  return (
    <>
      <div className="pos-modal-portal">
        <div className="pos-modal-overlay">
          <div className="pos-modal pos-modal_role_sale">
            <div className="pos-modal__content">
              <div className="pos-sale">
                <div className="pos-sale__group">
                  <div className="pos-sale__order">
                    <div className="pos-sale__invoice">
                      <div className="pos-invoice pos-invoice_role_main js-invoice">
                        <div className="pos-invoice__panel">
                          <div className="pos-invoice__panel-content">
                            <div className="pos-invoice__list">
                              <div className="pos-invoice__list-labels">
                                <p className="pos-invoice__list-label">Product</p>
                                <p className="pos-invoice__list-label">Qty</p>
                                <p className="pos-invoice__list-label">Each</p>
                                <p className="pos-invoice__list-label">Total</p>
                              </div>
                              <div className="pos-invoice__list-scroll js-invoice-scroll">
                                <Swiper
                                  freeMode={true}
                                  height={300}
                                  slidesPerView="auto"
                                  spaceBetween={30}
                                  mousewheel={true}
                                  scrollbar={true}
                                  direction="vertical"
                                  pagination={{ clickable: true }}
                                  modules={[FreeMode, Pagination]}
                                >
                                  <SwiperSlide>
                                    <div className="pos-invoice__items">
                                      {primaryTransaction.transactionLineItems.map((lineItem) => (
                                        <div
                                          key={lineItem.id}
                                          className={`pos-invoice__item ${lineItem.voidReasonId ? 'line-item-vodied' : ''}`}
                                        >
                                          <div className="pos-invoice__item-info">
                                            <p className="pos-invoice__item-text">
                                              {lineItem.voidReasonId ? '(V) ' : ''}
                                              {lineItem.description}
                                            </p>
                                            <div className="pos-invoice__item-meta">
                                              {lineItem.childTransactionLineItems.map((childLineItem) => (
                                                <div key={childLineItem.id} className="pos-invoice__item-meta-group">
                                                  <p className="pos-invoice__item-meta-text">
                                                    {childLineItem.menuItemId > 0 && (
                                                      <>
                                                        {childLineItem.description} <Price price={childLineItem.itemPrice} />
                                                      </>
                                                    )}
                                                    {childLineItem.menuItemId === 0 && <i>{childLineItem.description}</i>}
                                                  </p>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                          <div className="pos-invoice__item-qty">
                                            <p className="pos-invoice__item-text">{lineItem.quantity}</p>
                                          </div>
                                          <div className="pos-invoice__item-price">
                                            <p className="pos-invoice__item-text">
                                              <Price price={lineItem.itemPrice} />
                                            </p>
                                          </div>
                                          <div className="pos-invoice__item-total">
                                            <p className="pos-invoice__item-text">
                                              <Price price={lineItem.totalPrice} />
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                      {primaryTransaction.transactionAppliedDeals.map((deal) => (
                                        <div key={deal.id} className={`pos-invoice__item pos-invoice__item-deal`}>
                                          <div className="pos-invoice__item-info">
                                            <p className="pos-invoice__item-text">{deal.dealName}</p>
                                          </div>
                                          <div className="pos-invoice__item-qty"></div>
                                          <div className="pos-invoice__item-price">
                                            <p className="pos-invoice__item-text"></p>
                                          </div>
                                          <div className="pos-invoice__item-total">
                                            <p className="pos-invoice__item-text">
                                              <Price price={-deal.amount} />
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </SwiperSlide>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                          <div className="pos-invoice__panel-footer">
                            {primaryTransaction.bookingId !== null && (
                              <div className="pos-invoice__panel-deposit">
                                <div className="pos-invoice__panel-deposit-info">
                                  <p className="pos-invoice__panel-deposit-label">Prepaid Deposit</p>
                                  <p className="pos-invoice__panel-deposit-name">{primaryTransaction.bookingName}</p>
                                </div>
                                <p className="pos-invoice__panel-deposit-value">
                                  -<Price price={primaryTransaction.bookingAmount ? primaryTransaction.bookingAmount : 0} />
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pos-invoice__info">
                          {primaryTransaction.tableNumber && <p className="pos-invoice__table-number">{primaryTransaction.tableNumber}</p>}
                          <p className="pos-invoice__total">
                            <span className="pos-invoice__total-label">Total</span>
                            <span className="pos-invoice__total-value">
                              <Price price={primaryTransaction.totalValue} />
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="pos-sale__actions">
                      <button className="pos-sale__action pos-sale__action_role_print" type="button" onClick={print}>
                        Print
                      </button>
                      {primaryTransaction.transactionPayments.filter(
                        (x) =>
                          cardTenders !== undefined &&
                          cardTenders.length > 0 &&
                          cardTenders.find((y) => y.id == x.tenderMediaId) !== undefined,
                      ).length > 0 && (
                        <button className="pos-sale__action pos-sale__action_role_refund" type="button" onClick={refund}>
                          Refund
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="pos-sale__tender">
                    <div className="pos-sale__data">
                      <div className="pos-data pos-data_role_sale">
                        <div className="pos-data__list">
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Date</p>
                            <p className="pos-data__item-value">
                              <Moment format="DD/MM/yy" date={primaryTransaction.transactionStartDateTime} />
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Time</p>
                            <p className="pos-data__item-value">
                              <Moment format="HH:mm" date={primaryTransaction.transactionStartDateTime} />
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Operator</p>
                            <p className="pos-data__item-value">{primaryTransaction.operatorName}</p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Terminal</p>
                            <p className="pos-data__item-value">{primaryTransaction.terminalNumber}</p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Booking ID</p>
                            <p className="pos-data__item-value">{primaryTransaction.bookingId ? primaryTransaction.bookingId : 'N/A'}</p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Loyalty</p>
                            <p className="pos-data__item-value">
                              <Price
                                price={primaryTransaction.transactionPayments
                                  .filter(
                                    (x) =>
                                      loyaltyTenders !== undefined &&
                                      loyaltyTenders.length > 0 &&
                                      loyaltyTenders.find((y) => y.id == x.tenderMediaId) !== undefined,
                                  )
                                  .reduce((a, b) => a + b.tendered, 0)}
                              />
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Discount</p>
                            <p className="pos-data__item-value">
                              <Price price={0} />
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Cash</p>
                            <p className="pos-data__item-value">
                              <Price
                                price={primaryTransaction.transactionPayments
                                  .filter((x) => cashTender !== undefined && cashTender.id === x.tenderMediaId)
                                  .reduce((a, b) => a + b.tendered, 0)}
                              />{' '}
                              <small>
                                Chg{' '}
                                <Price
                                  price={primaryTransaction.transactionPayments
                                    .filter((x) => cashTender !== undefined && cashTender.id === x.tenderMediaId)
                                    .reduce((a, b) => a + b.change, 0)}
                                />
                              </small>
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Card</p>
                            <p className="pos-data__item-value">
                              <Price
                                price={primaryTransaction.transactionPayments
                                  .filter(
                                    (x) =>
                                      cardTenders !== undefined &&
                                      cardTenders.length > 0 &&
                                      cardTenders.find((y) => y.id == x.tenderMediaId) !== undefined,
                                  )
                                  .reduce((a, b) => a + b.tendered, 0)}
                              />
                            </p>
                          </div>
                          <div className="pos-data__item">
                            <p className="pos-data__item-label">Deposit</p>
                            <p className="pos-data__item-value">
                              <Price
                                price={primaryTransaction.transactionPayments
                                  .filter((x) => depositTender !== undefined && depositTender.id === x.tenderMediaId)
                                  .reduce((a, b) => a + b.tendered, 0)}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pos-sale__actions">
                      <button
                        className="pos-sale__action pos-sale__action_role_change-tender"
                        type="button"
                        onClick={() => navigate('./change-tender')}
                      >
                        Change Tender
                      </button>
                    </div>
                  </div>
                </div>
                <div className="pos-sale__actions">
                  <button className="pos-sale__action pos-sale__action_role_done" type="button" onClick={doneClick}>
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default SalesReviewDetails;
