import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import { CardPaymentStatusItem } from '../../common/subscriptions/appSubscriptionService';
import { Operator } from '../api';

export interface SignOnState {
  fob?: string;
  pin?: string;
  operator?: Operator;
  remoteSignOn: boolean;
  cardStatus: CardPaymentStatusItem[];
}

const initialState: SignOnState = {
  remoteSignOn: false,
  cardStatus: [],
};

const signOnSlice = createSlice({
  name: 'signOn',
  initialState,
  reducers: {
    setFob: (state: Draft<SignOnState>, { payload }: PayloadAction<string>) => {
      state.fob = payload;
    },
    setPin: (state: Draft<SignOnState>, { payload }: PayloadAction<string>) => {
      state.pin = payload;
    },
    setOperator: (state: Draft<SignOnState>, { payload }: PayloadAction<Operator>) => {
      state.operator = payload;
    },
    setRemoteSignOn: (state: Draft<SignOnState>, { payload }: PayloadAction<boolean>) => {
      state.remoteSignOn = payload;
    },
    clear: (state: Draft<SignOnState>) => {
      state.pin = undefined;
      state.fob = undefined;
      state.operator = undefined;
      state.remoteSignOn = false;
    },
    setCardStatus: (state: Draft<SignOnState>, { payload }: PayloadAction<CardPaymentStatusItem[]>) => {
      state.cardStatus = payload;
    },
  },
});

export const { setFob, setPin, setOperator, clear, setRemoteSignOn, setCardStatus } = signOnSlice.actions;
export default signOnSlice.reducer;

const selectFob = (state: RootState) => state.signOn.fob;
const selectPin = (state: RootState) => state.signOn.pin;
const selectOperator = (state: RootState) => state.signOn.operator;
const selectRemoteSignOn = (state: RootState) => state.signOn.remoteSignOn;
const selectCardStatus = (state: RootState) => state.signOn.cardStatus;
export const useSignOnFob = () => useAppSelector(selectFob);
export const useSignOnPin = () => useAppSelector(selectPin);
export const useSignOnOperator = () => useAppSelector(selectOperator);
export const useRemoteSignOn = () => useAppSelector(selectRemoteSignOn);
export const useCardStatus = () => useAppSelector(selectCardStatus);
