import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import type { RootState } from '../../app/redux/reducer';
import { TransactionLineItem } from '../api';

export interface SplitState {
  visibleSplitIndex: number;
  activeTransactionLineItem?: TransactionLineItem;
}

const initialState: SplitState = {
  visibleSplitIndex: 0,
};

const splitSplice = createSlice({
  name: 'split',
  initialState,
  reducers: {
    setVisibleSplitIndex: (state: Draft<SplitState>, { payload }: PayloadAction<number>) => {
      state.visibleSplitIndex = payload;
    },
    setActiveTransactionLineItem: (state: Draft<SplitState>, { payload }: PayloadAction<TransactionLineItem | undefined>) => {
      state.activeTransactionLineItem = payload;
    },
    clearSplit: (state: Draft<SplitState>) => {
      state.visibleSplitIndex = 0;
      state.activeTransactionLineItem = undefined;
    },
  },
});

export const { setVisibleSplitIndex, setActiveTransactionLineItem, clearSplit } = splitSplice.actions;

export default splitSplice.reducer;

const selectVisibleSplitIndex = (state: RootState) => state.split.visibleSplitIndex;
const selectActiveTransactionLineItem = (state: RootState) => state.split.activeTransactionLineItem;

export const useVisibleSplitIndex = () => useAppSelector(selectVisibleSplitIndex);
export const useActiveTransactionLineItem = () => useAppSelector(selectActiveTransactionLineItem);
