import { useState } from 'react';
import Moment from 'react-moment';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../app/redux/hooks';
import { useGetTablesQuery, useSetTransactionTableMutation } from '../api';
import { useOperatorSession } from '../appState';
import { showErrorDialog } from '../error-dialog/errorDialogSlice';
import { useTransaction } from '../order/orderSlice';

function TransferTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operatorSession = useOperatorSession();
  const { data: tables } = useGetTablesQuery();
  const transaction = useTransaction();
  const [triggerSetTable] = useSetTransactionTableMutation();
  const [tableNum, setTableNum] = useState('');
  const [coversCount, setCoversCount] = useState(transaction.length === 1 ? transaction[0].covers : 0);

  const transferClick = () => {
    const parsedTableNum = parseInt(tableNum);
    if (isNaN(parsedTableNum)) dispatch(showErrorDialog({ message: 'Table is invalid', dismissible: true }));
    else {
      if (tables !== undefined && operatorSession !== undefined && transaction.length === 1) {
        const table = tables.find((x) => x.tableNumber === parsedTableNum);
        if (table === undefined) dispatch(showErrorDialog({ message: 'Table does not exist', dismissible: true }));
        else {
          triggerSetTable({
            tableId: table.id,
            covers: coversCount,
            transactionId: transaction[0].id,
            operatorId: operatorSession.operator.id,
          })
            .unwrap()
            .then(() => {
              navigate('/order');
            })
            .catch((err) => {
              if (err.status === 400) dispatch(showErrorDialog({ message: err.data.detail, dismissible: true }));
              else dispatch(showErrorDialog({ message: JSON.stringify(err), dismissible: true }));
            });
        }
      }
    }
  };

  const clearClicked = () => {
    if (tableNum == '') navigate('/order');
    else setTableNum('');
  };

  if (transaction.length !== 1) return <Navigate to="/order" />;
  return (
    <div className="pos-container pos-container_role_transfer-table">
      <div className="pos-transfer-table">
        <div className="pos-transfer-table__group">
          <div className="pos-transfer-table__area">
            <div className="pos-transfer-table__header">
              <h2 className="pos-transfer-table__title">Transfer Table</h2>
            </div>
            <div className="pos-transfer-table__controls">
              <div className="pos-transfer-table__number">
                <div className="pos-transfer-table__number-item">
                  <div className="pos-transfer-table__badge">
                    <div className="pos-transfer-table__badge-meta">
                      <p className="pos-transfer-table__badge-server">{transaction[0].operatorName}</p>
                      <p className="pos-transfer-table__badge-time">
                        <Moment format="HH:mm" date={transaction[0].transactionStartDateTime} />{' '}
                      </p>
                    </div>
                    <h3 className="pos-transfer-table__badge-name">T {transaction[0].tableNumber}</h3>
                    <div className="pos-transfer-table__badge-meta">
                      <p className="pos-transfer-table__badge-label">Guests</p>
                      <p className="pos-transfer-table__badge-number">{transaction[0].covers}</p>
                    </div>
                  </div>
                </div>
                <p className="pos-transfer-table__number-label">to</p>
                <div className="pos-transfer-table__number-display">{tableNum}</div>
              </div>
              <div className="pos-transfer-table__numpad">
                <div className="pos-numpad pos-numpad_role_transfer-table">
                  <div className="pos-numpad__controls">
                    <div className="pos-numpad__controls-row">
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '1')}>
                        1
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '2')}>
                        2
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '3')}>
                        3
                      </button>
                    </div>
                    <div className="pos-numpad__controls-row">
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '4')}>
                        4
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '5')}>
                        5
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '6')}>
                        6
                      </button>
                    </div>
                    <div className="pos-numpad__controls-row">
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '7')}>
                        7
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '8')}>
                        8
                      </button>
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '9')}>
                        9
                      </button>
                    </div>
                    <div className="pos-numpad__controls-row">
                      <button className="pos-numpad__control" type="button" onClick={() => setTableNum(tableNum + '0')}>
                        0
                      </button>
                      <button className="pos-numpad__control pos-numpad__control_role_clear" type="button" onClick={clearClicked}>
                        CLR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-transfer-table__guests">
                <p className="pos-transfer-table__guests-label">Guests</p>
                <div className="pos-transfer-table__guests-number">
                  <div className="pos-quantity pos-quantity_role_transfer-table">
                    <button
                      className="pos-quantity__control"
                      type="button"
                      disabled={coversCount === 1}
                      onClick={() => setCoversCount(coversCount - 1)}
                    >
                      -
                    </button>
                    <div className="pos-quantity__value">{coversCount}</div>
                    <button className="pos-quantity__control" type="button" onClick={() => setCoversCount(coversCount + 1)}>
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-transfer-table__area"></div>
        </div>
        <div className="pos-transfer-table__actions">
          <button
            className="pos-transfer-table__action"
            type="button"
            onClick={transferClick}
            disabled={coversCount === 0 || tableNum.length === 0}
          >
            Transfer
          </button>
        </div>
      </div>
    </div>
  );
}
export default TransferTable;
