import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Operator, useOperatorValidatePinMutation } from '../../api';

function ServerOverviewPin() {
  const navigate = useNavigate();
  const location = useLocation();

  const operator = location.state as Operator | undefined;

  const [pin, setPinState] = useState('');
  const addToPin = (k: string) => setPinState(pin + k);
  const [triggerPinCheck, { data: pinCheckData, isLoading }] = useOperatorValidatePinMutation();

  const validate = () => {
    triggerPinCheck({
      pinCode: pin,
      operatorId: operator ? operator.id : 0,
    })
      .unwrap()
      .then((result) => {
        if (result.valid === true) navigate('../details', { state: operator });
      });
  };
  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Signing In</h2>
          <p className="pos-sign-in__subtitle">Please enter your PIN:</p>
          <p className="pos-sign-in__note">*** you must be clocked in this venue***</p>
        </div>
        <div className="pos-sign-in__info">
          <p className="pos-sign-in__name">
            {operator?.firstName} {operator?.lastName}
          </p>
          {pinCheckData && pinCheckData.valid === false && <p className="pos-sign-in__error">Wrong PIN Code</p>}
        </div>
        <div className="pos-sign-in__controls">
          <div className="pos-sign-in__pin">
            <div className="pos-field pos-field_role_pin">
              <div className="pos-field__group">
                <div className="pos-field__box">{'*'.repeat(pin.length)}</div>
                <button className="pos-field__action" onClick={validate} disabled={isLoading}>
                  Enter
                </button>
              </div>
            </div>
          </div>
          <div className="pos-sign-in__numpad">
            <div className="pos-numpad pos-numpad_role_sign-in">
              <div className="pos-numpad__controls">
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('1')}>
                    1
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('2')}>
                    2
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('3')}>
                    3
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('4')}>
                    4
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('5')}>
                    5
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('6')}>
                    6
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('7')}>
                    7
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('8')}>
                    8
                  </button>
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('9')}>
                    9
                  </button>
                </div>
                <div className="pos-numpad__controls-row">
                  <button className="pos-numpad__control" type="button" onClick={() => addToPin('0')}>
                    0
                  </button>
                  <button className="pos-numpad__control pos-numpad__control_role_clear" type="button" onClick={() => setPinState('')}>
                    CLR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServerOverviewPin;
