import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import { Operator, OperatorSession, useChangeFobMutation } from '../../api';
import { clearErrorDialog, showErrorDialog } from '../../error-dialog/errorDialogSlice';

export function ServerOverviewChangeFob() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const manager = location.state.manager as Operator;
  const operatorSession = location.state.operator as OperatorSession;

  const [triggerChangeFob] = useChangeFobMutation();
  const keyboardState = useRef('');
  const [fob, setFob] = useState('');

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        const regexSignOn = /^0100([a-z|A-Z|0-9]{12})$/gm;
        const regexSignOff = /^0{3,4}(00)$/gm;
        const regexSignOnMatch = keyboardState.current.match(regexSignOn);
        if (regexSignOnMatch !== null) setFob(regexSignOnMatch[0]);
        const regexSignOffMatch = keyboardState.current.match(regexSignOff);
        if (regexSignOffMatch !== null) {
          setFob('');
          dispatch(clearErrorDialog());
        }
        keyboardState.current = '';
      } else {
        if (e.key.length === 1) {
          if (keyboardState.current.length >= 16) keyboardState.current = keyboardState.current.substring(1) + e.key;
          else keyboardState.current = keyboardState.current + e.key;
        }
      }
    };

    window.addEventListener('keydown', eventHandler);
    return () => window.removeEventListener('keydown', eventHandler);
  }, [dispatch]);

  const onDoneClick = useCallback(
    (fob: string) => {
      triggerChangeFob({ token: fob, managerId: manager.id, operatorSessionId: operatorSession.sessionId })
        .unwrap()
        .then(() => {
          navigate('../details', { state: operatorSession.operator });
        })
        .catch((err) => {
          if (err && err.status === 404) {
            dispatch(
              showErrorDialog({
                message: 'Operator or Manager not found',
                dismissible: true,
              }),
            );
          } else if (err && err.status === 412) {
            dispatch(
              showErrorDialog({
                message: 'The new fob is already used',
                dismissible: true,
              }),
            );
          } else {
            dispatch(
              showErrorDialog({
                message: 'Fob Validation Error',
                dismissible: true,
              }),
            );
          }
        });
    },
    [dispatch, navigate, triggerChangeFob, manager.id, operatorSession.operator, operatorSession.sessionId],
  );
  return (
    <div className="pos-modal__content">
      <div className="pos-sign-in js-sign-in">
        <div className="pos-sign-in__header">
          <h2 className="pos-sign-in__title">Change Fob</h2>
        </div>
        <div className="pos-sign-in__info">
          <p className="pos-sign-in__name"></p>
          <p className="pos-sign-in__label">
            You are changing the fob for {operatorSession.operator.firstName} {operatorSession.operator.lastName}.<br />
            <br /> Please place the new fob on the reader:
          </p>
        </div>

        <div className="pos-sign-in__controls">
          <div className="pos-sign-in__fob">
            <div className="pos-field pos-field_role_fob">
              <div className="pos-field__group">
                <div className="pos-field__box">{fob}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-sign-in__actions">
          <button className="pos-sign-in__action" type="button" onClick={() => onDoneClick(fob)} disabled={fob.length === 0}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
