import { baseApi } from '../baseApi';

import { WasteReason } from './WasteReason';

const slice = baseApi
  .enhanceEndpoints({
    addTagTypes: ['WasteReasons'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWasteReasons: builder.query<WasteReason[], void>({
        query: () => ({
          url: `/waste-reasons`,
          method: 'GET',
          headers: {
            'x-api-version': '1.0',
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'WasteReasons' as const,
                  id,
                })),
                { type: 'WasteReasons', id: 'LIST' },
              ]
            : [{ type: 'WasteReasons', id: 'LIST' }],
        keepUnusedDataFor: 0,
      }),
    }),
  });
export const { useGetWasteReasonsQuery } = slice;
