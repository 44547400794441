import { useAppDispatch } from '../../app/redux/hooks';

function Offline() {
  const dispatch = useAppDispatch();

  dispatch({
    type: 'signalr/disconnect',
  });
  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay">
        <div className="pos-popup pos-popup_role_error">
          <div className="pos-popup__content">
            <div className="pos-popup__icon"></div>
            <div className="pos-popup__info">
              <h3 className="pos-popup__title">System Offline!</h3>
              <p className="pos-popup__text">
                This workstation has gone offline.
                <br />
                <br />
                This is due to another workstation starting with the same ID.
                <br />
                <br />
                Please contact your Ops Manager for assistance.
              </p>
            </div>
            <div className="pos-popup__actions"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offline;
