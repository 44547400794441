import { useZxing } from 'react-zxing';

import { UseZxingOptionsWithConstraints, UseZxingOptionsWithDeviceId } from 'react-zxing/lib/useZxing';

function QrScanner(options: UseZxingOptionsWithConstraints | UseZxingOptionsWithDeviceId) {
  const { ref } = useZxing(options);

  return <video ref={ref} />;
}

export default QrScanner;
