import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { ConfigState, IConfig } from './config.interface';
import { Version } from './version.interface';

const initialState: ConfigState = {} as ConfigState;

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    store: (state: Draft<ConfigState>, action: PayloadAction<IConfig>) => {
      state.configuration = action.payload;
    },
    versionStore: (state: Draft<ConfigState>, action: PayloadAction<Version>) => {
      state.version = action.payload;
    },
  },
});

export const { store, versionStore } = configSlice.actions;

export default configSlice.reducer;
